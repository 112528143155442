import React, { Component } from 'react';

import classificationStrengths from '../ClassificationStrengths';
import './index.css';

export default class ACMGTable extends Component {

  classificationObject = () => {
    var classificationObject = {};
    classificationObject.pathogenic = {};
    classificationObject.benign = {};

    Object.keys(classificationStrengths).map(key => {
      let value = classificationStrengths[key];
      if (value.includes('Pathogenic')) {
        value = value.replace('Pathogenic ', '');
        if (classificationObject.pathogenic[value] == undefined) {
          classificationObject.pathogenic[value] = [];
        }
        classificationObject.pathogenic[value].push(key);
      } else if (value.includes('Benign')) {
        value = value.replace('Benign ', '');
        if (classificationObject.benign[value] == undefined) {
          classificationObject.benign[value] = [];
        }
        classificationObject.benign[value].push(key);
      }
    });
    return classificationObject;
  };

  renderTable = (title, classification, styleClass) => {
    var classificationObject = this.classificationObject();

    return (
      <div>
        <div className={styleClass}>{title}</div>
        <table className="table" summary="This table describes the selected evidence by criteria.">
          <tbody>
            {Object.keys(classificationObject[classification]).map(strength => {
              return (
                <tr key={strength}>
                  <th scope="row">{strength}</th>
                  {classificationObject[classification][strength].map(
                    evaluation => {
                      return (
                        <td
                          key={evaluation}
                          className={
                            [...this.props.evaluationList,...this.props.literatureEvaluationList].find(
                              value => value === evaluation
                            ) != undefined
                              ? 'highlight'
                              : ''
                          }
                        >
                          {evaluation}
                        </td>
                      );
                    }
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  };

  render() {
    return (
      <div className="acmgTable">
        {this.renderTable(
          'Evidence of Pathogenicity',
          'pathogenic',
          'pathogenicTitle'
        )}
        {this.renderTable('Evidence of Benign', 'benign', 'benignTitle')}
      </div>
    );
  }
}
