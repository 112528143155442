import React, { Component } from 'react';
import Modal from 'react-modal';

import Loading from '../../Loading/index.js';
import AnnotationNamesConfig from '../../AddInterpretation/AnnotationNamesConfig.js';
import ReviewStatus from '../../../config/ReviewStatus.js';
import APIUtils from '../../../utils/APIUtils';
import FinalConsensus from './FinalConsensus';
import ConsensusCategoryTable from './ConsensusCategoryTable';
import ConsensusLiteratureTable from './ConsensusLiteratureTable/index.js';
import ConsensusClassificationTable from './ConsensusClassificationTable';

import { Button } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import BlockIcon from '@material-ui/icons/Block';
import PublishIcon from '@material-ui/icons/Publish';

import './index.css'

const final = "FINAL@CDC.GOV";

export default class ConsensusSummary extends Component {
    constructor() {
        super();
        this.state = {
            variantInterpretations: [],
            finalVarInterp: {},
            variantLiterature: [],
            classificationConsensus: [],
            consensusExist: false,
            dataExists: false,
            loading: true,
            errorMsg: '',
            loadedReviewStatus: null,
            programId: null,
            variantId: null,
            variantReviewId: null,
            startDate: null,
            filtered: [],
            modalCancelIsOpen: false,
            modalSubmitIsOpen: false,
            validationError: false,
            initialClassificationsLoaded: false,
        //    potentialCondition: '',
            calcCondition: '',
            finalClassification: '',
            finalComments: '',
            cancelAndReturn: false,
            savedFinalInterpretation: false,
            savedFinalDetails: false,
            savedFinalReviewStatus: false,
            toProgramSummary: false,
            formValues: {},
            initialCategoriesLoaded: false,
        };
    }
    
    componentDidUpdate(prevProps, prevState) {
        var variantId = this.props.variantId;
        var programId = this.props.programId;
        var history = this.props.history;
        
        if (this.props.variantId == null || this.props.variantId !== prevProps.variantId) {
            var variantId = this.props.variantId;
            var programId = this.props.programId;
            this.setState({
                programId: programId,
                variantId: variantId,
                history: history,
            })
            this.getConsensus(variantId, programId);
        }

        // state updates should remain in componentDidUpdate to prevent loop errors
        if (this.state.cancelAndReturn || (this.state.savedFinalInterpretation && this.state.savedFinalDetails)) {
            window.onbeforeunload = () => null;
            this.setState({
                toProgramSummary: true,
            });
        }

        if (this.state.toProgramSummary) {
            this.state.history.push({
                pathname: '/programsummary',
                state: {
                    programId: sessionStorage.getItem('program'),
                },
            });
        }
        // console.log("/// compdidupdate Form Values: " + JSON.stringify(this.state.formValues));

    }

    async getConsensus(variantId, programId) {
        console.log('---getConsensus()');
        this.setState({ loading: true });

        var response = await APIUtils.getConsensusSummary(programId, variantId);
        // console.log("+++++ consensusSummary response:" + JSON.stringify(response))
        if (response) {
            var consensusSummary = response;
           // var potCondition = consensusSummary.variantReview.variant.gene;
            var reviewStatus = consensusSummary.variantReview.reviewStatus;
            var reviewId = consensusSummary.variantReview.variantReviewId;
            var varInterps = consensusSummary.variantReview.variantInterpretations;
            // console.log("consensusSummary: ", JSON.stringify(consensusSummary));
            // console.log("reviewID: ", JSON.stringify(reviewId));
            // console.log("revStatus: ", JSON.stringify(reviewStatus));
            // console.log("potCondition: ", JSON.stringify(potCondition));

            // format consensus variant interpretations
            var variantInterpretations = new Array();
            var finalVarInterp = new Object();
            var finalUserExists = false;

            varInterps.forEach(interpretation => {
                const interpEval = {
                    "reviewId": interpretation.userId,
                    "reviewer": interpretation.user.firstName + ' ' + interpretation.user.lastName  + " Evaluation",
                    "sortName": interpretation.user.firstName + ' ' + interpretation.user.lastName};
                    interpretation.interpretationDetails.forEach(interpretationDetail=>{
                    const category = interpretationDetail.AnnotationCategory;
                    const catObj = interpEval[category] || {}; //check if exists

                    catObj.evaluation = interpretationDetail.AcmgCriteria;
                    catObj.comment = interpretationDetail.Comment;
                    // console.log("--USERID: " + interpretation.userId);
                    interpEval[category] = catObj;
                    // create object for final interpretation to load input values
                    if (interpretation.userId === final && reviewStatus !== ReviewStatus.CLOSED) {
                        finalVarInterp[category] = catObj.evaluation;
                    } 
                    if (interpretation.userId === final) {
                        finalUserExists = true;
                        // console.log("--- ***FOUND FINAL USER!*** ---")
                    } 
                });
                variantInterpretations.push(interpEval);
            });

            if (!finalUserExists && reviewStatus === ReviewStatus.READY_FOR_CONSENSUS) {
                // console.log("--PUSHING FINAL USER TO REVIEW EVAL--")
                const interpEval = {"reviewId": final, "sortName": 'Final Consensus'};
                variantInterpretations.push(interpEval);
            }
            //get literature categories
            const categories = await APIUtils.getLiteratureCategories();
            
            // format consensus literature
            var variantLiterature = new Array();
            consensusSummary.variantReview.variantInterpretations.forEach(review => {
                const reviewId = review.userId;
                const reviewer = review.user.firstName + ' ' + review.user.lastName;
                review.literatureReviews.forEach(literatureReview=>{
                    const pmid = literatureReview.pmid;
                    const litCategory = categories.filter(cat => cat.LiteratureCategoryValue === literatureReview.literatureCategory)[0].LiteratureCategoryLabel;
                    const litEval = literatureReview.acmgEvidCriteria.acmgEvidCriteriaId;
                    const litComment = literatureReview.comment;
                    const litRow = {
                        reviewId,
                        reviewer,
                        pmid,
                        litCategory,
                        litEval,
                        litComment
                    }
                    variantLiterature.push(litRow);
                });
            });
            // console.log("literature: ", JSON.stringify(variantLiterature));

            // format consensus classification
            var variantClassification = new Array();
            var finalVarClass = '';
            var finalVarComment = '';
            consensusSummary.variantReview.variantInterpretations.forEach(review => {
                const reviewId = review.userId;
                const reviewer = review.user.firstName + ' ' + review.user.lastName;
                const classification = review.programClassification;
                const comment = review.comment;
                const classRow = {
                    reviewId,
                    reviewer,
                    classification,
                    comment,
                }
                variantClassification.push(classRow);
                // create object for final consensus classification if it exists
                if (reviewId === final) {
                    finalVarClass = classification;
                    finalVarComment = comment;
                }
            });
        
            this.setState({
                loading: false,
                consensusExist: true,
              //  potentialCondition: potCondition,
                variantReviewId: reviewId,
                loadedReviewStatus: reviewStatus,
                variantInterpretations: variantInterpretations,
                finalVarInterp: finalVarInterp,
                variantLiterature: variantLiterature,
                variantClassification: variantClassification,
                finalClassification: finalVarClass,
                finalComments: finalVarComment,
            });

            // console.log("variantInterpretations: ", JSON.stringify(this.statevariantInterpretations));
            // console.log("finalVarInterp: ", JSON.stringify(this.statefinalVarInterp));
            console.log("/// get consensus Form Values: " + JSON.stringify(this.state.formValues));

            if (this.state.loadedReviewStatus === ReviewStatus.READY_FOR_CONSENSUS) {
                this.loadInitialCategorySelections(consensusSummary);
                this.loadInitialClassificationSelections(consensusSummary);
            } else {
                // format the form values object on load or update for existing data
                // if nothing was changed, formattedFormValues will default populate the 
                // formValues state so that saving will not wipe the loaded data
                var formattedFormValues = {}; 
                Object.entries(AnnotationNamesConfig).forEach(([annotation]) => {
                    formattedFormValues[annotation] = '';
                })

                const reformatVarInterpData = (varInterps) => {
                    return varInterps.reduce((acc, user) => {
                        user.interpretationDetails.forEach(interp => {
                            acc[interp.AnnotationCategory] = interp.AcmgCriteria
                        });
                        return acc;
                    }, {});
                }

                formattedFormValues = reformatVarInterpData(varInterps);

                this.setState({
                    formValues: formattedFormValues,
                });

                const finalUserObject = varInterps.find(obj => obj.userId === final);

                if (finalUserObject) {
                    this.setState({
                        finalClassification: finalUserObject.programClassification || '',
                        finalComments: finalUserObject.comment || '',
                    });
                }

                
            }
            
        } else {
            console.error('Error loading the consensus review');
          //  console.error('err=' + err);
            this.setState({
                loading: false,
                errorMsg: 'There was an error retrieving the data.',
                consensusExist: false,
            });
        }

        setTimeout(() => {
            this.setState({ loading: false });
        }, 2000);

    }

    loadInitialCategorySelections = (consensusSummary) => {
        const readyForConsensus = this.state.loadedReviewStatus === ReviewStatus.READY_FOR_CONSENSUS;
        const varInterps = consensusSummary.variantReview.variantInterpretations;
        // check non-final users exist
        const nonFinalUser = varInterps.find(users => users.userId !== final);
        // check final user exists
        const finalUser = varInterps.find(user => user.userId === final) || { reviews: [] };

        // check if evaluations match for non-final users
        const evaluationsMatch = (category) => {
            const nonFinalReviews = nonFinalUser.interpretationDetails.filter(review => review.AnnotationCategory === category);
            return (
                nonFinalReviews.every(review => review.AcmgCriteria !== null) &&
                nonFinalReviews.every(review => review.AcmgCriteria === nonFinalReviews[0].AcmgCriteria)
            );
        } 
        
        const initialFormValues = {}; 
        Object.entries(AnnotationNamesConfig).forEach(([annotation]) => {
            initialFormValues[annotation] = '';
          })
        if (readyForConsensus) {
            for (const review of nonFinalUser.interpretationDetails) {
                const category = review.AnnotationCategory;
                // check if final user value exists or if values match
                if (
                    !finalUser.interpretationDetails || finalUser.interpretationDetails.find(r => r.AnnotationCategory === category)?.AcmgCriteria === null ||
                    !varInterps.some(user => user.userId === final && evaluationsMatch(category)) ||
                    (finalUser.interpretationDetails.find(r => r.category === category)?.AcmgCriteria !== null)
                ) {
                    // console.log("----UPDATING Initial Form Values" );
                    initialFormValues[category] = review.AcmgCriteria;
                }
            }
        }
        this.setState({
            initialCategoriesLoaded: true,
            formValues: initialFormValues,
        });
        // console.log("----Initial Form Values: " + JSON.stringify(this.state.formValues));
    }


    loadInitialClassificationSelections = (consensusSummary) => {
        const varInterps = consensusSummary.variantReview.variantInterpretations;

        // check if classification values match in the response
        const AllSameClassification = varInterps.every(
            obj => obj.programClassification === varInterps[0].programClassification
        );

        if (AllSameClassification) {
            this.setState({
                finalClassification: varInterps[0].programClassification,
                finalComments: '',
            });
        }
    }

    openCancelModal = () => {
        this.setState({ modalCancelIsOpen: true });
    }

    openSubmitModal = () => {

        if (this.validateConsensus()) {
            this.setState({
                validationError: false,
                modalSubmitIsOpen: true
            });
        } else {
            this.setState({
                validationError: true,
            });
        }
    }

    onDiscard = () => {
        this.setState({
        cancelAndReturn: true,
        });
    }

    closeModal = () => {
        this.setState({
        modalCancelIsOpen: false,
        modalSubmitIsOpen: false,
        });
    }

    onEvaluationChange = (evalValue) => {
        this.setState({
            formValues: { ...evalValue },
        }, () => { // callback check 
            // console.log(">>>>>>> UPDATED onEvaluationChange Form Values: " + JSON.stringify(this.state.formValues));
        });
    }

    //Functions created to retrieve the classification and comments from the child 
    //component FinalConsensus
    handleConsensusClassificationChange = (classification) => {
        this.setState({
            finalClassification: classification,
        // });
        }, () => { // callback check 
            console.log(">>>>>>> UPDATED finalClassification: " + this.state.finalClassification);
        });
    }

    handleConsensusCommentChange = (comment) => {
        this.setState({
            finalComments: comment,
        // });
        }, () => { // callback check 
            console.log(">>>>>>> UPDATED finalComments: " + this.state.finalComments);
        });
    }

    save = () => {
        //console.log('---ConsensusSummary.save()');
        window.onbeforeunload = null;
        this.saveInterpretationBuilder(true);
    }

    submit = () => {
        //console.log('---ConsensusSummary.submit()');
        window.onbeforeunload = null;
        this.saveInterpretationBuilder(false);
    }

    validateConsensus = () => {
        console.log(">>>>>>> finalClassification: " + this.state.finalClassification);
        console.log(">>>>>>> finalComments: " + this.state.finalComments);

        if (
            (( this.state.finalClassification === "" ) || ( this.state.finalComments === "" ))
            ) 
        {
            return false; // invalid
        } else {
            return true; // validated
        }
    }

    saveInterpretationBuilder = (partialStatus) => {
        //console.log('---ConsensusSummary.saveInterpretationBuilder('+partialStatus+')');

        const formValuesObject = { ...this.state.formValues };
        let programId = this.state.programId;
        let variantId = this.state.variantId;
        this.saveInterpretation(programId, variantId, partialStatus, formValuesObject);
    }

    // TODO - doesn't currently calculate classification based on current evaluations
    async saveInterpretation(programId, variantId, partialStatus, formValues) {
        // console.log("/// Form Values: " + JSON.stringify(this.state.formValues));
        // console.log("/// FINAL CLASSIFICATION: " + this.state.finalClassification);
        // console.log("/// FINAL COMMENTS: " + this.state.finalComments);
        // console.log('--saveInterpretation()');
        let calcClassification = this.state.calcClassification;
        let programClassification = this.state.finalClassification;
        let comments = this.state.finalComments;
        var interpretationDetails = [];

        Object.keys(formValues).forEach((key) => {
            if (key !== undefined && formValues[key] !== '') {
                let interpElement = {
                    "AnnotationCategory": key,
                    "AcmgCriteria": formValues[key]
                };
                interpretationDetails.push(interpElement);
            }
        });

        let revStatus = this.state.loadedReviewStatus;
        let interpretation = {
            // NOTE - currently calcClassification is required on backend regardless if null or not
            'variantReviewId': this.state.reviewId,
            'userId': final,
            'partial': partialStatus,
            'review': true,
            'calcClassification': calcClassification,
            'programClassification': programClassification,
            'comment': comments,
            'interpretationDetails': interpretationDetails
        }

        try {
            // console.log('revStatus = ' + revStatus);
            if (revStatus === ReviewStatus.READY_FOR_CONSENSUS) {
                var response = await APIUtils.addVariantInterpretation(programId, variantId, interpretation);
            }
            else {
                var response = await APIUtils.updateVariantInterpretation(programId, variantId, interpretation);
            }
            this.closeModal();
            this.setState({
                savedFinalInterpretation: true,
                savedFinalDetails: true
            });
        } catch (err) {
            console.error('There was an error updating the interpretation.');
            console.error('err=' + err);
            this.setState({
                errorMsg: 'There was an error uploading the interpretation.',
                loading: false,
            });
        }
        
    }

    getButtonSection() {
        return (
            <>
                <div className="form-buttons">
                    <Button style={{ margin: "0 10px", width: "180px" }} onClick={this.openCancelModal} color="default" variant="contained">
                        <BlockIcon className="iconMarginRight" /> Cancel
                    </Button>
                    <Button style={{ margin: "0 10px", width: "180px" }} onClick={this.save} color="primary" variant="contained">
                        <SaveIcon className="iconMarginRight" /> Save for Later
                    </Button>
                    <Button style={{ margin: "0 10px", width: "180px" }} onClick={this.openSubmitModal} color="primary" variant="contained">
                        <PublishIcon className="iconMarginRight" /> Publish
                    </Button>
                </div>
                <Modal
                    className="modal"
                    overlayClassName="modalOverlay"
                    aria-modal="true"
                    contentLabel="Confirm Discard"
                    shouldCloseOnOverlayClick={false}
                    isOpen={this.state.modalCancelIsOpen}
                    onRequestClose={this.closeModal}
                >
                    <div className="modalContent">
                        Are you sure you want to discard this form and return to the program summary page?
                    </div>
                    <div className="modalButtons">
                        <button className="modalButton" onClick={this.onDiscard}>
                            Yes, Discard
                        </button>
                        <button
                            className="modalButton primaryButton"
                            onClick={this.closeModal}
                        >
                            Cancel
                        </button>
                    </div>
                </Modal>
                <Modal
                    className="modal"
                    overlayClassName="modalOverlay"
                    aria-modal="true"
                    contentLabel="Confirm Submit"
                    shouldCloseOnOverlayClick={false}
                    isOpen={this.state.modalSubmitIsOpen}
                    onRequestClose={this.closeModal}
                >
                    <div className="modalContent center">
                        Are you sure you want to submit this final consensus summary interpretation?
                        <br />
                        <br />
                        <strong>Note:</strong> Hitting submit will submit this final consensus summary interpretation for others to see
                        and no other edits can be made.
                    </div>
                    <div className="modalButtons">
                        <button
                            className="modalButton"
                            onClick={this.closeModal}
                        >
                            Cancel
                        </button>
                        <button className="modalButton primaryButton" onClick={this.submit}>
                            Yes, Submit
                        </button>
                    </div>
                </Modal>
            </>
        );
    }

    render() {
        console.log('---ConsensusSummary.render()');

        var revStatus = this.state.loadedReviewStatus;
        var showSaveButtons = (revStatus === ReviewStatus.READY_FOR_CONSENSUS || revStatus === ReviewStatus.CONSENSUS_IN_PROGRESS);

        return (
            <>
                <Loading isLoading={this.state.loading} />
                {this.state.consensusExist ? (
                    <>
                        <section>
                            <h2 className="sectionHeading">Summary of Variant Interpretation Results</h2>
                            <div className="sectionContent">
                                <div className='tableSection'>
                                    <ConsensusCategoryTable
                                        data={this.state.variantInterpretations}
                                        reviewStatus={this.state.loadedReviewStatus}
                                        formValues={this.state.formValues}
                                        finalVarInterp={this.state.finalVarInterp}
                                        onEvaluationChange={this.onEvaluationChange}
                                    /> 
                                </div>
                            </div>
                        </section>
                        <section>
                            <h2 className="sectionHeading">Summary of Variant Interpretation Literature</h2>
                            <div className="sectionContent">
                                <div className='tableSection'>
                                    { this.state.variantLiterature.length > 0 ? (
                                        <ConsensusLiteratureTable
                                            data={this.state.variantLiterature}
                                        />
                                    ) : (
                                        <div className="noData">
                                        No literature to display for this consensus
                                        </div>
                                    )
                                    }
                                </div>
                            </div>
                        </section>
                        <section>
                            <h2 className="sectionHeading">Summary of Variant Interpretation Classifications</h2>
                            <div className="sectionContent">
                                <div className='tableSection'>
                                    <ConsensusClassificationTable
                                        data={this.state.variantClassification}
                                    />
                                </div>
                            </div>
                        </section>
                        {this.state.loadedReviewStatus !== ReviewStatus.CLOSED ? (
                            <FinalConsensus
                                finalClassification={this.state.finalClassification}
                                finalComments={this.state.finalComments}
                                handleConsensusClassificationChange={this.handleConsensusClassificationChange}
                                handleConsensusCommentChange={this.handleConsensusCommentChange}
                            />
                        ) : (
                            ''
                        )}
                        {this.state.validationError ? (
                            <li className="error validationError" align="center">Both Classification and Comments are required.</li>
                        ) : ('')}
                        <div className='buttonSection'>
                            {showSaveButtons && (
                                this.getButtonSection()
                            )}
                        </div>
                    </>
                ) : (
                    <div className="headerText">
                        There is no consensus for this variant yet
                    </div>
                )}
            </>
        );
    }

}