import React from 'react';

import { Link } from 'react-router-dom';

//Label Link
// Example:
{/* 
<LinkLabel for={rowInfo.original.variantName}
           path={'/variantdashboard/' + rowInfo.original.variantName}
           text={rowInfo.original.variantName}
           state={{variantId: rowInfo.original.variantId,
                   variantName: rowInfo.original.variantName,
                   breadCrumbs: this.props.breadCrumbs}}
            />
*/}

const LinkLabel = (props) => { 
    return(
        <>
<label htmlFor={props.for}>
              <Link
                to={{
                  pathname: props.path,
                  state: props.state
                }}>
                {props.text}
              </Link>
            </label>
        </>
    )
            }

export default LinkLabel