//#region  Sorting Function Helpers
function descendingComparator(a, b, orderBy) {
    if (orderBy.toLowerCase().indexOf('date') > -1 || orderBy === 'lastReviewed') { //check if dates
      if(!isNaN(new Date(b[orderBy]).valueOf()) && !isNaN(new Date(a[orderBy]).valueOf())) { //each date in array is a number
        if (new Date(b[orderBy]).valueOf() < new Date(a[orderBy]).valueOf()) {
          return -1 
        } else
        if (new Date(b[orderBy]).valueOf() > new Date(a[orderBy]).valueOf()) {
          return 1 
        } 
      } else { //one of the dates in array (a or b) is NaN
        if (isNaN(new Date(b[orderBy]).valueOf())) {
          return -1 
        } else 
        if (isNaN(new Date(a[orderBy]).valueOf())) { 
          return 1 
        }
      }
    }
    if (b[orderBy] < a[orderBy]) { //normal sorting 
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
export function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
export function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  //#endregion
  