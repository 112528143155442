import React, {useState, useEffect} from "react";
import {
  Table,
  TableBody,
  TableContainer,
  Paper,
  Button,
  Link,
} from "@material-ui/core";
import {StyledTableRow,StyledTableCell} from '../../../uiComponents/MuiTable/StyledTable.js'
import { makeStyles } from "@material-ui/core/styles";
import MuiTableHeader from "../../../uiComponents/MuiTable/MuiTableHeader.js";
import MuiTablePagination from '../../../uiComponents/MuiTable/MuiTablePagination';
import {stableSort,getComparator} from '../../../uiComponents/MuiTable/MuiHelper';
import { AddCircleOutline, RemoveCircleOutline, Edit, OpenInNew } from "@material-ui/icons";
import './index.css';
import mastermindLogo from '../../../images/mastermindLogo.png';
import LoadingInner from '../../Loading/LoadingInner';

const useStyles = makeStyles({
  root: {
    background: '#006778',
    color: 'white',
    fontFamily: "Open Sans",
  },
  litButtons: {
    margin: '.1rem .5rem .1rem 0',
    padding: '6px 10px',
    color: 'white'
  },
  customMastermindButton: {
    margin: '.1rem .5rem .1rem 0',
    padding: '6px 10px',
    color: 'white !important',
    textDecoration: 'none !important',
    '&:hover': {
      backgroundColor: 'rgb(0, 72, 84)',
      boxShadow: '0 0 5px var(--primary-color)'
    },
    '&:focus-visible': {
      backgroundColor: 'var(--accent-primary-color)',
      border: 'none',
      outline: 'none',
      color: 'white'
    }
  },
  inline:{
    color: 'white'
  },
  paper: {
    width: '100%',
  },
  table: {
    minWidth: 750,
  },
  header: {
    height: '2rem',
  },
  cell: {
    wordBreak: 'break-word',
  }
});

const LiteratureTable = (props) => {
  const classes = useStyles();
  const literatures = Array.isArray(props.literatures) ? props.literatures : [];
  const literatureReviews = Array.isArray(props.literatureReviews) ? props.literatureReviews : [];
  const categories = Array.isArray(props.categories) ? props.categories : []; 
  const gene = props.gene;
  const variant = props.variantName;
  const [order, setOrder] = useState('desc');
  const [orderT2, setOrderT2] = useState('desc');
  const [orderBy, setOrderBy] = useState('pmid');
  const [orderByT2, setOrderByT2] = useState('pmid');
  const [page, setPage] = useState(0);
  const [pageT2, setPageT2] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rowsPerPageT2, setRowsPerPageT2] = useState(10);
  const [articlesToAdd, setArticlesToAdd] = useState([]);
  const [articlesToEdit, setArticlesToEdit] = useState([]);
  const [loadedCategories, setLoadedCategories] = useState([]);
  const [isLoadingInner, setIsLoadingInner] = useState(false);

  useEffect(() => {
    setIsLoadingInner(true);
    setTimeout(function () {
      setIsLoadingInner(false);
    }, 30000); //max loading spinner duration 30 seconds before no data available message
}, []);

  useEffect(() => {
    setArticlesToEdit(literatureReviews);
  }, [props.literatureReviews]);

  useEffect(() => {
    if (literatures.length > 0) {
      const filteredArticles = literatures.filter(
        (array_el) =>
          literatureReviews.filter(
            (anotherOne_el) => anotherOne_el.pmid === array_el.pmid
          ).length === 0
      );
      setArticlesToAdd(filteredArticles);
    } else {
      setArticlesToAdd(literatures);
    }
  }, [props.literatures]);

  useEffect(() => {
    setLoadedCategories(categories);
  }, [props.categories]);

  function onClick(literature, action, focus) {
    if (action === "add") {
      let literatureRecord = {
        pmid: literature.pmid,
        acmgEvidCriteria: { acmgCriteriaId: null },
        comment: "",
      };
      props.onOpenModal(literatureRecord, focus);
    } else if (action !== "remove") {
      props.onOpenModal(literature, focus);
    } else {
      props.onDelete(literature);
    }
  }

  function onManuallyAdd(focus) {
    let literature = {
      pmid: "",
      acmgEvidCriteria: { acmgCriteriaId: null },
      comment: "",
    };
    props.onOpenModal(literature, focus);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };   

  const handleRequestSortT2 = (event, property) => {
    const isAsc = orderByT2 === property && orderT2 === 'asc';
    setOrderT2(isAsc ? 'desc' : 'asc');
    setOrderByT2(property);
  };

  const handleChangePageT2 = (event, newPage) => {
    setPageT2(newPage);
  };

  const handleChangeRowsPerPageT2 = (event) => {
    setRowsPerPageT2(parseInt(event.target.value, 10));
    setPageT2(0);
  };   

  const pmidCell = (row) => {
    return (
      <Link href={'https://pubmed.ncbi.nlm.nih.gov/' + row.pmid} target="_blank" rel="noopener noreferrer">
        {row.pmid}
      </Link>
    )
  }

  const renderCategoryLabel = (row) => {
    if (!loadedCategories || loadedCategories === 0) {
      return "Loading...";
    }
    
    const categoryLabel = loadedCategories.find(
      (cat) =>
        cat.LiteratureCategoryValue ===
        row.literatureCategory
    );
    
    return categoryLabel ? categoryLabel.LiteratureCategoryLabel : "Unknown Category";
  }

  const renderActionButtons = (original) => {
    return original.literatureCategory?.length > 0 ? (
      <>
        <Button
          className={classes.litButtons}
          color='primary'
          variant="contained"
          id={`${original.pmid}-edit`}
          onClick={() => onClick(original, "edit", `${original.pmid}-edit`)}
        >
          <Edit className="litIcons" alt="edit" fontSize='small' />
          Edit
        </Button>
        <Button
          className={classes.litButtons}
          color='primary'
          variant="contained"
          id={`${original.pmid}-remove`}
          onClick={() => onClick(original, "remove", `${original.pmid}-remove`)}
        >
          <RemoveCircleOutline className="litIcons" alt="remove" fontSize='small' />
          Remove
        </Button>
      </>
    ) : (
      <>
        <Button
          className={classes.litButtons}
          color='primary'
          variant="contained"
          id={`${original.pmid}-add`}
          onClick={() => onClick(original, "add", "availableLiteratureTable")} //update 3rd thing for focus
        >
          <AddCircleOutline className="litIcons" alt="add" fontSize='small' />
          Add To Report
        </Button>
        <Button
          className={classes.customMastermindButton}
          color='primary'
          variant="contained"
          id={`${original.pmid}-mastermind`}
          href={original.url}
          component="a"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="customIcon" alt="Mastermind logo" src={mastermindLogo} />
          Show on Mastermind
        </Button>
      </>
    );
  };

  const generateMastermindLink = () => {
    let geneQuery = '';
    const geneList = gene.split(',');

    if (geneList.length > 1) {
      geneQuery = geneList.map((g) => `gene=${g}`).join('&') + '&gene_op=and';
    } else {
      geneQuery = `gene=${geneList[0]}`;
    }

    const link = `https://mastermind.genomenon.com/articles?${geneQuery}&mutation=${variant}`;
    return link;
  };

  const includedLitColumns = [
    { label: 'PMID', id: 'pmid', numeric: false, disablePadding: false },
    { label: 'ACMG Criteria', id: 'acmgEvidCriteria.acmgEvidCriteriaId', numeric: false, disablePadding: false },
    { label: 'Category', id: 'literatureCategory', numeric: false, disablePadding: false },
    { label: 'Comment', id: 'comment', numeric: false, disablePadding: false },
  ];

  const includedLitAction = [
    { label: 'Action', id: 'actionRow', numeric: false, disablePadding: false, disableSort: true },
  ]

  const availableLitColumns = [
    { label: 'PMID', id: 'pmid', numeric: false, disablePadding: false },
    { label: 'Title', id: 'title', numeric: false, disablePadding: false },
    { label: 'Journal', id: 'journal', numeric: false, disablePadding: false },
    { label: 'Publication Date', id: 'publication_date', numeric: false, disablePadding: false },
  ];

  const availableLitAction = [
    { label: 'Action', id: 'url', numeric: false, disablePadding: false, disableSort: true },
  ]

  return (
    <div className="literatureTable">
      <div className="litHeaderSection">
        <h3 className="litHeader">
          Literature included in your report
        </h3>
        <Button 
          id='add' 
          onClick={() => onManuallyAdd('add')}
          color='primary'
          variant="contained"
          aria-label="add literature" 
        >
          <AddCircleOutline className="litIcons" alt="add" fontSize='small' />
          Add Literature
        </Button>
      </div>
      {articlesToEdit.length > 0 ? (
        <div className="includedLitSection">
          <TableContainer component={Paper}>
            <Table 
              className={classes.table} 
              size="small"
              aria-label="included literature table" 
            >
              <MuiTableHeader 
                headCells={includedLitColumns} 
                noSortHeadCellsEnd={includedLitAction}
                order={order}  
                orderBy={orderBy} 
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {stableSort(articlesToEdit, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                  return (
                    <StyledTableRow tabIndex={-1} key={index}>
                      <StyledTableCell style={{width: '10%'}}>{pmidCell(row)}</StyledTableCell>
                      <StyledTableCell style={{width: '15%'}}>{row.acmgEvidCriteria.acmgEvidCriteriaId}</StyledTableCell>
                      <StyledTableCell style={{width: '25%'}}>{renderCategoryLabel(row)}</StyledTableCell>
                      <StyledTableCell style={{width: '30%'}}>{row.comment}</StyledTableCell>
                      <StyledTableCell style={{width: '20%'}}>{renderActionButtons(row)}</StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <MuiTablePagination 
            rowsPerPage={rowsPerPage}
            page={page}
            count={articlesToEdit.length}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage} 
          />
        </div>
      ) : (
        <div className="source-container center noMarginSection">
          <p>There is no data available for this section</p>
        </div>
      )}
      <div className="availableLitSection">
        <h3 className="litHeader">
          Literature matches from Mastermind
        </h3>
        <p>This table includes only literature with mentions of variants that are direct cDNA 
          matches to the interpreted variant. <br />Select literature below to add to your report, or&nbsp;
          <a 
            href={generateMastermindLink()}
            target="_blank"
            rel="noopener noreferrer"
            id="availableLiteratureTable"
          >see full search results on Mastermind UI.<OpenInNew className="litLinkIcons" alt="add" fontSize='inherit' /></a>
        </p>
        {articlesToAdd.length > 0 ? (
          <>
            <TableContainer component={Paper}>
              <Table 
                className={classes.table} 
                size="small"
                aria-label="mastermind table"
              >
                <MuiTableHeader 
                  headCells={availableLitColumns} 
                  noSortHeadCellsEnd={availableLitAction}
                  order={orderT2}  
                  orderBy={orderByT2} 
                  onRequestSort={handleRequestSortT2}
                />
                <TableBody>
                  {stableSort(articlesToAdd, getComparator(orderT2, orderByT2))
                    .slice(pageT2 * rowsPerPageT2, pageT2 * rowsPerPageT2 + rowsPerPageT2)
                    .map((row, index) => {
                    return (
                      <StyledTableRow tabIndex={-1} key={index}>
                        <StyledTableCell style={{width: '10%'}}>{pmidCell(row)}</StyledTableCell>
                        <StyledTableCell style={{width: '35%'}}>{row.title}</StyledTableCell>
                        <StyledTableCell style={{width: '15%'}}>{row.journal}</StyledTableCell>
                        <StyledTableCell style={{width: '15%'}}>{row.publication_date}</StyledTableCell>
                        <StyledTableCell style={{width: '25%'}}>{renderActionButtons(row)}</StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <MuiTablePagination 
              rowsPerPage={rowsPerPageT2}
              page={pageT2}
              count={articlesToAdd.length}
              onPageChange={handleChangePageT2}
              onRowsPerPageChange={handleChangeRowsPerPageT2} 
            />
          </>
        ) : (
          isLoadingInner ? 
          ( <LoadingInner isLoadingInner={isLoadingInner} loadingName='mastermind'/> )
          : 
          (
          <div className="source-container center noMarginSection">
            <p>There are no Mastermind articles</p>
          </div>
          )
        )}
      </div>
    </div>
  );
};

export default LiteratureTable;

