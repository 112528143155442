import React, {forwardRef, useImperativeHandle} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    "& .MuiPaper-root": {
      backgroundColor: "#f5f5f5", //set stepper background color to gray
      overflow: "auto",
    },
    "& .MuiStepLabel-label": {
      color: "#525252",
    },
    "& .MuiStepLabel-active": {
      color: "#000",
    },
    "& .MuiStepLabel-complete": {
      color: "#000",
    }
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ['Population Frequency','Disease Databases', 'Computational Predictive Programs','Nucleotide Conservation','Literature','Interpretation','Final Review'];
}

const StepperInterpretation = forwardRef((props, ref) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const { ref1, ref2 } = ref;

  // const handleReset = () => {
  //   setActiveStep(0);
  // };

  useImperativeHandle(ref2, () => ({
    handleNext() {
      if(activeStep < 7 ){
        setActiveStep((activeStep) => activeStep + 1)
      }
    }
  }));

  useImperativeHandle(ref1, () => ({
    handleBack() {
      if(activeStep != 0 ){
        setActiveStep((activeStep) => activeStep - 1)
      }
    }
  }));

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper> 
    </div>
  )
});

export default StepperInterpretation;
