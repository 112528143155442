import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ConsensusReviews from '../ConsensusReviews';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import '../../index.css';

import BreadCrumbs from '../BreadCrumbs';
import APIUtils from '../../utils/APIUtils';
import ReviewStatus from '../../config/ReviewStatus';
import TaskStatus from '../../config/TaskStatus';
import SessionConstants from '../../config/SessionConstants';
import Loading from '../Loading';
import moment from 'moment';
import { convertUTCDateToLocalDate } from '../../utils/DateUtils';
export default class VariantDetails extends Component {

  constructor() {
    super();
    this.state = {
        breadCrumbs: [],
        variantId: null,
        expected: null,
        completed: null,
        getVariantReview: null,						 
        summary: {},
        summaryExists: false,
        requiredInterpretations: 3,
        modalVariantsIsOpen: false,
        interpretations: [],
        hasUserInterpretation: false,
        userInterpretation: {},
        dataExists: false,
        loading: true,
        errorMsg: '',
        summaryMsg: '',
        loadMe: true,
        justAddedInterpretation: null,
        programId: null,
        reviewStatus: null,
        taskStatus:null,
        taskId:null,
    };
    this.input = React.createRef();	  								 
  }

  async componentDidMount() {
    console.log('---VariantDetails.componentDidMount()');

    this.loadPassedParameters();
    this.getSummary();
    this.getInterpretationStatus();
    this.getReviewStatus();

    this.setState({
        loading: false
    })
  }

  loadPassedParameters() {
    console.log('---VariantDetails.loadPassedParameters()');
    const {
      match: { params },
    } = this.props;
    let justAddedInterpretation;
    var breadCrumbs = this.props.location.state.breadCrumbs;
    //do not add breadcrum if just add/edit interpretation
    if (this.props.location.state.justAddedInterpretation == null) {
      justAddedInterpretation = false;
      breadCrumbs = [...breadCrumbs, {
        name: 'VariantDetails',
        variantId: this.props.location.state.variantId,
        variantName: this.props.location.state.variantName,
      }]
    
    } else {
      justAddedInterpretation = true;
    }
    this.setState({
      programId: sessionStorage.getItem(SessionConstants.PROGRAM_ID),
      variantId: this.props.location.state.variantId,
      variantName: this.props.location.state.variantName,
      justAddedInterpretation: justAddedInterpretation,
      expected: this.props.location.state.expected,
      completed: this.props.location.state.completed,
      inProgress: this.props.location.state.inProgress,
      breadCrumbs: breadCrumbs,
    });
  }

  //  get the summary data for the given variant
  getSummary() {
    console.log('---VariantDetails.getSummary()');

    var programId = sessionStorage.getItem(SessionConstants.PROGRAM_ID);
    var variantId = this.props.location.state.variantId;
    var variantName = this.props.location.state.variantName;

    var summaryUpdate = {};
    summaryUpdate.variantName = variantName;
    var summaryString = this.getSummaryString(0, 0, '');
    summaryUpdate.summaryStr = summaryString;
    // console.log("*****SUMMARY STRING: " + summaryUpdate.summaryStr);

    this.setState({
        expected: this.props.location.state.expected,
        completed: this.props.location.state.completed,
        reviewStatus: this.props.location.state.reviewStatus,
        summary: summaryUpdate,
        summaryExists: true,
    })
    this.getConsensusSummary(programId, variantId);
  };

  //  gets the current user's interpretation
  async getInterpretationStatus() {
    console.log('---VariantDetails.getInterpretationStatus()');

    var variantId = this.props.location.state.variantId;

    var response = await APIUtils.getVariantInterpretation(variantId);
    if (response) {
      this.setState({
          hasUserInterpretation: true,
          userInterpretation: response,
      });
      await this.getTaskStatus();
    }
  }

  async getTaskStatus() {
    var response = await APIUtils.getTaskById(this.state.userInterpretation.taskId);
    this.setState({
        taskStatus: response.taskStatus
    });
  }

  closeModal = () => {
    this.setState({
      modalVariantsIsOpen: false
    });
  };

  handleChange = (event) => {
    this.setState({
      expected: parseInt(event.target.value)
    });
  }

  async getConsensusSummary(programId, variantId) {
    console.log('---VariantDetails.getConsensusSummary()')

    var response = await APIUtils.getConsensusSummary(programId, variantId);
    // console.log("******* GET CONSENSUS SUMMARY: " + JSON.stringify(response));
    if (response) {
      var numNbsPrograms = response.numNbsPrograms;
      var numInterpretations = response.numInterpretations;
      //THIS IS NOT IN THE RESPONSE CONSENSUS SUMMARY ANYMORE
      // console.log('getConsensusSummary numNbsPrograms=' + numNbsPrograms);
      // console.log('getConsensusSummary numInterpretations=' + numInterpretations);

      if (numInterpretations == undefined || numInterpretations == 0) {
        console.log('numInterpretations==0')
        this.setState({
            loading: false,
            errorMsg: 'There is no summary available.',
            dataExists: false,
        });
      } else {
        console.log('---available summary info')

        console.log('stateClassifications= ' + response.stateClassifications);

        console.log('summary doesnt exist, setting now');
        var summaryUpdate = {};
        summaryUpdate.variantName = response.variantName;
        summaryUpdate.gene = response.gene;
        summaryUpdate.potentialCondition = response.potentialCondition;
        var summaryString = this.getSummaryString(numNbsPrograms, numInterpretations, response.stateClassifications);
        summaryUpdate.summaryStr = summaryString;

        this.setState({
          summary: summaryUpdate,
          summaryExists: true,
        })
        var dataForUi = [];

        response.map(i => {
          var myInterpretation = {};
          myInterpretation.interpretedDate = i.interpretedDate;
          myInterpretation.potentialCondition = i.potentialCondition;
          myInterpretation.calcClassification = i.calcClassification;
          myInterpretation.stateClassification = i.stateClassification;

          myInterpretation.programId = i.programId;
          myInterpretation.comment = i.comment;

          console.log('myInterpretation (post map)');
          console.log('interpretedDate=' + myInterpretation.interpretedDate);
          console.log('potentialCondition=' + myInterpretation.potentialCondition);
          console.log('calcClassification=' + myInterpretation.calcClassification);
          console.log('stateClassification=' + myInterpretation.stateClassification);
          console.log('programId=' + myInterpretation.programId);
          console.log('comment=' + myInterpretation.comment);
          
          dataForUi.push(myInterpretation);
        });
        if (dataForUi.length) {
          this.setState({
            interpretations: dataForUi,
            loading: false,
            errorMsg: '',
            dataExists: true,
          });
        } else {
          this.setState({
            loading: false,
            errorMsg: 'There was an error retrieving the data.',
            dataExists: false,
          });
        }
      }
    } else {
      this.setState({
        loading: false,
        errorMsg: 'There was an error retrieving the data.',
        dataExists: false,
      });
    }
  };

  getSummaryString = (numNbsPrograms, numInterpretations, programClassifications) => {
    console.log('VariantDetails.getSummaryString()');
    // console.log('getSummaryString numNbsPrograms='+numNbsPrograms);
    // console.log('getSummaryString numInterpretations='+numInterpretations);
    // console.log('getSummaryString programClassifications='+programClassifications);
    if (numNbsPrograms < 1) {
        console.log('setting blank statement');
        var returnStr = 'No NBS Programs have reviewed this variant.';
        return returnStr;
    }

    var summaryBuilder = numNbsPrograms + ' Newborn Screening Program';
    if (numNbsPrograms == 1)
        summaryBuilder += ' has ';
    else
        summaryBuilder += 's have ';

    summaryBuilder += 'reviewed this variant a total of ' + numInterpretations;

    if (numNbsPrograms == 1)
        summaryBuilder += ' time ';
    else
        summaryBuilder += ' times ';

    summaryBuilder += 'and it has been classified as ';

    console.log('summaryBuilder1='+summaryBuilder);

    var counter = 0;
    // console.log("***_____Program Classification: " + programClassifications);
    for (const key of Object.keys(programClassifications)) {
      var classification = key;
      var classCount = programClassifications[key];

      console.log('classification='+classification)
      console.log('classification='+classification.toLowerCase)

      console.log('classCount='+classCount)

      var lowerClassification = classification.toLowerCase();

      var miniBuilder = '';
      if (counter > 0)
          miniBuilder += ', ';

      if (classCount == 1)
          miniBuilder += lowerClassification + ' ' + classCount + ' time';
      else
          miniBuilder += lowerClassification + ' ' + classCount + ' times';

      summaryBuilder += miniBuilder;
      counter++;
    }

    console.log('summaryBuilder3='+summaryBuilder);

    return summaryBuilder;
  }

  //get the review Status for the given variant
  async getReviewStatus() {
    const programId = sessionStorage.getItem('programId');
    const variantId = this.props.location.state.variantId;
    console.log('---VariantDetails.getReviewStatus()');
    
    var response = await APIUtils.getReview(programId, variantId);

    if (response) {
      console.log('have response')
      console.log('programId=' + response.programId)
      console.log('variantId=' + response.variantObjectId)
      console.log('reviewStatus=' + response.reviewStatus)
      console.log('createdDate=' + response.createdDate);
      console.log('lastEdited=' + response.lastEdited);
      console.log('closeDate=' + response.closeDate);
      //  determine number of expected, completed, and in progress interpretations
      let expected = response.requiredInterpretations;
      let completed = 0;
      let inProgress = 0;
      for (let [i, interpretation] of Object.entries(response.variantInterpretations)) {
          if (interpretation.partial === true) {
              inProgress = inProgress + 1;
          }
          else {
              completed = completed + 1;
          }
      }

      this.setState({
        programId: response.programId,
        variantId: response.variantObjectId,
        reviewStatus: response.reviewStatus,
        createdDate:convertUTCDateToLocalDate(response.createdDate),
        lastEdited: convertUTCDateToLocalDate(response.lastEdited),
        closedDate: convertUTCDateToLocalDate(response.closeDate),
        gene: response.variant.gene,
        condition: response.variant.condition,
        expected: expected,
        completed: completed,
        inProgress: inProgress,
      });
    } else {
      this.setState({
        loading: false,
        errorMsg: 'There was an error retrieving the data.',
        dataExists: false,
      });
    }
  };

  render() {
    console.log('---VariantDetails.render()');
    var variantName = (this.state.summary != undefined ? this.state.summary.variantName : '');

    return (
      <div className="variantDetails">
        <Loading isLoading={this.state.loading}/>

        <Helmet>
            <title>ED3N - Variant Details</title>
        </Helmet>

        <BreadCrumbs breadCrumbs={this.state.breadCrumbs} />

        <div className="button-left-right-bar">
            <div>
                <Link tabIndex="-1"
                    to={{
                        pathname: "/programsummary",
                        state: {
                            programId: this.state.programId,
                        },
                    }}
                >
                    <button className="actionButton">
                        <FontAwesomeIcon icon={faChevronLeft} />Back to Program Summary
                    </button>
                </Link>
            </div>
        </div>
        <section>
          <header>
            <h1 className="pageTitle">
                Variant Name: {variantName}<br />
                Gene: {this.state.gene}<br />
                Potential Condition: {this.state.condition}
            </h1>
          </header>
        </section>
        <div className="button-bar">
        </div>

        {this.state.hasUserInterpretation ? 
        <section>
          <div className="section">
            <h2 className="sectionHeading">
              Your Activity
            </h2>
            <div className="sectionContent">
              {this.state.userInterpretation.partial  ?
              ('You have an interpretation for this variant review assigned to you.') : ('You have submitted an interpretation for this variant review.')}
              <br /><br />
              Interpretation Classification: {this.state.userInterpretation.programClassification}<br/>
              Date Last Edited: {moment(convertUTCDateToLocalDate(this.state.userInterpretation.interpretedDate)).format("MM/DD/YYYY")}
              <br /><br />
                <Link
                  tabIndex="-1"
                  to={{
                      pathname: '/addinterpretation',
                      state: {
                          summary: this.state.summary,
                          breadCrumbs: this.state.breadCrumbs,
                          programId: this.state.programId,
                          gene: this.state.gene,
                          variantId: this.state.variantId,
                          variantName: this.state.variantName,
                          variantInterpretationId: this.state.userInterpretation.variantInterpretationId
                      }
                  }}
                >
                  <button className="actionButton">
                    <FontAwesomeIcon icon={'edit'} />Edit Your Interpretation
                  </button>
                </Link> 
            </div>
          </div>
        </section>
        : 
        <section>
          <div className="section">
            <h2 className="sectionHeading">
              Your Activity
            </h2>
            <div className="sectionContent">
              No interpretation assigned to you.
            </div>
          </div>
        </section>
        }

        <section>
          <div className="section">
            <h2 className="sectionHeading">
              Latest Variant Review by Your Program
            </h2>
            <div className="sectionContent">
                <div>
                  {this.state.reviewStatus == ReviewStatus.REVIEW_CREATED &&
                      <div>
                        Current Review Status: {this.state.reviewStatus}<br />
                        Date Created:    {this.state.createdDate}<br />
                        Interpretation Status: {this.state.expected} expected, {this.state.completed} completed, {this.state.inProgress} in progress<br />
                      </div>
                  }
                  {this.state.reviewStatus == ReviewStatus.IN_PROGRESS  &&                  
                      <div>
                        Current Review Status: {this.state.reviewStatus}<br />
                        Date Created:    {this.state.createdDate}<br />
                        Date Last Edited: {this.state.lastEdited}<br />
                        Interpretation Status: {this.state.expected} expected, {this.state.completed} completed, {this.state.inProgress} in progress<br />
                      </div>                   
                  }

                  {(this.state.reviewStatus == ReviewStatus.READY_FOR_CONSENSUS  ||  
                    this.state.reviewStatus == ReviewStatus.CONSENSUS_IN_PROGRESS)  &&                   
                      <div>
                        Current Review Status: {this.state.reviewStatus}<br />
                        Date Created:    {this.state.createdDate}<br />
                        Date Last Edited: {this.state.lastEdited} <br />                     
                      </div>                   
                  }
                  {this.state.reviewStatus == ReviewStatus.CLOSED  &&                  
                      <div>
                        Current Review Status: {this.state.reviewStatus}<br />
                        Date Created:    {this.state.createdDate}<br />
                        Date Last Edited: {this.state.lastEdited} <br />
                        Date Closed: {this.state.closedDate}<br />              
                      </div>                   
                  }
                </div>            
            </div>
          </div>
        </section>

        <section>
          <div className="section">
            <h2 className="sectionHeading">
              Final Consensus Reviews by All Programs
            </h2>
            <div className="sectionContent">
              {this.state.loadMe || this.state.justAddedInterpretation ? (
                  <ConsensusReviews variantId={this.state.variantId} />
                  ) : ('')
              }
            </div>
          </div>
        </section>
      </div>
    );
  }
}