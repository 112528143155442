import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SessionConstants from '../../config/SessionConstants.js';
import {Helmet} from "react-helmet";
import { Grid } from '@material-ui/core';

import './index.css';

export default class Home extends Component {

  state = {
    isAuthenticated: true,
    comingSoon1:false,
    comingSoon2:false
  }

  async componentDidMount() {
    console.log('--Home.componentDidMount()');
  }

  toggleComingSoon(val, isShow){
    if (val==1){
      this.setState({comingSoon1: isShow});
    }
    else if (val==2){
      this.setState({comingSoon2: isShow});
    }
  }

  render() {

    return (
      <>
        <Helmet>
          <title>ED3N - Home</title>
        </Helmet>
        <section>
          <div className="welcomeSection">
            <header>
              <h1 className="tagline">
                Enhancing Data-driven Disease Detection in Newborns (ED3N)
              </h1>
              <div className="description">
                ED3N serves as a secure, centralized
                resource for newborn screening partners to analyze and share
                biochemical and molecular data. ED3N supports laboratory
                best practices through standardized workflows and robust, validated
                analytical tools.
              </div>
            </header>
          </div>
          <div className="buttonsSection">
            <Grid container spacing={0}>
              <Grid item xs={12} sm={4}>
                <Link
                  className="landingButton card ds-7"
                  to={{
                      pathname: "/programsummary",
                      state: {
                          programId: sessionStorage.getItem(SessionConstants.PROGRAM_ID),
                      },
                  }}
                >
                  <div className="landingButtonIcon">
                    <FontAwesomeIcon icon={'list-alt'} alt="" />
                  </div>
                  <div className="landingButtonTitle">Evaluate</div>
                  <div className="landingButtonSubtitle">
                    Routine testing workflows for decision support
                  </div>
                  <div className="landingButtonText">
                    View and analyze your newborn screening data at the individual
                    patient level to enhance disease detection.
                  </div>
                </Link>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Link className="landingButtonDisabled card ds-7" 
                  onClick={e=>e.preventDefault()} 
                  to="/research"
                  aria-disabled="true"
                  aria-label="Explore - Coming Soon"
                  onMouseOver={()=>this.toggleComingSoon(1,true)}  
                  onMouseOut={()=>this.toggleComingSoon(1,false)}
                  onFocus={()=>this.toggleComingSoon(1,true)}
                  onBlur={()=>this.toggleComingSoon(1,false)}>
                  {this.state.comingSoon1 == false ?
                    (
                    <>
                    <div className="landingButtonIcon">
                      <FontAwesomeIcon icon={'book'} alt="" />
                    </div>
                    <div className="landingButtonTitle">Explore</div>
                    <div className="landingButtonSubtitle">
                      Data repository for exploring correlations and trends
                    </div>
                    <div className="landingButtonText">
                      Explore de-identified, aggregate data. Ask what-if questions. Try
                      out new workflows before they are validated and moved to the
                      evaluate module.
                    </div>
                    </>)
                    :
                    <div className="comingSoon">COMING SOON</div>
                  }
                </Link>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Link className="landingButtonDisabled card ds-7" 
                  onClick={e=>e.preventDefault()} 
                  to="/educate"
                  aria-disabled="true"
                  aria-label="Educate - Coming Soon"
                  onMouseOver={()=>this.toggleComingSoon(2,true)}  
                  onMouseOut={()=>this.toggleComingSoon(2,false)}
                  onFocus={()=>this.toggleComingSoon(2,true)}
                  onBlur={()=>this.toggleComingSoon(2,false)}>
                  {this.state.comingSoon2 == false ?
                    (
                    <>
                    <div className="landingButtonIcon">
                      <FontAwesomeIcon icon={'chalkboard-teacher'} alt="" />
                    </div>
                    <div className="landingButtonTitle">Educate</div>
                    <div className="landingButtonSubtitle">
                      Helpful tutorials and training materials
                    </div>
                    <div className="landingButtonText">
                      Learn how to navigate ED3N. Improve your data analysis
                      skills. Find materials to train newborn screening personnel in
                      best practice workflows.
                    </div>
                    </>
                    )
                    :
                    <div className="comingSoon">COMING SOON</div>
                  }
                </Link>
              </Grid>
            </Grid>
          </div>
        </section>
        <section>
          <div className="noticesSection">
            <h2 className="noticesHeading">NOTICES AND WARNINGS</h2>
            <div className="notice">
              NOTICE: Information in ED3N has not been
              cleared or approved by the FDA. The information in these databases
              is for RESEARCH USE ONLY. The results presented here should NOT be
              used for diagnosis, treatment, or assessment of patient health or
              management.
            </div>
            <div className="notice">
              WARNING: This warning banner provides privacy and security notices 
              consistent with applicable federal laws, directives, and other 
              federal guidance for accessing this Government system, which 
              includes all devices/storage media attached to this system. This 
              system is provided for Government-authorized use only. Unauthorized 
              or improper use of this system is prohibited and may result in 
              disciplinary action and/or civil and criminal penalties. At any 
              time, and for any lawful Government purpose, the government may 
              monitor, record, and audit your system usage and/or intercept, 
              search and seize any communication or data transiting or stored on 
              this system. Therefore, you have no reasonable expectation of 
              privacy. Any communication or data transiting or stored on this 
              system may be disclosed or used for any lawful Government purpose.
            </div>
          </div>
        </section>
      </>
    );
  }
}
