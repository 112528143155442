import React, {useState} from 'react';
import { Route, Redirect } from 'react-router-dom';
import CdcHeader from '../CdcHeader';
import CdcFooter from '../CdcFooter';
import Navbar from '../Navbar';
import SessionTimeout from '../SessionTimeout/SessionTimeout.js';
import { NavHashLink } from 'react-router-hash-link';
import SessionConstants from '../../config/SessionConstants';
import LoginValidator from '../LoginValidator'

import './index.css';

const AuthenticatedRoute = ({ component: Component, ...rest }) => {

    //check session for fullscreen setting
    const [fullscreen] = useState(JSON.parse(sessionStorage.getItem(SessionConstants.FULLSCREEN)));

    console.log(" -- AuthenticatedRoute --")
    const [isAuthenticated, setIsAuthenticated] = useState(null);

    const handleValidation = (authenticated) => {
        setIsAuthenticated(authenticated);
    };

    return (
        <Route
            {...rest}
            render={matchProps => (
                <LoginValidator onValidation={handleValidation}>
                    {isAuthenticated === null ? (
                        <div>Loading...</div>
                    ) : isAuthenticated ? (
                        <>
                            <nav aria-label='skip navigation'>
                                <NavHashLink
                                    to="#skip-to-content"
                                    className="skipNavLink"
                                >
                                    Skip to content
                                </NavHashLink>
                            </nav>
                            <CdcHeader />
                            <nav><Navbar /></nav>
                            <SessionTimeout />
                            <div className={fullscreen ? 'container-wide' : 'container'} role="main">
                                <div id="skip-to-content"></div>
                                <Component {...matchProps} />
                            </div>
                            <CdcFooter />
                        </>
                    ) : (
                        <Redirect to="/landing" />
                    )}
                </LoginValidator>
            )}
        />
    );
};

export default AuthenticatedRoute;