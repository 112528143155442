import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import BreadCrumbs from '../BreadCrumbs';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container'
import InterpretationsTable from './InterpretationsTable/index.js';
import SessionConstants from '../../config/SessionConstants';
import APIUtils from '../../utils/APIUtils'
import './index.css';
import AdministrativeTasksTable from './AdministrativeTasksTable/index.js';
import Loading from '../Loading';

export default class UserDashboard extends Component {

  state = {
    breadCrumbs: [],
    interpretationsData: [],
    administrativeData: [],
    inProgress: 0,
    pending: 0,
    loading:true,
  }

  async componentDidMount() {
    console.log('--UserDashboard.componentDidMount()');
    
    this.setState({
      breadCrumbs: [{
        name: 'UserDashboard',
      }],
    });

    //Load Tasks    
    let response = await APIUtils.getTasksByUser();
    if (response) { //first check if response at all, else hide loading
        let inProgress = response.filter(function (e) {
          return e.taskType == "MOLECULAR_INTERPRETATION" ? e.taskStatus == "IN_PROGRESS" : '' //only interpretation tasks
        });
        let newTasks = response.filter(function (e) {
          return e.taskType == "MOLECULAR_INTERPRETATION" ? e.taskStatus == "NEW" : '' //only interpretation tasks
        });
        let molecularInterpretations = response.filter(function (e) {
          return e.taskType == "MOLECULAR_INTERPRETATION"
        });
        let administrativeTasks = response.filter(function (e) { //everything not molecular interpretation is admin tasks
          return e.taskType != "MOLECULAR_INTERPRETATION"
        });
        this.setState({
          interpretationsData: molecularInterpretations,
          inProgress: inProgress.length,
          newTasks: newTasks.length,
          administrativeData: administrativeTasks,
          loading: false
        });
    } else {
      this.setState({
        loading: false
      }) 
    }
  }
   
  render() {
    const programs = JSON.parse(sessionStorage.getItem(SessionConstants.NBS_PROGRAM));
    return (
        <div className="userDashboard">
          <Loading isLoading={this.state.loading}/>
          <Helmet>
            <title>ED3N - Dashboard</title>
          </Helmet>
          <BreadCrumbs breadCrumbs={this.state.breadCrumbs} />
          <h1 className="pageTitle">Dashboard</h1>
          <Container maxWidth="xl">
            <Grid container spacing={3} className="dashGridContainer">
              <Grid item xs={12} sm= {12} md={6}>
                <Paper className="card userDashCard" elevation={0}>
                  <div className="userDashProfile">
                    <div className="dashProfileIcon">
                      <FontAwesomeIcon icon={faUserCircle} size="6x" />
                    </div>
                    <div className="dashProfileDescription">
                      <div className="dashProfileName">
                        {sessionStorage.getItem(SessionConstants.USER_FIRSTNAME)} {sessionStorage.getItem(SessionConstants.USER_LASTNAME)}</div>
                      <div>{sessionStorage.getItem(SessionConstants.USER_EMAIL)}</div>
                      <div>
                        <ul>
                          { programs.map((x)=> <li key={x.name}>{x.shortName}</li>)}
                        </ul>
                      </div>
                    </div>
                  </div>
                </Paper>
              </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Paper className="card userDashCard" elevation={0}>
                            <div className="userDashCallout">
                                <div className="dashCalloutNumber">{this.state.newTasks}</div>
                                <div className="dashCalloutDescription">New Assignments</div>
                            </div>
                        </Paper>
                    </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Paper className="card userDashCard" elevation={0}>
                  <div className="userDashCallout">
                    <div className="dashCalloutNumber">{this.state.inProgress}</div>
                    <div className="dashCalloutDescription">In-Progress Interpretations</div>
                  </div>
                </Paper>
              </Grid>
              
              <Grid item xs={12}>
                <Paper className="card userDashTable" elevation={0}>
                  <div className="dashTableDescription">Assigned Interpretations</div>
                  { this.state.interpretationsData.length ? (
                    <InterpretationsTable
                      data={this.state.interpretationsData}
                    />
                    ) : (
                        'No Assigned Interpretations'
                    )
                  }
                </Paper>
              </Grid>
              { this.state.administrativeData.length ? ( //hide entire card if empty -- no admin tasks
                <Grid item xs={12}>
                  <Paper className="card userDashTable" elevation={0}>
                    <div className="dashTableDescription">Administrative Tasks</div>
                      <AdministrativeTasksTable
                        data={this.state.administrativeData}
                      />
                  </Paper>
                </Grid>
                ) : (
                ''
                )
              }
            </Grid>
          </Container>
        </div>
    );
  }
}
