import React, { Component } from 'react';
import Modal from 'react-modal';
import APIUtils from '../../../utils/APIUtils';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import SessionConstants from '../../../config/SessionConstants';
import ReminderFrequency from '../../../config/ReminderFrequency';
import Combobox from "react-widgets/Combobox";
import SuccessMessageModal from '../../SuccessMessageModal';
import { TextField, MenuItem } from '@material-ui/core';
import "react-widgets/styles.css";
import './index.css'

const moment= require('moment') 
export default class VariantSettingsModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            variantReviewId: this.props.data.variantReviewId,
            dueDate:  moment(this.props.data.dueDate),
            reminderFrequency: this.props.data.reminderFrequency,
            initialReqInterpreters: this.props.data.requiredInterpretations,
            requiredInterpretations: this.props.data.requiredInterpretations,
            variantInterpretations: this.props.data.variantInterpretations,
            variantReviewId: this.props.data.variantReviewId,
            initInterpreters: [],
            defaultInterpreters:[],
            availableUsers: [],
            minInterpreters: 1,
            completedInterpreters:0, 
            errorMessage:[],
            successMessageModalIsOpen:false,
            successMessage:[],
            needRefresh:false
        };
    }

    async componentDidMount() {
        var programId = sessionStorage.getItem(SessionConstants.PROGRAM_ID);
        var userResponse = await APIUtils.getProgramUsers(programId);
        var users = userResponse;
        users.forEach(user => {
            user.fullName= user.firstName + ' ' + user.lastName;
        });
        let defaultInterpreters = [];
        let interpretationCompletedCount = 0;

        //  populate array from current variant interpretation's userId
        for (var i = 0 ; i < this.state.variantInterpretations.length; i++) {
            defaultInterpreters.push(this.state.variantInterpretations[i].user.email);
            if (this.state.variantInterpretations[i].partial == false) 
                this.setState({minInterpreters: ++interpretationCompletedCount});
        }
        //set element to null if number of interpreters is less than required interpretations
        let reqInterps = this.state.requiredInterpretations;
        for (var i = defaultInterpreters.length; i < this.state.requiredInterpretations.length; i++) {
            defaultInterpreters[i] = ''
        };
        
        this.setState({
            programId: programId,
            availableUsers: users,
            defaultInterpreters: defaultInterpreters,
            initInterpreters: defaultInterpreters,
            requiredInterpretations: reqInterps,
            initialReqInterpreters: reqInterps,
        });
    }

    onClose = () => {
        let initCount = this.state.initialReqInterpreters;
        let initInterpreters = this.state.initInterpreters;
        this.setState({
            errorMessage: [],
            requiredInterpretations: initCount,
            defaultInterpreters: initInterpreters
        });
        this.props.closeModal();
    };

    setDefaultInterpreters = () => {
        //  populate array from current variant interpretation's userId
        for (var i = 0; i < this.state.variantInterpretations.length; i++) {
            defaultInterpreters.push(this.state.variantInterpretations[i].user.email);
            if (this.state.variantInterpretations[i].partial == false)
                this.setState({ minInterpreters: ++interpretationCompletedCount });
        }
        //set element to null if number of interpreters is less than required interpretations
        let reqInterps = this.state.requiredInterpretations;
        for (var i = defaultInterpreters.length; i < this.state.requiredInterpretations.length; i++) {
            defaultInterpreters[i] = ''
        };
    }

    setInterpreter = (index, selectedUser, requiredInterpretations) => {
        let errorMessage = [];
        let visibleUser = [];
        let defaultInterpreters = [...this.state.defaultInterpreters];
        defaultInterpreters[index] = selectedUser.email;
        console.log("defaultInterpreters = " + defaultInterpreters);

        for (var i = 0; i < requiredInterpretations; i++) {
            if ((defaultInterpreters[i] !== "") && (defaultInterpreters[i] != undefined)) {
                visibleUser.push(defaultInterpreters[i]);
            }
        }

        //check for duplicate
        var unique = Array.from(new Set(visibleUser));
        var unique2 = unique.filter(function (value, index, arr) {
            return ((value != "") && (value != undefined));
        });
        if (visibleUser.length != unique2.length)
            errorMessage.push("Please remove duplicate interpreters.");
    
        this.setState({
            errorMessage: errorMessage,
            defaultInterpreters: defaultInterpreters 
        });
    }
  
  setRequiredInterpretations = (e) => {
    let requiredInterpretations = parseInt(e.target.value);
    if (requiredInterpretations >= this.state.minInterpreters) {
      this.setState({
        requiredInterpretations: requiredInterpretations
      });
      // console.log("requiredInterpretations", requiredInterpretations)
      let defaultInterpreters = [...this.state.defaultInterpreters];
      if (requiredInterpretations >= defaultInterpreters.length) {
        //ensure array size insync with any change to requiredInterpretations
        for(var i = defaultInterpreters.length; i < e.target.value; i++) {
          defaultInterpreters.push('');
        }
        this.setState({
          defaultInterpreters: defaultInterpreters 
        });
      }
    }
    else {
      return false;
    }
  }

  getDefaultUsers = (i) => {
    try {
      //find user by userId 
      const user = this.state.availableUsers.filter(user => user.email == this.state.variantInterpretations[i].user.email);
      return user[0].fullName;
    }
    catch {
      return "";
    }
  }

    onSubmit = async() => {
        console.log("On submit")
        const {reminderFrequency, requiredInterpretations, dueDate, defaultInterpreters } = this.state;

        if (this.state.errorMessage.length == 0) {

            if (defaultInterpreters.length > requiredInterpretations)
                defaultInterpreters.splice(requiredInterpretations, defaultInterpreters.length - requiredInterpretations);
            //save variant review setting     
            let formData = new FormData();
            formData.append('reminderFrequency', reminderFrequency);
            formData.append('requiredInterpretations', requiredInterpretations);
            formData.append('dueDate', moment(dueDate).format('MM/DD/YYYY'));
            await this.saveVariantSetting(formData);
            this.setState({
                initialReqInterpreters: requiredInterpretations
            });
        }
    }

    saveVariantSetting = async(formData) => {
      // console.log("on saveVariantSetting:", formData)
        this.setState({
            successMessage: []
        });
        try {
            await APIUtils.updateReviewSettings(this.state.variantReviewId, formData)
            .then((response1) => {
                // this.setState({successMessage:[response1.data]});
                this.addSuccessMessage(response1);
                this.assignInterpreation()
                .then((response2) => {
                    this.unassignInterpreation()
                    .then((response3) => {
                      // console.log("response3:", response2)
                        this.setState({
                            errorMessage: [],
                            successMessageModalIsOpen: true
                        });
                        this.onClose(); 
                    });
                });
            });
        }
        catch (err) {
         
            this.setState({
            errorMessage: response1 + " " + err
            });
        }
    }

  addSuccessMessage = (message) => {
    let messages = [...this.state.successMessage];
    messages.push(message);
    this.setState({
      successMessage: messages
    });
  }

  assignInterpreation = async() => {
     this.state.defaultInterpreters.map(async (email) => {  
        const variantInterpretation = this.state.variantInterpretations.filter(item => item.user.email == email);   
        if (variantInterpretation.length == 0) {
            let formData = new FormData();
            formData.append('userId',  email);
            //if not found, assign interpretation
            let response = await APIUtils.assignInterpretation(this.state.variantReviewId, formData);
            this.addSuccessMessage(response);
            // console.log(response);
        }
     });
  }
  
  unassignInterpreation = async() => {
    Promise.all(this.state.variantInterpretations.map(async(variantInterpretation) => {
      const email = this.state.defaultInterpreters.filter(email => email == variantInterpretation.user.email);
      if (email.length == 0) {
        let formData = new FormData();
        formData.append('userId',  variantInterpretation.user.email);
          //unassign interpreation
          let response =  await APIUtils.unassignInterpretation(this.state.variantReviewId, formData);
          this.addSuccessMessage(response);
          // console.log("unassign", response);


      }
    }));
 }

 closeSuccessModal = () => {
  this.setState({ 
    successMessageModalIsOpen: false
  });
  this.props.refresh();
}
 
  
render() {
  const disabledCombobox = [];
  const enableCombobox = [];
  let variantInterpretations = this.state.variantInterpretations;
    for(var i = 0; i < this.state.requiredInterpretations; i++) {
      const defaultUser = this.getDefaultUsers(i);
      const index = i;//need to save this
      const disabled = (defaultUser !== "") ? (variantInterpretations[i].partial == false) : false;
      const combobox =
        <div key={i} className="comboBox interpreterSettingsCombo">
          <label className='interpreterLabel' htmlFor={'interpreter_' + (i+1).toString() + '_input'} >{'Interpreter ' + (i+1)}</label>
          <Combobox    
            className="interpreterSelect"
            data={this.state.availableUsers}
            dataKey='email'
            textField='fullName'
            defaultValue={defaultUser}
            disabled={disabled}
            onChange={key => this.setInterpreter(index, key,this.state.requiredInterpretations)}
            filter="contains"
            hideCaret
            hideEmptyPopup
            focusFirstItem
            id={'interpreter_' + (i+1).toString()}
          />
        </div>
      if (disabled) {
        disabledCombobox.push(combobox);
      }
      else {
         enableCombobox.push(combobox);
      }
    }

    return (
      <div key={this.props.childKey}>
        <Modal
          className="modal"
          overlayClassName="modalOverlay"
          aria-modal="true"
          contentLabel="Variant Review Settings"
          shouldCloseOnOverlayClick={true}
          isOpen={this.props.modalIsOpen}
          onRequestClose={this.onClose}
        >
          <div className="modalHeader">Variant Review Settings</div>
          <div className="modalContent center">
            {/* REMOVING this section for now. Will reimplement with alerts
            <div className="sectionHeading center">
              <label htmlFor="dueDate">Due Date</label>
            </div>
            <div className="subSection dueDate dueDatePickerContainer">
              <DatePicker
                selected={this.state.dueDate}       
                onChange={date => {
                  this.setState({ dueDate: date });
                }}
                todayButton={'Today'}
                minDate={new Date()}
                placeholderText="Click to select a date"
                popperPlacement="bottom"
                className="dueDatePicker"
                id="dueDate"
              />
              <FontAwesomeIcon icon={faCalendar} className="primary datePickerIcon" /> 
            </div>
            <div className="sectionHeading center">
              Reminder Frequency
            </div>
            <div className="subSection">
            <input type="radio" name="dailyOrWeekly" value={ReminderFrequency.DAILY}                 
                 defaultChecked={this.state.reminderFrequency == ReminderFrequency.DAILY} 
                 onChange={(e) =>  this.setState({ reminderFrequency: e.target.value })} id="daily"
                /><label htmlFor="daily">{ReminderFrequency.DAILY}</label>
              <input type="radio" name="dailyOrWeekly" value={ReminderFrequency.WEEKLY}
                defaultChecked={this.state.reminderFrequency == ReminderFrequency.WEEKLY} 
                onChange={(e) =>  this.setState({ reminderFrequency: e.target.value })} id="weekly"
                /><label htmlFor="weekly">{ReminderFrequency.WEEKLY}</label>
            </div>
            */}
            <div className="sectionHeading center">
              <label htmlFor="number-of-interpreters">Number of Required Interpretations (1-5)</label>
            </div>
            <div className="subSection">
                {/* DEFAULTS TO PRESET AT PROG LEVEL. default value of num of interpretations will need to pull from state/props, and also update on save*/}
              <div className="modalInput">
                <TextField
                    select
                    size="small"
                    name="numberOfInterpretations" 
                    id="number-of-interpreters"
                    aria-label="Set number of Interpretations" 
                    value={this.state.requiredInterpretations ? this.state.requiredInterpretations : ""}
                    onChange={this.setRequiredInterpretations} 
                    variant="outlined"
                    SelectProps={{ 
                      MenuProps: { 
                        disableScrollLock: true, 
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left"
                        },
                        getContentAnchorEl: null
                      }, 
                    }}
                  >
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                  </TextField>
              </div>
            </div>
            <div className="sectionHeading center">
              Assigned Interpreters
            </div>
            {this.state.errorMessage.map((err)=><li key={i} className="error">{err}</li>)}
            <div className="subSection"> 
               {disabledCombobox}
               {enableCombobox}   
            </div>
          </div>
          <div className="modalButtons">
            <button
              className="modalButton primaryButton"
              onClick={this.onSubmit}
            >
              Save
            </button>
            <button className="modalButton" onClick={this.onClose}>
              Cancel
            </button>
          </div>
        </Modal>
        <SuccessMessageModal 
           closeModal={this.closeSuccessModal}
           modalIsOpen={this.state.successMessageModalIsOpen}
           message={this.state.successMessage}
        />
      </div>
    );
  }
}
