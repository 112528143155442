import React, { useEffect, useState, useRef } from "react";
import Modal from 'react-modal';
import APIUtils from '../../../utils/APIUtils';

const literatureModal = (props) => {
  const [pmid, setPmid] = useState(props.literatureReview.pmid);
  const [comment, setComment] = useState(props.literatureReview.comment);
  const [acmgCriteriaId, setAcmgCriteriaId] = useState(props.literatureReview.acmgEvidCriteria.acmgEvidCriteriaId);
  const [literatureCategory, setliteratureCategory]=useState(props.literatureReview.literatureCategory);
  const [categories, setCategories] = useState(props.categories);
  const selectCategory = useRef(null);
  const selectCriteria = useRef(null);
  const inputPmid = useRef(null);
  const inputComment =useRef(null);

  useEffect(() => {
    if (pmid != props.literatureReview.pmid){
      setPmid(props.literatureReview.pmid);
      setComment(props.literatureReview.comment);
      setAcmgCriteriaId(props.literatureReview.acmgEvidCriteria.acmgEvidCriteriaId);
      setliteratureCategory(props.literatureReview.literatureCategory);
      setCategories(props.categories);
    }
  },[props]); //[props] - to reload on props changes

  useEffect(() => {
    document.body.setAttribute('aria-hidden', 'false');
  },[]); 

  async function saveLiteratureReivew() {
    if (pmid == '') { 
      inputPmid.current.focus();
      return;
    }
    if (literatureCategory == null || literatureCategory == "") {
      selectCategory.current.focus(); //user must select ACMG criteria
      return;
    }
    if (acmgCriteriaId == null || acmgCriteriaId == "") {
      selectCriteria.current.focus(); //user must select ACMG criteria
      return;
    }
    if (comment == '') {
      inputComment.current.focus(); //user must add comment
      return;
    }
    let formData = new FormData();
    formData.append('pmid', pmid)
    formData.append('comment', comment)
    formData.append('acmgCriteriaId', acmgCriteriaId);
    formData.append('literatureCategory', literatureCategory);
    let response = await APIUtils.saveLiteratureReview(props.variantInterpretationId, formData);
    props.onAddOrUpdate();
  }

  const handleOnChange = (e) => {
    setliteratureCategory(e.target.value);
    //cleare selected value
    setAcmgCriteriaId();
    selectCriteria.current.value = '';
  }

  const returnFocus = () => {
    document.getElementById(props.focus).focus();
  }

 return (
  <Modal
    className="modal"
    overlayClassName="modalOverlay"
    aria-modal="true"
    contentLabel="Add Literature"
    shouldCloseOnOverlayClick={true}
    shouldReturnFocusAfterClose={true}
    onAfterClose={returnFocus}
    isOpen={props.modalIsOpen}
    onRequestClose={props.closeModal}
  >
    <div className="modalHeader">Add Literature Evidence</div>
    <div className="modalContent">
      <div className="requiredMessageContainer">
        <span className="requiredIndicator">*</span> Indicates a required field.
      </div>
      <div className="form-container">
        <form>
          <div className="form-item">
            <label htmlFor="pmid">PMID {props.literatureReview.pmid.length > 0 ? '' : <span className="requiredIndicator">*</span>}
            </label>
            {props.literatureReview.pmid.length > 0 
            ? props.literatureReview.pmid
            : <input
                type="text" className="form-control" aria-label="PMID" 
                id="pmid"
                name="pmid"
                placeholder="Enter PMID"
                value={pmid}
                ref={inputPmid}
                onChange={(e) => setPmid(e.target.value)} />
            }
            </div>
          <div className="form-item">
            <label htmlFor="litCategory">Type of Evidence <span className="requiredIndicator"> *</span></label>  
            <select 
              id="litCategory"
              name="category"
              defaultValue={literatureCategory}
              ref={selectCategory}
              onChange={(e) => handleOnChange(e)}
            >
              <option key="default1" value="" >Please select category</option>
              {categories.map(( category, index ) => (
                <option key={index} value={category.LiteratureCategoryValue}>
                  {category.LiteratureCategoryLabel}
                </option>
              ))}
            </select>
          </div>
          <div className="form-item">
            <label htmlFor="acmgCriteria">ACMG Criterias <span className="requiredIndicator">*</span></label>  
            <select 
              id="acmgCriteria"
              name="acmgCriteria" 
              ref={selectCriteria} 
              defaultValue={acmgCriteriaId}
              onChange={(e) => setAcmgCriteriaId(e.target.value)}
            >
              <option key="default2" value="">Please select criteria</option>
              {categories.filter(category => category.LiteratureCategoryValue === literatureCategory)
                .map(filterCategory => 
                  (filterCategory.ACMGCriterias.map((criteria, index) =>
                    (<option key={index} value={criteria}>{criteria}</option>))                               
                  ))
              }
            </select>
          </div> 
          <div className="form-item">
            <label htmlFor="comments">Comments <span className="requiredIndicator">*</span></label>
            <textarea
              id="comments"
              rows="6"
              cols="30"
              name="comments"
              defaultValue={comment}
              ref={inputComment}
              onChange={(e) => setComment(e.target.value)}
            />
          </div>
        </form>
      </div>
    </div>
    <div className="modalButtons">
      <button className="modalButton" onClick={props.closeModal}>
        Cancel
      </button>
      <button className="modalButton primaryButton"  onClick={saveLiteratureReivew}>
        Save
      </button>
    </div>
  </Modal>
  );
}

export default literatureModal;

