import React, { useState, useEffect } from 'react';

import { convertUTCDateToLocalDate } from '../../../utils/DateUtils';
import ReviewStatus from '../../../config/ReviewStatus';
import ConfirmationModal from '../../../uiComponents/ConfirmationModal';
import SuccessMessageModal from '../../SuccessMessageModal';
import Button from '../../../uiComponents/Button';
import LinkLabel from '../../../uiComponents/LinkLabel';
import './index.css'
import APIUtils from '../../../utils/APIUtils';
import SessionConstants from '../../../config/SessionConstants';
import { TableContainer,TableBody,Paper,Table,makeStyles} from '@material-ui/core';
import MuiTableHeader from '../../../uiComponents/MuiTable/MuiTableHeader';
import TableFilter from '../../../uiComponents/MuiTable/TableFilterGrid';
import MuiTablePagination from '../../../uiComponents/MuiTable/MuiTablePagination';
import {stableSort,getComparator} from '../../../uiComponents/MuiTable/MuiHelper'
import {StyledTableRow,StyledTableCell} from '../../../uiComponents/MuiTable/StyledTable'
import Loading from '../../Loading';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    fontFamily: "Open Sans",
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function ProgramVariantsTable(props) {
  const classes = useState();
  const [rows,setRows] = useState(props.data);  //hold original dataset
  const [tableData,setTableData] = useState([]); //  
  const [isLoading,setIsLoading] = useState(false);
    //props for Modal component
    const [isOpen,setIsOpen] = useState(false);
    const [modalTitle,setModalTitle] = useState('');
    const [modalMessage,setModalMessage] = useState('');
    const [action,setAction] = useState('');
    const [variantReviewId,setVariantReviewId] = useState(null);
    const [variantName,setVariantName] = useState('');
    //props for Success Modal Component
    const [isSuccessModalOpen,setIsSuccessModalOpen] = useState(false);
    const [successMessage,setSuccessMessage] = useState([]);
  //props on sort table
    const [order,setOrder] = useState('asc');
    const [orderBy,setOrderBy] = useState('variantName');
    //prop for paging
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
  
    //props for filter     
    const [searchFilter, setSearchFilter] = useState('');
    const [filterBy, setFilterBy] = useState('');
  
  //initil load and when filter changed or loading new dataset
  useEffect(() => {
    //if upload was successful, sort by date desc
    if (filterBy == '') {
      setTableData(rows);
    }
    else
    {
    let filteredValues = filterBy
    ? rows.filter(r => (searchFilter === 'gene' ? r.gene : r.potentialCondition) === filterBy) //filter by selected searchFilter type if it matches input value
    : rows; //filter from the original array rows = props.data
    setTableData(filteredValues); 
    }
  }, [filterBy,rows]);

  const lastReviewedCell = (rowInfo) => {
        return (rowInfo.lastReviewed != null) ? convertUTCDateToLocalDate(rowInfo.lastReviewed) : "";
  }
   //#region Action Buttons & event
  const actionButton= (rowInfo) => {
      //Note: To test the action buttons,you need to comment the below if statement
       if (rowInfo.currentReviewVersion == 1 && rowInfo.reviewStatus != ReviewStatus.CLOSED){
        return "";
       }      
        switch(rowInfo.reviewStatus){
          case ReviewStatus.CLOSED:
            return <Button  onClick={()=>openModal(rowInfo,'create','Create New Review')} text="Create new review"/>;
          case ReviewStatus.DELETED:
            return <Button  onClick={()=>openModal(rowInfo,'reopen','Reopen Review')} text="Reopen review"/>;
          default:
            return <Button onClick={()=>openModal(rowInfo,'delete','Delete Review')} text="Delete review"/>
        }
       }
    ///Set Modal Properties 
  const  openModal = (rowInfo,action,title) => {
    const message = "Do you want to " + action + " review for variant " + rowInfo.variantName + "? " + 
                  ((action == "delete") ? "Doing this will remove all associated interpretations." : "");
    setIsOpen(true);
    setVariantReviewId(rowInfo.variantReviewId);
    setVariantName(rowInfo.variantName);
    setModalTitle(title);
    setModalMessage(message);
    setAction(action);

  }
  const onConfirmation=async()=>{    
         setIsOpen(false);
         let formData = new FormData();
         formData.append('variantReviewId', variantReviewId);
         let successMessage=[];

        switch(action){
          case "create": successMessage.push(await APIUtils.createReview(formData));break;
          case "delete": successMessage.push(await APIUtils.deleteReview(formData));break;
          case "reopen": successMessage.push(await APIUtils.reopenReview(formData));break;
        }   
        setIsSuccessModalOpen(true);
        setSuccessMessage(successMessage);
    }
  const onCancel = (e) => { setIsOpen(false); }
  const  onCloseSuccessModal=async()=> {
      setIsSuccessModalOpen(false);
      setIsLoading(true);
      //refresh data
      var variantResponse = await APIUtils.getVariantsByProgramId(sessionStorage.getItem(SessionConstants.PROGRAM_ID)); 
      setIsLoading(false);
      setRows(variantResponse);
  }
  //#endregion
  
  //#region Sorting 
  const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
  };
  //#endregion
  //#region on filter 
  const onFilterTypeChange = (event) => {
    //Search Filter Type Select
    setSearchFilter(event.target.value);
    setFilterBy(''); //clear any set filters on type change
    setTableData(rows); //reset filters to initial data when changing top level search filter type
  };
  //++++Filter By Gene or Condition+++
  const handleFilterChange = (event) => {  setFilterBy(event.target.value);   };
  const onClearFilters = () => {
    setSearchFilter('');
    setFilterBy('');
    setTableData(rows); //reset filters to initial data
  };
  //#endregion
  //#region Paging
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  //#endregion
  //#region create columns header array
  const columns = [
    {label: 'Variant', id: 'variantName',numeric: false, disablePadding: false},
    {label: 'Gene',id: 'gene',numeric: false, disablePadding: false},
    {label: 'Condition',id: 'potentialCondition',numeric: false, disablePadding: false},
    {label: 'Last Program Classification',id: 'programClassification',numeric: false, disablePadding: false},
    {label: 'Last Reviewed',id: 'lastReviewed',numeric: false, disablePadding: false},
    {label: 'Current Review Version',id: 'currentReviewVersion',numeric: false, disablePadding: false},
    {label: 'Interpretation Status',id: 'interpretationStatusString',numeric: false},
  ];

  const blankHeadCellsEnd = [
    {id: 'action', hiddenHeader:'Action'},
  ]
  //#endregion
  return (
    <div>
      <TableFilter 
        rows={props.data} 
        searchFilter={searchFilter}
        filterBy={filterBy}
        onClearFilters={onClearFilters}
        handleFilterChange={handleFilterChange}
        onFilterTypeChange={onFilterTypeChange}
      />
      <div className={classes.root}>
        <Paper className={classes.paper}>       
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="variant table">
              <MuiTableHeader 
                headCells={columns} 
                blankHeadCellsEnd={blankHeadCellsEnd}
                order={order}  
                orderBy={orderBy} 
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {stableSort(tableData, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return(
                    <StyledTableRow tabIndex={-1}  key={row.variantName}>
                      <StyledTableCell style={{width: '15%'}}>
                        <LinkLabel  
                          for={row.variantName}
                          path={'/variantdashboard/' + row.variantName}
                          text={row.variantName}
                          state={ {variantId: row.variantId,variantName: row.variantName,breadCrumbs: props.breadCrumbs,}}
                        />
                      </StyledTableCell>
                      <StyledTableCell>{row.gene}</StyledTableCell>
                      <StyledTableCell style={{width: '7%'}}>{row.potentialCondition}</StyledTableCell>
                      <StyledTableCell style={{width: '7%'}}>{row.programClassification}</StyledTableCell>
                      <StyledTableCell style={{width: '12.5%'}}>{lastReviewedCell(row)}</StyledTableCell>
                      <StyledTableCell >{row.currentReviewVersion}</StyledTableCell>
                      <StyledTableCell style={{width: '25%'}}>{row.interpretationStatusString}</StyledTableCell>
                      <StyledTableCell style={{width: '12.5%'}}>{actionButton(row)}</StyledTableCell>
                    </StyledTableRow>
                    );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <MuiTablePagination 
            rowsPerPage={rowsPerPage}
            page={page}
            count={tableData.length}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage} 
          />
        </Paper>
      </div>
              
      <ConfirmationModal 
        title={modalTitle}
        message={modalMessage}
        onCancel={onCancel}
        onConfirmation={onConfirmation}
        onClose={onCancel}
        isOpen={isOpen}
      />
      <SuccessMessageModal 
        modalIsOpen={isSuccessModalOpen}
        closeModal={onCloseSuccessModal}
        message={successMessage}
      />
      <Loading isLoading={isLoading}/>
          
    </div>
  );
}//end of ProgramVaiantsTable
    
