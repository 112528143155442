import React, {useState, useEffect} from 'react';
import {StyledTableRow,StyledTableCell} from '../../../../uiComponents/MuiTable/StyledTable.js'

import MuiTableHeader from '../../../../uiComponents/MuiTable/MuiTableHeader';

import { 
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  Paper
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    fontFamily: "Open Sans",
  },
  paper: {
    width: '100%',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function ConsensusLiteratureTable(props) {

  const classes = useStyles();
  const [tableData, setTableData] = useState(props.data);

  //intialize tableData
  useEffect(() => { 
    setTableData(props.data)
  }, [props.data] );

  const sortedTableData = tableData.sort((a,b) => {
    return a.reviewer.localeCompare(b.reviewer);
  })

  //Important: The id here must match the column name in the returned data. Label can be different for display.
  const headCells = [
    { label: 'Reviewer', id: 'reviewer', numeric: false, disablePadding: false },
    { label: 'PMID', id: 'pmid', numeric: false, disablePadding: false },
    { label: 'Literature Category', id: 'litCategory', numeric: false, disablePadding: false},
    { label: 'Evaluation', id: 'litEval', numeric: false, disablePadding: false },
    { label: 'Comments', id: 'litComment', numeric: false, disablePadding: false },
  ];
  
  return (
    <>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <TableContainer>
            <Table
              className={classes.table}
              size='small'
              aria-label="consensus literature table">
              <MuiTableHeader 
                noSortHeadCells={headCells} 
              />
              <TableBody>
                {sortedTableData
                  .map((row, index) => {
                  return (
                    <StyledTableRow tabIndex={-1} key={index}>
                      <StyledTableCell>{row.reviewer}</StyledTableCell>
                      <StyledTableCell>{row.pmid}</StyledTableCell>
                      <StyledTableCell>{row.litCategory}</StyledTableCell>
                      <StyledTableCell>{row.litEval}</StyledTableCell>
                      <StyledTableCell>{row.litComment}</StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </>
  );
}