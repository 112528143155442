import React, { Component } from 'react';
import Router from './components/Router';
import Modal from 'react-modal';
import { hot } from 'react-hot-loader';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowLeft,
  faBook,
  faChalkboardTeacher,
  faChevronUp,
  faChevronDown,
  faEdit,
  faFileUpload,
  faListAlt,
  faSpinner
} from '@fortawesome/free-solid-svg-icons';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-table/react-table.css';
import 'react-tabs/style/react-tabs.css';
import './react-table-override.css';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
Modal.setAppElement('#root');

//font awesome icons
library.add(
  faArrowLeft,
  faBook,
  faChalkboardTeacher,
  faChevronUp,
  faChevronDown,
  faEdit,
  faFileUpload,
  faListAlt,
  faSpinner
);

class App extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      errorMsg: '',
    };
  }

  componentDidMount() {
    console.log('---App.componentDidMount()');
  }

  render() {
    return (
      <div className="App">
        <ThemeProvider theme={theme}>
          <Router />
        </ThemeProvider>
      </div>
    );
  }
}
export default hot(module)(App);
