import React, { Component } from 'react';

import AnnotationData from './AnnotationData';
import AnnotationSectionsConfig from '../AnnotationSectionsConfig';

import './index.css';

export default class Sources extends Component {
  constructor() {
    super();
    this.state = {
      annoData: [],
      formValues: null,
      categoryValues: null,
      loadedAnnotations: false,
    };
  }

  componentDidMount() {
    // console.log('---Sources.componentDidMount()');
    var formData = this.props.formData;
    var sourceType = this.props.sourceType;
    var annoData = this.props.annoData;

    console.log('sourceType='+sourceType);

    this.setState({
      annoData: annoData,
      formData: formData,
      loadedAnnotations: true,
    });
  }

  //return an array of jsx for each option in the evaluation dropdown
  //for this source type
  renderEvaluationOptions = () => {
    var array = [];
    AnnotationSectionsConfig[this.props.sourceType].categories.map(category => {
      array.push(
        <option key={category.name} value={category.name}>
          {category.name}
        </option>
      );
    });
    return array;
  };

  onEvaluationChange = (event, source) => {
    this.props.onEvaluationChange(source, event.target.value);
  };

  onTextChange = (event, source) => {
    this.props.onTextChange(source, event.target.value);
  }

  //renders the evaluation dropdown and comments input section for given category
  renderEvalComments = category => {
    return (
      <div className="evaluation-comments">
        <div className="form-item">
          <label>
            <div>
              Evaluation
            </div>
            <select
              name="evaluation"
              value={
                this.props.categoryValues[category].eval
                  ? this.props.categoryValues[category].eval
                  : ''
              }
              onChange={e => {
                this.onEvaluationChange(e, category);
              }}
            >
              <option value="" />
              {this.renderEvaluationOptions()}
            </select>
          </label>
        </div>

        <div className="form-item">
          <label>
            <div>
              Comments
            </div>
            <textarea rows="6" cols=""
              name="comments"
              value={
                  this.props.categoryValues[category].comments
                  ? this.props.categoryValues[category].comments
                  : ''
              }
              onChange={e => {
                  this.onTextChange(e, category)}
              }
            />
          </label>
        </div>
      </div>
    );
  };

  renderAnnotationData = (category, categoryAnno, fieldDescription) => {
    return (
      <div className="annotation-data">
        <div className="form-item">
          <AnnotationData
            source={category}
            annotationData={categoryAnno}
            fieldDescription={fieldDescription}
            annotationSources={this.state.annoData.annotationSources}
          />
        </div>
      </div>
    );
  };

  //if the data field is undefined for 'SIFT', 'PolyPhen', and 'UCSC Conservation'
  //provide a messege to the user to use the specific tool
  renderNoDataMessage = source => {
    switch (source) {
      case 'SIFT':
        return (
          <div className="source-form-container">
            <div className="no-data-messege">
              Use the Sift tool to predict the effect of the variant
            </div>
            {this.renderEvalComments(source)}
          </div>
        );
      case 'PolyPhen':
        return (
          <div className="source-form-container">
            <div className="no-data-messege">
              Use the PolyPhen tool to predict the effect of the variant
            </div>
            {this.renderEvalComments(source)}
          </div>
        );
      case 'UCSC Conservation':
        return (
          <div className="source-form-container">
            <div className="no-data-messege">
              Use the UCSC Genome Browser to view evolutionary conservation
              information
            </div>
            {this.renderEvalComments(source)}
          </div>
        );
      default:
        return (
          <div className="source-form-container">
            There is no data available for this source
          </div>
        );
    }
  };

  renderCategoryData = (category, categoryAnno, fieldDescription) => {
    return (
      <div className="source-form-container">
        {this.renderAnnotationData(category, categoryAnno, fieldDescription)}
        {this.renderEvalComments(category)}
      </div>
    );
  };

  render() {
    return (
      <>
        {this.props.annoData.categories[this.props.categoryType].length == 0 ? 
          <div className="source-container">
            <div className="source-form-container">
              <div className="annotation-data">
                <div className="form-item">
                  There is no data available for this section
                </div>
              </div>
              {this.renderEvalComments(this.props.categoryType)}  
            </div>
          </div>
          :
          <div key={this.props.categoryType} className="source-container"> 
            {this.renderCategoryData(this.props.categoryType, this.props.annoData.categories[this.props.categoryType], this.props.annoData.fieldDescription)}
          </div>
        }
      </>
    );
  }
}
