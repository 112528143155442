import React, { Component } from 'react';
import ReactTable from 'react-table';
import { SortDate, convertUTCDateToLocalDate } from '../../../utils/DateUtils';

export default class VariantReviewHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      columns: [],
    };
  }

  componentDidMount() {
    this.getColumns(); //to fix column resize on window change
    window.addEventListener("resize", this.getColumns.bind(this)); 
    }

    dateField = (rowInfo) => {
        return convertUTCDateToLocalDate(rowInfo.original.itemDate);
    }

  getColumns = () => {
    this.setState({
      columns: [
        {
            Header: 'Date',
              accessor: 'itemDate',
              Cell: this.dateField,
            sortMethod:(a,b) =>{
              return SortDate(a,b)
            },
            width: Math.round(window.innerWidth * 0.1),
          },
          {
            Header: 'Event',
            accessor: 'itemDescription',
          },
      ]
    });
  }

  render() {
    return (
      <div key={this.props.childKey}>
        <ReactTable
          data={this.props.data} 
          showPagination={false}
          minRows={1}
          defaultSorted={[
            {
              id: 'itemDate',
              desc: true,
            },
          ]}
          columns={this.state.columns}
          //Make table headers focusable and add keypress for sorting for 508 accessibility
          getTheadThProps={(state, rowInfo, column, instance) => {
            return {
              tabIndex: 0,
              onKeyPress: (e, handleOriginal) => {
                if(e.which === 13 || e.which === 32) {
                  instance.sortColumn(column);
                }
              }
            };
          }}
        />
      </div>
    );
  }
}
