const DataStatusConfig = {
  PENDING_VCF_INGESTION: "Pending VCF Ingestion",
  INGESTING_VCF: "Ingesting VCF",
  VCF_INGESTION_COMPLETED: "VCF Ingestion Completed",
  VCF_INGESTION_FAILED: "VCF Ingestion Failed",
  PROCESSING_FAILED: "Processing Failed",
  PENDING_UPLOAD: "Pending Upload",
  UPLOAD_COMPLETED: "Upload Completed",
  UPLOAD_FAILED: "Upload Failed"
};

export default DataStatusConfig;