import React, {Component} from 'react'
import SessionConstants from '../../config/SessionConstants';
import Link from 'react-router-dom/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faArrowRight, faEnvelope, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import Paths from '../../config/Paths';

import './index.css';

export default class Error extends Component {

    state = {
        errorMessage: null, 
        errorDescription: null,
        timeout: false,
    };

    async componentDidMount() {
        console.log('loading error message descriptions');
        let errMsg = sessionStorage.getItem(SessionConstants.ERROR_MESSAGE);
        let errDesc = sessionStorage.getItem(SessionConstants.ERROR_DESCRIPTION);
        let timeoutStatus = sessionStorage.getItem(SessionConstants.TIMEOUT_STATUS);

        this.setState({
            errorMessage: errMsg, 
            errorDescription: errDesc,
            timeout: timeoutStatus,
        });
    }

    render() {
        return (
            <div className='errorContainer' role='main'>
                <div className='errorBox'>
                    <div className='errorTitle'>
                        {this.state.timeout ? (
                            <h1><FontAwesomeIcon icon={faExclamationCircle}></FontAwesomeIcon> Logout</h1>
                        ) : (
                            <h1><FontAwesomeIcon icon={faExclamationTriangle}></FontAwesomeIcon> Error</h1>
                        )}
                    </div>
                    <div className='errorBody'>
                        <div className='errorMessage'>
                            {this.state.errorMessage ? (<span>{this.state.errorMessage}</span>) : ('')}
                            {(this.state.errorMessage && this.state.errorDescription) ? (' - ') : ('')}
                            {this.state.errorDescription ? (<span>{this.state.errorDescription}</span>) : ('')}
                        </div>
                        <div className='errorLoginMessage'>
                            <div className='loginWrapper'>
                                <div>
                                    Please login to ED3N again:
                                </div>
                                <div className='linkWrapper'>
                                    <Link className={'loginLink'} to={{ pathname: Paths.samsAuth }} role={'link'} aria-label={'login'}><FontAwesomeIcon icon={faArrowRight} className='errorButtonIcons'></FontAwesomeIcon>Login to ED3N</Link>
                                </div>
                            </div>
                            <div className='emailWrapper'>
                                <div>
                                    If you continue to experience problems logging in, please email CDC ED3N support:
                                </div>
                                <div className='linkWrapper'>
                                    {this.state.errorDescription == null ? (
                                            <a className='emailLink' role='link' aria-label='send email' href="mailto:cdced3nsupport@cdc.gov?Subject=ED3N%20Login%20Problem"><FontAwesomeIcon icon={faEnvelope} className='errorButtonIcons'></FontAwesomeIcon>CDC ED3N support</a>
                                    ) : (
                                            <a className='emailLink' role='link' aria-label='send email' href={"mailto:cdced3nsupport@cdc.gov?Subject=ED3N%20Login%20Problem%20-%20" + this.state.errorDescription}><FontAwesomeIcon icon={faEnvelope} className='errorButtonIcons'></FontAwesomeIcon>ED3N support</a>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}