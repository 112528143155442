import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import {Helmet} from "react-helmet";

import BreadCrumbs from '../BreadCrumbs';
import ConsensusSummary from './ConsensusSummary';
import ReviewStatus from '../../config/ReviewStatus.js';
import Oauth from '../../utils/Oauth.js';
import SessionConstants from '../../config/SessionConstants';

import '../../index.css';

export default class VariantConsensus extends Component {
  constructor() {
    super();
    this.state = {
      breadCrumbs: [],
      programId: null,
      caseId: null,
      variantId: null,
      dbSnpId: null,
      reviewStatus: null,
      // reviewComplete: true, //prevent content/menu flash
      summary: {},
      modalIsOpen: false,
      // classification: null,
      // comments: null,
    };
  }

  async componentDidMount() {
    console.log('---VariantConsensus.componentDidMount()')

    const {
        match: { params },
    } = this.props;

    var programId = sessionStorage.getItem(SessionConstants.PROGRAM_ID);
    var reviewStatus = this.props.location.state.reviewStatus;
    var caseId = this.props.match.params.caseId;
    var variantId = this.props.match.params.variantId;
    var breadCrumbs = this.props.location.state.breadCrumbs;
    breadCrumbs = [...breadCrumbs, { name: 'VariantConsensus' }];

    this.setState({
        caseId: caseId,
        variantId: variantId,
        programId: programId,
        reviewStatus: reviewStatus,
        breadCrumbs: breadCrumbs,
    });
    //prevent final consensus items from flashing during page load
    // this.state.reviewStatus ? '' : this.setState({reviewComplete: false});
    
    this.getSummary();
  }

  //get the summary data for the given variant
  getSummary = () => {
    console.log('---VariantConsensus.getSummary()')
    var variantName = this.props.location.state.variantName;
    var gene = this.props.location.state.gene;
    var potentialCondition = this.props.location.state.potentialCondition;

    var summary = {};
    summary.variantName = variantName;
    summary.gene = gene;
    summary.potentialCondition = potentialCondition;

    this.setState({ summary: summary });
  };

  openModal = () => {
    this.setState({ modalIsOpen: true });
  };

//   closeModal = () => {
//     this.setState({
//         modalIsOpen: false
//     });
//   };

//   handleOptionChange = changeEvent => {
//     this.setState({
//         classification: changeEvent.target.value,
//     });
//   };

//  //TODO: update publish function
//   publish = () => {
//     //console.log('VariantConsensus.publish()');

//     window.onbeforeunload = null;

//     this.props.history.push({
//       pathname: '/programsummary',
//       state: {
//         programId: this.state.programId,
//         needsRefresh: true,
//       }
//     });
// };

  render() {
    var variantName = this.state.summary != undefined ? this.state.summary.variantName : '';
    return (
      <div className="variantDetails">
        <Helmet>
          <title>ED3N - Variant Consensus</title>
        </Helmet>
        <BreadCrumbs breadCrumbs={this.state.breadCrumbs} />
        <h1 className="pageTitle">Variant Name: {variantName}
        {this.state.reviewStatus === ReviewStatus.CLOSED ? (
           <>
              <span className="pageSubTitle">
                <strong>Published</strong>
              </span>
           </>
          ) : (
            ''
        )}
        </h1>
        <section>
          <div className="section">
            <section>
              <h2 className="sectionHeading">Summary</h2>
              <div className="sectionContent">
                {this.state.summary != undefined ? (
                  <div className="consensusSection">
                    <div>Gene: {this.state.summary.gene}</div>
                    <div>
                      Potential Condition: {this.state.summary.potentialCondition}
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </section>
            <ConsensusSummary
              programId={this.state.programId}
              variantId={this.state.variantId}
              reviewStatus={this.state.reviewStatus}
              history={this.props.history}
            />
          </div>
        </section>
      </div>
    );
  }
}