import React from "react";

import Modal from 'react-modal';
const SessionTimeoutModal = ({  open, countdown, onLogout, onContinue }) => {
  // Prepend `0` for one digit numbers. For that the number has to be
// converted to string, as numbers don't have length method
const padTime = time => {
  return String(time).length === 1 ? `0${time}` : `${time}`;
};

const format = time => {
  // Convert seconds into minutes and take the whole part
  const minutes = Math.floor(time / 60);
  // Get the seconds left after converting minutes
  const seconds = time % 60;
  //Return combined values as string in format mm:ss
  return `${minutes}:${padTime(seconds)}`;
};

return (

<Modal
    className="modal sessionTimeoutModal"
    overlayClassName="modalOverlay"
    aria-modal="true"
    contentLabel="Session Timeout"
    shouldCloseOnOverlayClick={false}
    isOpen={open}>
    <div className="modalHeader">Session Timeout Reminder</div>
      <div className="modalContent">
        The current session is about to expire in{" "}
        <span className="countdown">{format(countdown)}</span> seconds.
        <p>Would you like to continue the session?</p>
      </div>
    <div className="modalButtons">
        <button onClick={onLogout} className="modalButton defaultButton">
                Logout
        </button>
        <button onClick={onContinue} className="modalButton primaryButton">
                Continue Session
        </button>
    </div>
</Modal>
);
}
export default SessionTimeoutModal;