import React, { Component } from 'react';
import Modal from 'react-modal';
import { Prompt } from 'react-router';
import {Helmet} from "react-helmet";
import BreadCrumbs from '../BreadCrumbs';
import Literature from './Literature';
import FinalReview from './FinalReview';
import RelevantCriteriaCategories from './RelevantCriteriaCategories';
import ConsensusReviews from '../ConsensusReviews';
import CalculatedClassification from './CalculatedClassification';
import Sources from './Sources';
import classificationLogic from './classificationLogic.js';
import ACMGTable from './ACMGTable';
import SessionConstants from '../../config/SessionConstants.js';
import ClassificationWarning from './ClassificationWarning';
import Loading from '../Loading';
import APIUtils from '../../utils/APIUtils';
import AnnotationNamesConfig from './AnnotationNamesConfig';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import StepperInterpretation from './StepperInterpretation/StepperInterpretation';
import WarningIcon from '@material-ui/icons/Warning';
import './index.css';

export default class AddInterpretations extends Component {
  
  constructor() {
    super();
    //initiliaze the ref
    this.nextRef = React.createRef(),
    this.backRef = React.createRef(),
    this.state = {
      breadCrumbs: [],
      variantId: null,
      programId: null,
      annoData: [],
      modalSubmitIsOpen: false,
      modalCancelIsOpen: false,
      modalValidationIsOpen: false,
      validationRequiredFields: '',
      evaluationList: [],
      condition: null,
      calcClassification: 'None',
      programClassification: null,
      comments: '',
      summary: {},
      summaryExists: false,
      savedInterpretation: false,
      savedDetails: false,
      loadedAnnotations: false,
      loadedInterpretation: false,
      toVariantDetails: false,
      loading: false,
      literatureEvaluationList:[],
      //index var holder
      activeStepIndex: 0,
      categoryValues: {
        DISEASE_DATABASES: {},
        POPULATION_FREQUENCY: {},
        COMPUTATIONAL_PREDICTIVE_PROGRAMS: {},
        NUCLEOTIDE_CONSERVATION: {},
      },
      formIsIncomplete: null,
      errorMsg: '',
      literatures: [],
    };
  }

  async componentDidMount() {
    console.log('---AddInterpretation.componentDidMount()');

    const {
        match: { params },
    } = this.props;

    let condition = this.props.location.state.potentialCondition;
    let breadCrumbs = this.props.location.state.breadCrumbs;
    breadCrumbs = [...breadCrumbs, { name: 'AddInterpretation' }]

    let programId = sessionStorage.getItem(SessionConstants.PROGRAM_ID);
    let variantId = this.props.location.state.variantId;
    let variantName = this.props.location.state.variantName;
    console.log('condition='+condition);
    console.log('programId='+programId);
    console.log('comp mount variantId='+variantId);
    console.log('variantName='+variantName);
    this.setState({
        condition: condition,
        programId: programId,
        variantId: variantId,
        variantName: variantName,
        breadCrumbs: breadCrumbs,
        formIsIncomplete: true,
    });
    this.loadAnnotations(variantId);
    this.loadInterpretation(programId, variantId);
  }

  async loadAnnotations(variantId) {
    console.log('---AddInterpretation.getSources()');
    var annoData = null;

    var response = await APIUtils.getAnnotationsForVariant(variantId);

    if (response) {
      annoData = response;
      console.log('Loaded ' + Object.keys(annoData).length + ' (number of) annotations');
      // console.log("-------Annotations JSON: " + JSON.stringify(response));
      const summary = {
        gene: response.gene,
        condition: response.condition,
        //Need to figure out condition in response for summary 
      };
    
      this.setState({
        summary: summary,
        summaryExists: true,
        annoData: annoData,
        loadedAnnotations: true,
        loading: false,
      });
    } else {
      //if errors exist then display the error message
      this.setState({
          errorMsg: 'Error loading annotation data!',
          loadedAnnotations: false,
          loading: false,
      });
      console.error('There was an error loading the annotations.'); //generic console error message
    }
  }

  openCancelModal = () => {
    this.setState({ modalCancelIsOpen: true });
  }

  openSubmitModal = () => {
    const { programClassification, comments } = this.state;
  
    if (programClassification && comments) {
      // Both required fields are not empty or null, open submit modal
      this.setState({ modalSubmitIsOpen: true });
    } else {
      // Determine which of the required interpretation fields are invalid and open validation modal
      let validationRequiredFields = [];
      
      if (!programClassification) {
        validationRequiredFields.push("Classification");
      }
      if (!comments) {
        validationRequiredFields.push("Comments");
      }
  
      this.setState({ 
        validationRequiredFields: validationRequiredFields.join(", "),
        modalValidationIsOpen: true 
      });
    }
  }

  closeModal = () => {
    this.setState({
      modalCancelIsOpen: false,
      modalSubmitIsOpen: false,
      modalValidationIsOpen: false,
    });
  }

  onDiscard = () => {
    console.log('---AddInterpretation.onDiscard()');
    window.onbeforeunload = null;
    this.closeModal();
    //history.push('/caseload' + '/casedetails/' + this.state.caseId + '/variantdetails/' + this.state.variantId);

    let caseId = this.state.caseId;
    let programId = sessionStorage.getItem(SessionConstants.PROGRAM_ID)
    let variantId = this.props.location.state.variantId;
    let variantName = this.props.location.state.variantName;

    console.log('programId='+programId);
    console.log('caseId='+caseId);
    console.log('variantId='+variantId);

    this.props.history.push({
      pathname: '/variantdetails/' + variantId,
      state: {
        programId: programId,
        variantId: variantId,
        variantName: variantName,
        justLoadedInterpretation: true,
        breadCrumbs: this.props.location.state.breadCrumbs,
      }
    });
  }

  save = () => {
    console.log('---AddInterpretation.save()');
    // prevent incomplete prompt message from showing
    this.setState({formIsIncomplete: false});
    this.saveInterpretation(true);
  }

  submit = () => {
    console.log('---AddInterpretation.submit()');
    // prevent incomplete prompt message from showing
    this.setState({formIsIncomplete: false});
    this.saveInterpretation(false);
  }

  async saveInterpretation(partialStatus) {
    console.log('----AddInterpretation.saveInterpretation()');
    console.log('++++partialStatus after='+partialStatus);

    let programId = sessionStorage.getItem(SessionConstants.PROGRAM_ID)
    let variantId = this.state.variantId;
    let calcClassification = this.state.calcClassification;
    let programClassification = this.state.programClassification;
    let comments = this.state.comments;
    let interpretedDate = this.getCurrentDate();

    console.log('calcClassification='+calcClassification);
    console.log('programClassification='+programClassification);
    console.log('interpretedDate='+interpretedDate);

    let prevLoaded = this.state.loadedInterpretation;
    console.log('prevLoaded='+prevLoaded);

    var interpretationJson = {
      'variantReviewId': -1,
      'userId': "",
      'calcClassification': calcClassification,
      'programClassification': programClassification,
      'comment': comments,
      'partial': partialStatus,
      'review': false,
      'interpretationDetails': [],
    };
    const categoryValuesObject = { ...this.state.categoryValues };
    for (const key of Object.keys(categoryValuesObject)) {
      var evalList = {};
      evalList['AnnotationCategory'] = key;
      evalList['AcmgCriteria'] = categoryValuesObject[key].eval;
      evalList['Comment'] = categoryValuesObject[key].comments;
      interpretationJson['interpretationDetails'].push(evalList);
    }

    var response;
    //if (!partialStatus && !this.state.loadedInterpretation)
    //    response = await APIUtils.addVariantInterpretation(programId, variantId, interpretationJson);
    //else
    response = await APIUtils.updateVariantInterpretation(programId, variantId, interpretationJson);

    if (response) {
      //if success response then close the modal and return the caseload page
      //this.onClose();
      console.log('about to send back to /variantDetails');
      console.log('either fix refresh, or just send back to homepage');
      this.setState({
          savedInterpretation: true,
      })
    } else {
      //if errors exist then display the error message
      console.error('err='+err);
      this.setState({
          errorMsg: 'There was an error uploading the interpretation.',
      });
    }
  }

  getCurrentDate = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();
    today = mm + '/' + dd + '/' + yyyy;
    return today;
  }

  async loadInterpretation(programId, variantId) {
    console.log('----AddInterpretation.loadInterpretation()')

    var response = await APIUtils.getPartialVariantInterpretation(programId, variantId);
    if (response) {
      console.log('we have data!');
      const evaluationList = [];
      const categoryValuesBuilder = { ...this.state.categoryValues};

      if (Array.isArray(response.interpretationDetails) && response.interpretationDetails.length > 0) {
        response.interpretationDetails.forEach(currentEval => {
          if ((currentEval.AcmgCriteria != undefined) && (currentEval.AcmgCriteria != null)) {
            evaluationList.push(currentEval.AcmgCriteria);
            categoryValuesBuilder[currentEval.AnnotationCategory].eval = currentEval.AcmgCriteria;
            categoryValuesBuilder[currentEval.AnnotationCategory].comments = currentEval.Comment;
          }
        });
      }
      
      let programClassification = response.programClassification;
      let calcClassification = response.calcClassification;
      let comment = response.comment;

      this.setState({
        programClassification: programClassification,
        calcClassification: calcClassification,
        comments: comment,
        categoryValues: categoryValuesBuilder,
        loadedInterpretation: true,
        evaluationList: evaluationList,
      });
    } else {
      this.setState({
        errorMsg: 'There was an error retrieving the interpretation.',
        loadedInterpretation: false,
      });
    }
  }

  getMyDate = () => {
    let date = new Date();
    let dateBuilder = date.getFullYear() + '-' + (date.getMonth()+1) + '-' + date.getDate() +' '+ date.getHours()+':'+ date.getMinutes()+':'+ date.getSeconds();
    console.log('dateBuilder='+dateBuilder);
    //return dateBuilder

    //03/03/2020
    let month = date.getMonth();
    if (month.length == 1) {
      let newMonth = 0 + month;
      month = newMonth;
    }

    date = date.getDate();
    if (date.length == 1) {
      let newDate = 0 + date;
      date = newDate;
    }

    let year = date.getFullYear();

    let dateBuider3 = month + '/' + date + '/' + year;
    console.log('dateBuider3='+dateBuider3);
    return dateBuider3;
  }

  onEvaluationChange = (source, evaluation) => {
    console.log('AddInterpretation.onEvaluationChange()')
    const categoryValueObj = { ...this.state.categoryValues};

    if (!categoryValueObj[source].eval) {
      console.log('was empty, adding new key')
      categoryValueObj[source].eval = '';
    }

    const prevEvaluation = categoryValueObj[source].eval;
    console.log(`prev log ${prevEvaluation}`)
    categoryValueObj[source].eval = evaluation;

    this.setState({
      categoryValues: categoryValueObj,
    });

    this.updateEvaluationsAndClassification(prevEvaluation, evaluation);
  }

  onLiteratureEvaluationChange =(literatureEvaluations)=>{
    this.setState({
      literatureEvaluationList: literatureEvaluations
    });
  }

  onClassificationChange = (source) => {
    console.log('AddInterpretation.onClassificationChange()');
    console.log('source='+source);

    this.setState({
      programClassification: source.currentTarget.value
    });
  }

  onTextChange = (source, newText) => {
    console.log('source='+source);
    console.log('newText='+newText);

    const categoryValuesObj = { ...this.state.categoryValues };

    if (!categoryValuesObj[source].comments) {
      console.log('was empty, adding new key')
      categoryValuesObj[source].comments = '';
    }

    categoryValuesObj[source].comments = newText;

    this.setState({
      categoryValues: categoryValuesObj,
    });
  }

  //given previous evaluation value and the current evaluation value, update the
  //evaluation list with all the evaluations selected on the page currently and
  //calculate the classification value
  updateEvaluationsAndClassification = (prevEvaluation, evaluation) => {
    let evaluationList = [...this.state.evaluationList];
    if (prevEvaluation != '') {
      if (evaluation === '') {
      //case: deselect an evaluation
      evaluationList.splice(evaluationList.indexOf(prevEvaluation), 1);
      } else {
      //case: different evaluation picked for the same source
      evaluationList[evaluationList.indexOf(prevEvaluation)] = evaluation;
      }
    } else {
      //case: picking eval for the first time in the given source
      evaluationList.push(evaluation);
    }
    let classification = classificationLogic(evaluationList);
    this.setState({
      evaluationList: evaluationList,
      calcClassification: classification,
      programClassification: classification
    });
    console.log("EVALUATION LIST: " + evaluationList);
  }

  handleOptionChange = changeEvent => {
    console.log('---AddInterpretation.handleOptionChange()');
    console.log('classification='+changeEvent.target.value)

    this.setState({
      programClassification: changeEvent.target.value,
    });
  }

  loadAnnotationSection(source, sourceString) { //source is annotation section enum
    return(
      <div className="section">
      <h2 className="sectionHeading" tabIndex={-1}>{sourceString}</h2>
      <div className="sectionContent">
        <RelevantCriteriaCategories sourceType={sourceString} />
        {this.state.loadedAnnotations ? (
          <Sources
            sourceType={sourceString}
            categoryType={source}
            categoryValues={this.state.categoryValues}
            annoData={this.state.annoData}
            onEvaluationChange={this.onEvaluationChange}
            onTextChange={this.onTextChange}
          />) : (
          <div className='errorMsg'>
            <ErrorOutlineIcon fontSize='small'></ErrorOutlineIcon> {this.state.errorMsg}
          </div>
        )}
      </div>
      </div>
    );
  }

  //functions to track and change activestep state in the parent
  nextIndex (){
    if(this.state.activeStepIndex < 7){
      this.setState({
        activeStepIndex: this.state.activeStepIndex+1
      })
    }    
  }

  backIndex (){
    if(this.state.activeStepIndex != 0){
        this.setState({
          activeStepIndex:  this.state.activeStepIndex-1
        })
    }
  }

  render() {
    console.log('---AddInterpretation.render()')

    const summaryExists = this.state.summaryExists;
    const gene = this.props.location.state.gene;
    const variantId = this.props.location.state.variantId;
    const variantName = this.props.location.state.variantName;
    const reviewStatus = this.props.location.state.reviewStatus;
    const programId = sessionStorage.getItem(SessionConstants.PROGRAM_ID);
    const variantInterpretationId = this.props.location.state.variantInterpretationId;

    // console.log('variantId='+variantId);
    // console.log('programId='+programId);
    // console.log('variantInterpretationId='+variantInterpretationId);

    const BENIGN = "Benign";
    const LIKELY_BENIGN = "Likely Benign";
    const UNCERTAIN_SIGNIFICANCE = "Uncertain Significance";
    const LIKELY_PATHOGENIC = "Likely Pathogenic";
    const PATHOGENIC = "Pathogenic";

    //  catch if we hit Save
    if (this.state.savedInterpretation) {
      console.log('about to update toVariantDetails');
      window.onbeforeunload = () => null;
      //this.onClose();
      this.setState({
        toVariantDetails: true,
      })
    }
    if (this.state.toVariantDetails) {
      console.log('about to send back');
      this.props.history.push({
        pathname: '/variantdetails/' + variantId,
        state: {
          variantId: variantId,
          variantName: variantName,
          breadCrumbs: this.props.location.state.breadCrumbs,
          programId: programId,
          justAddedInterpretation: true,
          reviewStatus: reviewStatus,
        }
      });
    }

    let annoSections = [];
    Object.entries(AnnotationNamesConfig).forEach(([annotation, annotationString]) => {
      annoSections.push(this.loadAnnotationSection(annotation, annotationString));
    })

    return (
      <>
        <Loading isLoading={this.state.loading}/>
        <Helmet>
          <title>ED3N - Add Interpretation</title>
        </Helmet>
        {/* Use formIsIncomplete to control unsaved changes prompt display */}
        <Prompt when={this.state.formIsIncomplete} message="Any unsaved changes will be lost. Are you sure you want to leave?" /> 
        <BreadCrumbs breadCrumbs={this.state.breadCrumbs} />
        <h1 className="pageTitle">
          Add Interpretation: {this.state.variantName}
        </h1>
        {/* Add Stepper Component */} 
        <StepperInterpretation activeStepIndex={this.state.activeStepIndex}
          ref={{
            ref1: this.backRef,
            ref2: this.nextRef
          }}
        />
        <div className="interpretation-container">
          {
            (this.state.activeStepIndex == 0) ? 
              /*Population Frequency*/
              <>
                {annoSections[0]}
              </>
                :
            (this.state.activeStepIndex == 1) ? 
              /*Disease Databases*/
              <>
                {annoSections[1]}
              </>
                :   
            (this.state.activeStepIndex == 2) ? 
            /*Computational Predictive Programs*/
            <>
              {annoSections[2]}
            </>
              :  
            (this.state.activeStepIndex == 3) ? 
            /*Nucleotide Conservation */
            <>
              {annoSections[3]}
            </>
              :
            (this.state.activeStepIndex == 4) ?   
            <>
              {/*Literature */}
              <div className="section">
                <h2 className="sectionHeading" tabIndex={-1}>Literature</h2>
                <div className="sectionContent">
                  <Literature
                    sourceType="Literature"
                    categoryValues={this.state.categoryValues}
                    gene={gene}
                    variantName={variantName}
                    variantId={variantId}
                    variantInterpretationId={variantInterpretationId}
                    onLiteratureEvaluationChange={this.onLiteratureEvaluationChange}
                  />
                </div>
              </div>               
            </>
                :
            (this.state.activeStepIndex == 5) ?
            <>
              {/*Interpretation */}
              <div className="section">
                <h2 className="sectionHeading" tabIndex={-1}>Interpretation</h2>
                <div className="sectionWrapper">
                  <div className="sectionBox sectionLeft">
                    {/*ACMG */ }
                    {/* {console.log("+-+-+ EVAL LIST: " + this.state.evaluationList.length)} */}
                    <h3 className="">Selected ACMG Criteria</h3>
                    <div className="">
                      <ACMGTable evaluationList={this.state.evaluationList} literatureEvaluationList={this.state.literatureEvaluationList}/>
                    </div>
                    {/*Summary Consensus */}
                    <h3 className="">Summary of Previous Consensus Reviews</h3>
                    <div className="">
                      <ConsensusReviews variantId={this.state.variantId} />
                    </div>
                  </div>
                  <div className="sectionRight">
                    <div className="">
                      <div className="classification-form-item">
                        <div>
                          <div className="summary-fields">
                            Gene: {summaryExists ? <b>{this.state.summary.gene}</b> : 'loading'}
                          </div>
                          <div className="summary-fields">
                            Potential Condition: {summaryExists ? <b>{this.state.summary.condition}</b> : 'loading'}
                          </div>
                        </div>
                      </div>
                      <div className="classification-form-item">
                          <span className="requiredIndicator">*</span> Indicates a required field.
                      </div>
                      <div className="classification-form-item">
                        <div className="form-item">
                          <form>
                            <fieldset>
                              <legend>
                                Classification <span className="requiredIndicator">*</span>
                              </legend>
                              <div onChange={this.handleOptionChange}>
                                <input
                                  type="radio"
                                  value={BENIGN}
                                  id={BENIGN}
                                  name="classification"
                                  checked={this.state.programClassification == BENIGN}
                                  onChange={this.handleOptionChange}
                                />
                                <label htmlFor={BENIGN}>{BENIGN}</label><br />
                                <input
                                  type="radio"
                                  value={LIKELY_BENIGN}
                                  id={LIKELY_BENIGN}
                                  name="classification"
                                  checked={this.state.programClassification == LIKELY_BENIGN}
                                  onChange={this.handleOptionChange}
                                />
                                <label htmlFor={LIKELY_BENIGN}>{LIKELY_BENIGN}</label><br />
                                <input
                                  type="radio"
                                  value={UNCERTAIN_SIGNIFICANCE}
                                  id={UNCERTAIN_SIGNIFICANCE}
                                  name="classification"
                                  checked={this.state.programClassification == UNCERTAIN_SIGNIFICANCE}
                                  onChange={this.handleOptionChange}
                                />
                                <label htmlFor={UNCERTAIN_SIGNIFICANCE}>{UNCERTAIN_SIGNIFICANCE}</label><br />
                                <input
                                  type="radio"
                                  value={LIKELY_PATHOGENIC}
                                  id={LIKELY_PATHOGENIC}
                                  name="classification"
                                  checked={this.state.programClassification == LIKELY_PATHOGENIC}
                                  onChange={this.handleOptionChange}
                                />
                                <label htmlFor={LIKELY_PATHOGENIC}>{LIKELY_PATHOGENIC}</label><br />
                                <input
                                  type="radio"
                                  value={PATHOGENIC}
                                  id={PATHOGENIC}
                                  name="classification"
                                  checked={this.state.programClassification == PATHOGENIC}
                                  onChange={this.handleOptionChange}
                                />
                                <label htmlFor={PATHOGENIC}>{PATHOGENIC}</label><br />
                              </div>
                            </fieldset>
                          </form>
                        </div>
                      </div>
                      <div className="comments-form-item">
                        <div className="form-item">
                          <label htmlFor="interpretation-comments">Comments <span className="requiredIndicator">*</span></label>
                          <textarea
                            rows="6"
                            name="comment"
                            id="interpretation-comments"
                            ref="myTextArea"
                            value = {this.state.comments}
                            onChange = { (event) => {
                                this.setState({
                                    comments:event.target.value,
                                })
                            }}
                          />
                        </div>
                      </div>
      
                    </div>
                  </div>
                </div>
              </div>
            </>
                :
            (this.state.activeStepIndex == 6) ?
            <>
              {/*Final Review */}
              <div className="section">
                <h2 className="sectionHeading" tabIndex={-1}>Final Review</h2>
                <div className="sectionContent">
                    <FinalReview
                    //passing categoryValues object
                    categoryValues={this.state.categoryValues}
                    programClassification={this.state.programClassification}
                    comments={this.state.comments}
                    />
                </div>
              </div>
            </>
                :                  
            <></>
          }
        </div>
        {/*Stepper Buttons */}
        <div className="interpretationButtons">
          <button className="primaryButton" 
            disabled={this.state.activeStepIndex === 0}
            onClick={() => {
              this.backRef.current.handleBack();
              this.backIndex();
            }}
            onKeyDown={(event) => {
              const targetElement = document.querySelector('.sectionHeading');
              if (event.key === 'Enter') {
                event.preventDefault();
                this.backRef.current.handleBack();
                this.backIndex();
                if (targetElement) {
                  targetElement.focus();
                }
              } 
            }}
            >
            Prev
          </button>
          <button className="primaryButton" 
            onClick={this.openCancelModal}
            >
            Cancel
          </button>
          <CalculatedClassification calculatedClassification={this.state.calcClassification} />
          <button className="primaryButton"
            disabled={(this.state.activeStepIndex === 6)}
            onClick={this.save} 
            >
            Save for Later
          </button>
          {this.state.activeStepIndex != 6 ?
            <button className="primaryButton"
              disabled={((this.state.activeStepIndex === 6) && ((this.state.programClassification == null) || (this.state.comments == "")))} 
              onClick={() => {
                this.nextRef.current.handleNext();
                this.nextIndex();
              }}
              onKeyDown={(event) => {
                const targetElement = document.querySelector('.sectionHeading');
                if (event.key === 'Enter') {
                  event.preventDefault();
                  this.nextRef.current.handleNext();
                  this.nextIndex();
                  if (targetElement) {
                    targetElement.focus();
                  } 
                } 
              }}
              >
              Next
            </button>
            :<> </>
          }
          {(this.state.activeStepIndex === 6) ? //final step, submit
            <button className="primaryButton"
              onClick={this.openSubmitModal}
              >
              Submit
            </button>
            :<> </>
          }
        </div>
        <Modal
          className="modal"
          overlayClassName="modalOverlay"
          aria-modal="true"
          contentLabel="Form Incomplete"
          shouldCloseOnOverlayClick={false}
          isOpen={this.state.modalValidationIsOpen}
          onRequestClose={this.closeModal}
        >
          <div className="modalContent flexContent">
            <span className="requiredIndicator">
              <WarningIcon fontSize='large' className='validationWarning' />
            </span>
            <div className="textContent">
              Required fields missing: <strong>{this.state.validationRequiredFields}</strong>. 
              Go back to Step 6, "Interpretation", and complete all required fields.
            </div>
          </div>
          <div className="modalButtons">
            <button
              className="modalButton primaryButton"
              onClick={this.closeModal}
            >
              OK
            </button>
          </div>
        </Modal>
        <Modal
          className="modal"
          overlayClassName="modalOverlay"
          aria-modal="true"
          contentLabel="Confirm Discard"
          shouldCloseOnOverlayClick={false}
          isOpen={this.state.modalCancelIsOpen}
          onRequestClose={this.closeModal}
        >
          <div className="modalContent">
            Are you sure you want to discard this form and return to the variant
            details page?
          </div>
          <div className="modalButtons">
            <button className="modalButton" onClick={this.onDiscard}>
              Yes, Discard
            </button>
            <button
              className="modalButton primaryButton"
              onClick={this.closeModal}
            >
              Cancel
            </button>
          </div>
        </Modal>
        <Modal
          className="modal"
          overlayClassName="modalOverlay"
          aria-modal="true"
          contentLabel="Confirm Submit"
          shouldCloseOnOverlayClick={false}
          isOpen={this.state.modalSubmitIsOpen}
          onRequestClose={this.closeModal}
        >
          <div className="modalContent center">
          <ClassificationWarning 
               calcClassification={this.state.calcClassification}
               classification={this.state.programClassification}/>
            Are you sure you want to submit this interpretation?
            <br/>
            <br/>
            <strong>Note:</strong> Hitting submit will notify your team that this variant interpretation cannot be changed and no other edits can be made.
          </div>
          <div className="modalButtons">
            <button className="modalButton primaryButton" onClick={this.submit}>
              Yes, Submit
            </button>
            <button
              className="modalButton"
              onClick={this.closeModal}
            >
              Cancel
            </button>
          </div>
        </Modal>
      </>
    );
  }
}


