import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 992,
      lg: 1200,
      xl: 1400,
    },
  },
  palette: {
    primary: {
      //light: '#4795a7',
      main: '#006778',
      //dark: '#003c4c',
      //contrastText: '#fff',
    },
    secondary: {
      //light: '#f37a35',
      main: '#ffad42',
      //dark: '#831b00',
      //contrastText: '#000',
    },
  },
  typography: {
    "fontFamily": `"Open Sans",apple-system,blinkmacsystemfont,"Segoe UI","Helvetica Neue",arial,sans-serif`,
    // "fontSize": 14,
    // "fontWeightLight": 300,
    // "fontWeightRegular": 400,
    // "fontWeightMedium": 500
  },
  props: {
    MuiButtonBase: {
      disableRipple: true, 
    },
    MuiButton: {
      disableElevation: true
    }
  },

})

export default theme;
