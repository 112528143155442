import React, {useState} from 'react';
import SessionConstants from '../../config/SessionConstants';
import './index.css';

export default function FooterBanner() {
    //check session for fullscreen setting
    const [fullscreen] = useState(JSON.parse(sessionStorage.getItem(SessionConstants.FULLSCREEN)));

    return (
        <div className={ fullscreen? 'container-wide footerBanner' : 'container footerBanner' }>
            <div className="footer-desktop">
                <div className="desktopBanner">
                    <strong>ED3N SUPPORT</strong>
                </div>
                <div className="mobileBanner">
                    ED3N SUPPORT
                </div>
                <div className="row mt-2">
                    <div className="col-1"><span className="fi cdc-icon-email x24"></span></div>
                    <div className="col">
                        <span className="pl-1 emailBanner" itemProp="url"><a href="mailto:cdced3nsupport@cdc.gov">Email CDC ED3N Support</a></span>
                    </div>
                </div>
            </div>
        </div>
    );
}
