import React, {useState} from 'react';
import { NavLink } from 'react-router-dom';
import UserMenu from '../UserMenu/index.js';
import SessionConstants from '../../config/SessionConstants.js';

import './index.css';

export default function Navbar() {
  //check session for fullscreen setting
  const [fullscreen] = useState(JSON.parse(sessionStorage.getItem(SessionConstants.FULLSCREEN)));

  return (
    <div className="headerBkg">
      <div className={ fullscreen? 'header header-wide container-wide' : 'header container' }>
        <div className="appName">
          <NavLink to="/home">
          ED3N
          </NavLink>
        </div>
        <div className="navbar">
          <NavLink activeClassName="selected" to="/home">
            Home
          </NavLink>
          <NavLink activeClassName="selected" 
            to={{
              pathname: "/programsummary",
              state: {
                  programId: sessionStorage.getItem(SessionConstants.PROGRAM_ID),
              },
            }}
          >
            Evaluate
          </NavLink>
          <NavLink className='disabled' aria-disabled="true" onClick={e=>e.preventDefault()} to="/research" title="Coming Soon">
            Explore
          </NavLink>
          <NavLink className='disabled' aria-disabled="true" onClick={e=>e.preventDefault()} to="/educate"  title="Coming Soon">
            Educate
          </NavLink>
          <NavLink activeClassName="selected" to="/help">
            Help
          </NavLink>
        </div>
        <UserMenu></UserMenu>
      </div>
    </div>
  );
}

