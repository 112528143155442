import React, { useEffect, useState } from "react";
import { 
    makeStyles,
    Grid,
    TextField,
    MenuItem,
    TextareaAutosize,
} from '@material-ui/core';

import './index.css';

export default function FinalConsensus(props) {

    const useStyles = makeStyles({ //radio button styles
        radio: {
            '&$checked': {
                color: '#006778' //primary
            },
            "&.Mui-focusVisible": {
                color: "#ba4b00" //secondary
            },
        },
        checked: {},
        formLabel: {
            color: 'black'
        },
        formControlLabel: {
            fontSize: '.88rem' //~14px
        },
        multilineColor:{
            color: 'black',
            background: 'white',
            
        },
        '& .MuiInputBase-root': {
            background: 'white'
        },
        
        selectInputs: {
            "& .MuiOutlinedInput-input": {
                color: "#2e2e2e",
                background: "white"
            },
            "& .MuiInputLabel-root": {
                color: "#2e2e2e"
            },
            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                borderColor: "#737373"
            },
            "&:hover .MuiOutlinedInput-input": {
                color: "#006778"
            },
            "&:hover .MuiInputLabel-root": {
                color: "#006778"
            },
            "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                borderColor: "#006778"
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
                color: "#bb4d00",
                backgroundColor: "white"
            },
            "& .MuiInputLabel-root.Mui-focused": {
                color: "#bb4d00"
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "#bb4d00"
            }
        }
    });
    const classes = useStyles();

    var defaultClassification = props.finalClassification;
    var defaultComment = props.finalComments;
   
    const [classification, setClassification] = useState(defaultClassification);
    const [comment, setComment] = useState(defaultComment);

    useEffect(() => { 
        setClassification(defaultClassification);
        setComment(defaultComment);
      }, [defaultClassification, defaultComment] );

    const handleClassificationChange = (event) => {
        let newClassification = event.target.value;
        setClassification(newClassification);
        props.handleConsensusClassificationChange(newClassification);
    }

    const handleCommentChange = (event) => {
        let newComment = event.target.value;
        setComment(newComment);
        props.handleConsensusCommentChange(newComment);
    }

    return(
        <section className="section">
            <h2 className="sectionHeading">Final Consensus</h2>
            <div className="sectionContent">
                <div className="consensusSection finalConsensusSection">
                    <Grid container spacing={0} >
                        <Grid item md={3} sm={2} />
                        <Grid item md={6} sm={8} xs={12}>
                            <div className="muiFormItem classSelect">
                                <label id="finalClassificationLabel" htmlFor="finalClassificationSelect">Classification<span className="requiredIndicator">*</span></label>
                                <TextField
                                    fullWidth
                                    size="small"
                                    id="finalClassificationSelect"
                                    className={classes.selectInputs}
                                    value={classification || ''}
                                    onChange={handleClassificationChange}
                                    variant="outlined"
                                    select
                                    InputLabelProps={{shrink: false}} //disable label in outline
                                >
                                    <MenuItem value="Benign">Benign</MenuItem>
                                    <MenuItem value="Likely Benign">Likely Benign</MenuItem>
                                    <MenuItem value="Uncertain Significance">Uncertain Significance</MenuItem>
                                    <MenuItem value="Likely Pathogenic">Likely Pathogenic</MenuItem>
                                    <MenuItem value="Pathogenic">Pathogenic</MenuItem>
                                </TextField>
                            </div>
                            <div className="muiFormItem">
                                <label htmlFor="comments">Comments<span className="requiredIndicator">*</span></label>
                                <TextareaAutosize
                                    minRows="6"
                                    name="comment"
                                    value={comment || ''}
                                    onChange={handleCommentChange}
                                    id="comments"
                                    maxLength="1000"
                                    style={{ font: 'inherit', fontSize: '1rem' }}
                                />
                            </div>
                        </Grid>
                        <Grid item md={3} sm={2} />
                    </Grid>
                </div>
            </div>
        </section>
    )
}

