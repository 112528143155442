
import React from 'react';
import { TableHead, makeStyles} from '@material-ui/core';
import PropTypes from 'prop-types';
import {StyledTableRow,StyledTableCell,StyledTableSortLabel}  from './StyledTable'

const useStyles = makeStyles((theme) => ({
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }));
function MuiTableHeader(props) {
  const classes = useStyles();
    const { order, orderBy, onRequestSort, headCells, blankHeadCells, noSortHeadCells, noSortHeadCellsEnd, blankHeadCellsEnd } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
    return (
      <TableHead>
        <StyledTableRow>
          {
            blankHeadCells ?
              blankHeadCells.map((blankCell)=>
                <StyledTableCell key={blankCell.id}>
                    <span className='hiddenHeader'>{blankCell.hiddenHeader}</span>
                </StyledTableCell>
              )
            : null
          }
          {
            noSortHeadCells ? 
              noSortHeadCells.map((noSortCell)=>
                <StyledTableCell key={noSortCell.id}>
                  {noSortCell.label}
                </StyledTableCell>
              )
            : null
          }    
          {
            headCells ?
              headCells.map((headCell) => (
                <StyledTableCell
                  key={headCell.id}
                  align={headCell.align ? headCell.align : headCell.numeric ? 'right' : 'left'}
                  padding={headCell.disablePadding ? 'none' : 'normal'}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  <StyledTableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <span className={classes.visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </span>
                    ) : null}
                  </StyledTableSortLabel>
                </StyledTableCell>
              ))
            : null
          }
          {
            noSortHeadCellsEnd ? 
              noSortHeadCellsEnd.map((noSortCell)=>
                <StyledTableCell key={noSortCell.id}>
                  {noSortCell.label}
                </StyledTableCell>
              )
            : null
          }    
          {
            blankHeadCellsEnd ?
              blankHeadCellsEnd.map((blankCell)=>
                <StyledTableCell key={blankCell.id}>
                    <span className='hiddenHeader'>{blankCell.hiddenHeader}</span>
                </StyledTableCell>
              )
            : null
          }
        </StyledTableRow>
      </TableHead>
    );
  }
  
  MuiTableHeader.propTypes = {
    onRequestSort: PropTypes.func,
    order: PropTypes.oneOf(['asc', 'desc']),
    orderBy: PropTypes.string,
    headCells: PropTypes.array,
  };

  export default MuiTableHeader