import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "./index.css";


const LoadingInner = ({  isLoadingInner, loadingName }) => {
  return (
      <div className="loadingInner">
        {isLoadingInner ? 
              (
                loadingName === "mastermind" ? (
                  <span>Searching Mastermind articles. Please wait... <FontAwesomeIcon icon='spinner' className='spinner spinnerInner' size="2x" /></span>
                ) : (
                  <span>Loading... <FontAwesomeIcon icon='spinner' className='spinner spinnerInner' size="2x" /></span>
                )
              )
        :
              ('')
        }
      </div>
  );
}

export default LoadingInner;
