
import React, { useEffect } from 'react';
import { makeStyles, Grid, FormControl, TextField, MenuItem, Box, Button } from '@material-ui/core';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import PropTypes from 'prop-types';

const useStyleFilter = makeStyles({
    //custom colors for MUI text field select
    filterInputs: {
        "& .MuiOutlinedInput-input": {
            color: "#2e2e2e"
        },
        "& .MuiInputLabel-root": {
            color: "#2e2e2e"
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#737373"
        },
        "&:hover .MuiOutlinedInput-input": {
            color: "#006778"
        },
        "&:hover .MuiInputLabel-root": {
            color: "#006778"
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#006778"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
            color: "#bb4d00",
            backgroundColor: "white"
        },
        "& .MuiInputLabel-root.Mui-focused": {
            color: "#bb4d00"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#bb4d00"
        }
    }
});

//NOTE:  We could extend this component later.  
//For now, it's default filter by Gene and Condition
function TableFilter(props) {
    const filterClasses = useStyleFilter();
    // Array of options keys passed from parent
    const optionsArray = props.tableDataKeyList
         
    return(
        <Grid container spacing={3} >
            <Grid item xs={9}>
                <Grid container spacing={3} >
                    {props.daysRangeDefined ? 
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <TextField
                                fullWidth
                                size="small"
                                id="daysRangeFilter"
                                className={filterClasses.filterInputs}
                                value={props.selectedTimeRange}
                                onChange={props.handleDaysRangeChange}
                                variant="outlined"
                                label="Date Range"
                                select
                            >
                                <MenuItem value="All Time">All Time</MenuItem>
                                <MenuItem value="1 Week">1 Week</MenuItem>  
                                <MenuItem value="1 Month">1 Month</MenuItem>
                                <MenuItem value="6 Months">6 Months</MenuItem>
                                <MenuItem value="1 Year">1 Year</MenuItem>
                                <MenuItem value="2 Years">2 Years</MenuItem>
                                <MenuItem value="5 Years">5 Years</MenuItem>
                            </TextField>
                        </FormControl>
                    </Grid>
                    : ''}
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <TextField
                                fullWidth
                                size="small"
                                id="searchFilter"
                                className={filterClasses.filterInputs}
                                value={props.searchFilter}
                                onChange={props.onFilterTypeChange}
                                variant="outlined"
                                label="Search Filter"
                                select
                            >
                                <MenuItem value=""><em>None</em></MenuItem>  
                                {optionsArray
                                    .filter(option => props.filterNameConfig && props.filterNameConfig.hasOwnProperty(option)) //filter out undefined, etc. options
                                    .map(option => (
                                        <MenuItem key={option} value={option}>
                                            {"By " + props.filterNameConfig[option]}
                                        </MenuItem>
                                    ))
                                }
                            </TextField>
                        </FormControl>
                    </Grid>
                    {props.searchFilter && props.rows.length ? 
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <TextField
                                fullWidth
                                size="small"
                                id="filterByValueFilter"
                                className={filterClasses.filterInputs}
                                value={props.filterBy}
                                onChange={props.handleFilterChange}
                                variant="outlined"
                                label={props.filterNameConfig ? props.filterNameConfig[props.searchFilter] : props.searchFilter}
                                select
                            >
                                {props.filterOptions.map((option, index) => (
                                <MenuItem key={index} value={option}>{option}</MenuItem>
                                ))}
                            </TextField>
                        </FormControl>
                    </Grid>
                  : ''}
                </Grid>
            </Grid>
            <Grid item xs={3}>
                <Box display="flex" justifyContent="flex-end">
                    <Button 
                        id="clearFilters"
                        color="primary"
                        variant="contained"
                        aria-label="Clear table filters" 
                        onClick={props.onClearFilters}
                    >
                        <ClearAllIcon className="iconMarginRight" /> Clear Filters
                    </Button>
                </Box>
            </Grid>
        </Grid>
    )
}
TableFilter.propTypes = {
    onClearFilters: PropTypes.func.isRequired,
    rows: PropTypes.array.isRequired,
    filterBy: PropTypes.string.isRequired,
    searchFilter: PropTypes.string.isRequired,
    handleFilterChange: PropTypes.func.isRequired,
    onFilterTypeChange: PropTypes.func.isRequired,
    tableDataKeyList: PropTypes.array.isRequired,
    filterOptions: PropTypes.array.isRequired,
    filterNameConfig: PropTypes.object,
    daysRangeDefined: PropTypes.bool,
    daysRange: PropTypes.string,
    handleDaysRangeChange: PropTypes.func,
    selectedTimeRange: PropTypes.string,
};
export default TableFilter