import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { 
  Menu,
  MenuItem,
  Button,
  Badge,
  FormGroup,
  FormControlLabel,
  createTheme, 
  MuiThemeProvider
} from '@material-ui/core';
import Oauth from '../../utils/Oauth';
import Paths from '../../config/Paths';
import AlertModal from '../AlertModal';
import AboutModal from '../AboutModal';
import SessionConstants from '../../config/SessionConstants';
import Switch from '@material-ui/core/Switch';
import { alpha, styled } from '@material-ui/core/styles';
import './index.css';

export default function UserMenu() {

  const [ badgeContent, setBadgeContent ] = useState(0);
  const [ alertModalIsOpen, setAlertModal ] = useState(false);
  const [ aboutModalIsOpen, setAboutModal ] = useState(false);
  const [ anchorEl, setAnchorEl ] = useState(null);

  const theme = createTheme();

  const handleBadgeContent =(num)=>{
    setBadgeContent(num);  }

  const toggle = previous => !previous;

  const closeModal =()=>{
    setAboutModal(false);
    setAlertModal(false);
  }

  const handleAlertClick =()=> {
    setAlertModal(toggle);
    setAnchorEl(null);
  } 

  const handleAboutClick =()=> {
    setAboutModal(toggle);
    setAnchorEl(null);
  } 

  const handleAlertKeypress = e => {
    if (e.key === "Enter") {
      handleAlertClick
    }
  };

  const handleAboutKeypress = e => {
    if (e.key === "Enter") {
      handleAboutClick
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [checked, setChecked] = useState(JSON.parse(sessionStorage.getItem(SessionConstants.FULLSCREEN)));

  const handleSwitchClick = (e) => {
    e.preventDefault();
    if (checked == true) {
      sessionStorage.setItem(SessionConstants.FULLSCREEN, false);
      setChecked(false);
    } else {
      sessionStorage.setItem(SessionConstants.FULLSCREEN, true);
      setChecked(true);
    }
    window.location.reload(false);
  };

  const handleSwitchKeypress = e => {
    if (e.key === "Enter" || e.key === "Spacebar") {
      handleSwitchClick;
    }
  };
 
  const PrimarySwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: '#006778',
      '&:hover': {
        backgroundColor: alpha('#006778', theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#006778',
    },
  }));

  const clearTokens = () => {
    Oauth.clearAllSessionVariables();
    sessionStorage.setItem(SessionConstants.ERROR_MESSAGE, 'Logout');
    sessionStorage.setItem(SessionConstants.ERROR_DESCRIPTION, 'Sucessfully logged out of ED3N');
    sessionStorage.setItem(SessionConstants.LOGOUT_STATUS, true);
  }

  const handleLogout = async () => {
    await clearTokens();
    // console.log("_______------logout: " + SessionConstants.LOGOUT_STATUS);
    window.location.assign(Paths.samsLogout);
    // Localhost logout will redirect to ed3ndev.cdc.gov since redirect requires cdc.gov addresses
  }

  const handleLogoutKeypress = e => {
    if (e.key === "Enter") {
      handleLogout
    }
  };

  return (
    <div className="userInfo">
      <MuiThemeProvider theme={theme}>
        <Button 
          id="userMenuButton"
          className="userInfoButton" 
          aria-controls="user-menu" 
          aria-haspopup="true" 
          alt="User Menu" 
          aria-label="User Menu" 
          onClick={handleClick}
          // improve button fit in fullscreen mode
          style={{
            minWidth: "0px",
            marginRight: "2px"
          }}
        >
                  {/*<Badge 
            className="alertBadge" 
            badgeContent={badgeContent} 
            invisible={badgeContent==0} 
            role="status" 
            overlap="rectangular"
            aria-label={badgeContent + " Alerts"}
          >*/}
            {/*badgeContent will be session constant info item */}
            <FontAwesomeIcon icon={faUserCircle} size="2x" /> 
                  {/*</Badge>*/}
        </Button>
      </MuiThemeProvider>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem className="userAccountMenuItem" disabled>{sessionStorage.getItem(SessionConstants.USER_EMAIL)}</MenuItem> 
        <MenuItem className="userAccountMenuItem" disabled divider={true}>NBS Program: {sessionStorage.getItem(SessionConstants.PROGRAM_ID)}</MenuItem>
        {/*<MenuItem
          onClick={handleAlertClick}
          onKeyPress={handleAlertKeypress}
        >
          My Alerts 
          {
          badgeContent !==0 && 
          <Badge 
            className="alertBadge"
            color="error" 
            badgeContent={badgeContent} 
            invisible={badgeContent==0} 
            role="status" 
            overlap="rectangular"
            aria-label={badgeContent + " Alerts"}
          /> 
          }
          if badgeContent = 0, icon will not appear
        </MenuItem>*/}          
        <MenuItem
          component={Link}
          to={{
            pathname: "/userdashboard",
            state: {
              programId: sessionStorage.getItem(SessionConstants.PROGRAM_ID),
            },
          }}
          aria-label="My Dashboard"
          onClick={handleClose}
        >
          My Dashboard
        </MenuItem>
        <MenuItem
          component={Link}
          to={{
            pathname: "/datauploads",
            state: {
              programId: sessionStorage.getItem(SessionConstants.PROGRAM_ID),
            },
          }}
          aria-label="Data Uploads"
          onClick={handleClose}
        >
          Data Uploads
        </MenuItem>
        <MenuItem
          component={Link}
          to={{
            pathname: "/programdashboard",
            state: {
              programId: sessionStorage.getItem(SessionConstants.PROGRAM_ID),
            },
          }}
          aria-label="Program Dashboard"
          onClick={handleClose}
          divider={true}
        >
          Program Dashboard
        </MenuItem>
        <MenuItem
          onClick={handleSwitchClick}
          onKeyPress={handleSwitchKeypress}
          aria-label="Fullscreen Toggle"
          divider={true}
        >
          <FormGroup>
            <FormControlLabel className="fullscreenToggle" control={
              <PrimarySwitch
                checked={checked}
                
                inputProps={{ 'aria-label': 'controlled' }}
              />
            } label="Fullscreen" labelPlacement="start" />
          </FormGroup>
        </MenuItem>
        {/* <MenuItem>Account Settings</MenuItem>*/}
        <MenuItem 
          onClick={handleAboutClick}
          onKeyPress={handleAboutKeypress}
          aria-label="About"
          divider={true}
        >
          About 
        </MenuItem>
        <MenuItem
          onClick={handleLogout}
          onKeyPress={handleLogoutKeypress}
          aria-label="Sign Out"
        >
          Sign Out
        </MenuItem>
      </Menu>
      {/*<AlertModal
        closeModal={closeModal}
        modalIsOpen={Boolean(alertModalIsOpen)}
        setBadgeContent={handleBadgeContent}
      />*/}
      <AboutModal
        closeModal={closeModal}
        modalIsOpen={Boolean(aboutModalIsOpen)}
      />
    </div>
  );
}

