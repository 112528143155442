import React, {useState, useEffect} from 'react';
import Loading from '../Loading/index.js';
import {Helmet} from "react-helmet";
import BreadCrumbs from '../BreadCrumbs/index.js';
import {StyledTableRow,StyledTableCell} from '../../uiComponents/MuiTable/StyledTable.js'
import APIUtils from '../../utils/APIUtils.js';
import MuiTableHeader from '../../uiComponents/MuiTable/MuiTableHeader.js';
import TableFilter from '../../uiComponents/MuiTable/TableFilterGridNew';
import MuiTablePagination from '../../uiComponents/MuiTable/MuiTablePagination';
import {stableSort,getComparator} from '../../uiComponents/MuiTable/MuiHelper'
import { 
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  Paper
} from '@material-ui/core';
import DataStatusConfig from './DataStatusConfig.js';
import FilterNameConfig from './FilterNameConfig.js';
import moment from 'moment';
import './index.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    fontFamily: "Open Sans",
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

//Important: The id here must match the column name in the returned data. Label can be different for display.
const headCells = [
  { label: 'Program', id: 'programId', numeric: false, disablePadding: false },
  { label: 'File Name', id: 'fileName', numeric: false, disablePadding: false },
  { label: 'Platform', id: 'sequencingPlatform', numeric: false, disablePadding: false },
  { label: 'Variant Caller', id: 'variantCaller', numeric: false, disablePadding: false },
  { label: 'Reagent Kit', id: 'reagentKit', numeric: false, disablePadding: false },
  { label: 'Uploaded By', id: 'uploadedBy', numeric: false, disablePadding: false },
  { label: 'Date Uploaded', id: 'processStartDate', numeric: false, disablePadding: false },
  { label: 'Status', id: 'status', numeric: false, disablePadding: false },
];

export default function DataUploads(props) {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [initialRows, setInitialRows] = useState([])
  const [loading, setLoading] = useState(true);
  const [breadCrumbs, setBreadCrumbs] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [order, setOrder] = useState('desc'); //default sort direction
  const [orderBy, setOrderBy] = useState('createdDate'); //default sort column for table
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchFilter, setSearchFilter] = useState('');
  const [filterOptions, setFilterOptions] = useState([]);
  const [filterBy, setFilterBy] = useState('');
  const [tableDataKeyList, setTableDataKeyList] = useState([]);
  const [selectedTimeRange, setSelectedTimeRange] = useState("All Time");

  useEffect(() => { 
    setBreadCrumbs([{
      name: 'DataUploads',
    }]);
  }, [] );

  //intialize tableData
  useEffect(() => { 
    getUploadData();
  }, [] );

  const getUploadData = async () => {
    try {
      setLoading(true);
      const response = await APIUtils.getDataUploads(0);

      // Capture all keys in the table data to use for filtering
      const allTableDataKeys = response.reduce((keys, obj) => {
        Object.keys(obj).forEach(key => {
          if (key !=='processStartDate' && key !=='processEndDate' && !keys.includes(key)) {
            keys.push(key)
          }
        });
        return keys;
      }, []);

      setInitialRows(response);
      setRows(response);
      setTableData(response);
      // Set table keys in key list to use for filtering
      setTableDataKeyList(allTableDataKeys);
    }
    catch (error) {
      console.error("Error Getting Data Uploads: " + error);
      setLoading(false);
    }
    finally {
      setLoading(false);
    }
  }

  const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
  };
  
  const handleChangePage = (event, newPage) => {
      setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
  };

  const handleDaysRangeChange = (event) => {
    const selectedTimeRange = event.target.value;
    setSelectedTimeRange(selectedTimeRange);

    // Calculate the date range based on the selected time range
    const currentDate = new Date();
    const startDate = new Date();

    if (selectedTimeRange === "All Time") {
      // If "All Time" is selected, show all records without filtering
      setSearchFilter('');
      setFilterBy('');
      setTableData(initialRows);
      return;
    }

    switch (selectedTimeRange) {
      case "1 Week":
        startDate.setDate(currentDate.getDate() - 7);
        break;
      case "1 Month":
        startDate.setMonth(currentDate.getMonth() - 1);
        break;
      case "6 Months":
        startDate.setMonth(currentDate.getMonth() - 6);
        break;
      case "1 Year":
        startDate.setFullYear(currentDate.getFullYear() - 1);
        break;
      case "2 Years":
        startDate.setFullYear(currentDate.getFullYear() - 2);
        break;
      case "5 Years":
        startDate.setFullYear(currentDate.getFullYear() - 5);
        break;
      default:
        break;
    }

    const filtered = initialRows.filter(obj => {
      const objDate = new Date(obj.processStartDate);
      return objDate >= startDate && objDate <= currentDate;
    });

    // console.log("---DAYS RANGE: " + event.target.value);
    setSearchFilter('');
    setFilterBy('');
    setTableData(filtered); 
    setRows(filtered);
  }

  //++++++++++++++FILTERS+++++++++++++++++++++++++
  //Search Filter Type Select
  const filterTypeChange = (event) => {
    const selectedKey = event.target.value;
    setSearchFilter(selectedKey);

    const options = selectedKey ? [...new Set(rows.map(obj => obj[selectedKey]))] : [];
    setFilterOptions(options);
    
    setFilterBy(''); //clear any set filters on type change
    setTableData(rows); //reset filters to initial data when changing top level search filter type
  };

  const handleFilterChange = (event) => {
    let filterName = event.target.value;
    setFilterBy(filterName)
    let filteredValues = filterName
      ? rows.filter(obj => {
        return Object.values(obj).some(value => value === filterName)
      }) : rows; //filter from the original response: rows
    setTableData(filteredValues); 
  };

  const clearFilters = (event) => {
    setSearchFilter('');
    setFilterBy('');
    setSelectedTimeRange('All Time')
    setTableData(initialRows); //reset filters to initial data
  };

  // Return sentence case string for status 
  const dataStatusCell = (status) => {
    return DataStatusConfig[status] || '';
  }

  return (
    <>
      <Loading isLoading={loading}/>
      <Helmet>
        <title>ED3N - Data Uploads</title>
      </Helmet>
      <BreadCrumbs breadCrumbs={breadCrumbs} />
      <h1 className="pageTitle">Data Uploads</h1>
      {!(loading) ? (
        <>
          <div className="dataUploadTableFilterGrid">
            <TableFilter 
              rows={rows} 
              tableDataKeyList={tableDataKeyList}
              filterNameConfig={FilterNameConfig}
              searchFilter={searchFilter}
              filterBy={filterBy}
              filterOptions={filterOptions}
              onClearFilters={clearFilters}
              handleFilterChange={handleFilterChange}
              onFilterTypeChange={filterTypeChange}
              daysRangeDefined={true}
              handleDaysRangeChange={handleDaysRangeChange}
              selectedTimeRange={selectedTimeRange}
            />
          </div>
          <div className={classes.root}>
            <Paper className={classes.paper}>
              <TableContainer>
                <Table
                  className={classes.table}
                  size='small'
                  aria-label="data uploads" 
                >
                  <MuiTableHeader 
                    headCells={headCells} 
                    order={order}  
                    orderBy={orderBy} 
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                  { tableData.length ? (
                    stableSort(tableData, getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                      return (
                        <StyledTableRow tabIndex={-1} key={index}>
                          <StyledTableCell style={{width: '12%'}}>{row.programId}</StyledTableCell>
                          <StyledTableCell style={{width: '15%'}}>{row.fileName}</StyledTableCell>
                          <StyledTableCell style={{width: '12%'}}>{row.sequencingPlatform}</StyledTableCell>
                          <StyledTableCell style={{width: '12%'}}>{row.variantCaller}</StyledTableCell>
                          <StyledTableCell style={{width: '12%'}}>{row.reagentKit}</StyledTableCell>
                          <StyledTableCell style={{width: '10%'}}>{row.uploadedBy}</StyledTableCell> 
                          <StyledTableCell style={{width: '10%'}}>{row.processStartDate ? moment(row.processStartDate).format("MM/DD/YYYY") : ''}</StyledTableCell>
                          <StyledTableCell style={{width: '15%'}}>{dataStatusCell(row.status)}</StyledTableCell>
                        </StyledTableRow>
                      );
                      })
                    ) : (
                      <StyledTableRow>
                        <StyledTableCell style={{textAlign: 'center'}} colSpan={12}>No data upload records found</StyledTableCell>
                      </StyledTableRow>
                    )
                  }
                  </TableBody>
                </Table>
              </TableContainer>
              <MuiTablePagination 
                rowsPerPage={rowsPerPage}
                page={page}
                count={tableData.length}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage} 
              />
            </Paper>
          </div>
        </>
      ) : (
        // spacing for load spinner
        <div className="noData"></div>
      )}
    </>
  );
}
