import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import ReactHtmlParser from 'react-html-parser';

export default class FeedbackModal extends Component {

    constructor(props) {
        super(props);
    }

    //calls the closeModal from props which closes the modal
    onClose = () => {
        this.setState({ loading: false });
        this.props.closeModal();
    };

    render() {

        return (
            <div>
                <Modal
                    className="modal fileUploadModal"
                    overlayClassName="modalOverlay"
                    aria-modal="true"
                    contentLabel="Upload a New File"
                    shouldCloseOnOverlayClick={true}
                    isOpen={this.props.modalIsOpen}
                    onRequestClose={this.onClose}
                >
                    <div className="modalHeader">Upload a New File</div>
                    <div className="modalContent">
                        {/* <div className="sectionHeading center">
                            {this.props.message[0]}
                        </div> */}
                        <div className="sectionContent">
                        {this.props.message}
                        </div>
                    </div>
                    <div className="modalButtons">
                        <Link to={{ pathname: '/userdashboard' }} className="modalButton primaryButton modalLinkButton">
                            Go to User Dashboard
                        </Link>
                        <button className="modalButton" onClick={this.onClose}>
                            Close
                        </button>
                    </div>
                </Modal>
            </div>
        );
    }
}
