import React, { Component } from 'react';
import ReactTable from 'react-table';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

import './index.css';

export default class InterpretationsTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            breadCrumbs: [],
            summary: {},
            taskId: null,
            variantName: null,
            programId: null,
            assignmentModalIsOpen: false,
        };
    }

    closeModal = () => {
        this.setState({
            assignmentModalIsOpen: false,
        });
    };

    assignmentStatusCell = (rowInfo) => {
        var linkState = {
            summary: this.state.summary,
            breadCrumbs: this.state.breadCrumbs,
            programId: this.state.programId,
            variantId: rowInfo.original.variant.variantObjectId,
            variantName: rowInfo.original.variant.hgvsGrch38Name,
            variantReviewId: rowInfo.original.variantReviewId,
            gene: rowInfo.original.variant.gene,
            variantInterpretationId: rowInfo.original.variantInterpretation.variantInterpretationId
        } 

        switch(rowInfo.original.taskStatus){
            case "NEW":
                return(
                    <div>
                        <Link to={{ pathname: '/addinterpretation', state: linkState }}>
                            NEW <FontAwesomeIcon icon={faExclamationCircle} className="exclamation" />
                        </Link>
                    </div>
                );
            case "COMPLETED":
                return (<div>
                    {rowInfo.original.taskStatus}
                    </div>
                );
            case "UNASSIGNED":
                return (<div>
                    {rowInfo.original.taskStatus}
                    </div>
                );
            case "PENDING":
                    return(
                        <div>VCF LOAD PENDING</div>
                    );
            default:
                return (<Link to={{pathname: '/addinterpretation', state: linkState}}>
                    {rowInfo.original.taskStatus}
                    </Link>)
        }
    }

    assignedDate = (rowInfo) => {
        var tasks = rowInfo.original.taskHistoryItems.filter(function (e) {
            return  e.itemType == "CREATED"
        });
        return tasks.length > 0 ? moment(tasks[0].taskHistoryItemPk.itemDate).format("MM/DD/YYYY") : "";
    }

    closedDate = (rowInfo) => {
        var tasks = rowInfo.original.taskHistoryItems.filter(function (e) {
            return e.itemType == "CLOSED"
        });
        return tasks.length > 0 ? moment(tasks[0].taskHistoryItemPk.itemDate).format("MM/DD/YYYY") : "";
    }

    render() {
        const columns = [
            {
                Header: 'Variant Name',
                accessor: 'variant.hgvsGrch38Name',
            },
            {
                Header: 'Date Assigned',
                accessor: 'itemType', //separating these so filtering works
                Cell: this.assignedDate,
            },
            {
                Header: 'Date Completed',
                accessor: 'itemType', //separating these so filtering works
                Cell: this.closedDate,
            },
            {
                Header: 'Status',
                accessor: 'taskStatus',
                Cell: this.assignmentStatusCell, 
            },
        ];
        return (
            <div>
            <ReactTable
                data={this.props.data} 
                showPagination={false}
                minRows={1}
                defaultSorted={[
                    {
                        id: 'variant.hgvsGrch38Name',
                        desc: true,
                    },
                ]}
                columns={columns}
                //Make table headers focusable and add keypress for sorting for 508 accessibility
                getTheadThProps={(state, rowInfo, column, instance) => {
                    return {
                        tabIndex: 0,
                        onKeyPress: (e, handleOriginal) => {
                            if (e.which === 13 || e.which === 32) {
                                instance.sortColumn(column);
                            }
                        }
                    };
                }}
            />
            </div>
        );
    }
}
