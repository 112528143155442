import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AnnotationSectionsConfig from '../AnnotationSectionsConfig';

import './index.css';

export default class RelevantCriteriaCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: true,
    };
  }

  handleClick = () => {
    this.setState({
      active: !this.state.active,
    });
  };

  //return a list of jsx with category name and descriptions for each sourceType
  renderCriteriaDescription = () => {
    var array = [];
    AnnotationSectionsConfig[this.props.sourceType].categories.map(category => {
      array.push(
        <div key={category.description} className="category">
          <span className="categoryName">{category.name + ': '}</span>{' '}
          {category.description}
        </div>
      );
    });
    return array;
  };
  render() {
    return (
      <div className="categoriesContainer">
        <div className="categoriesHeading" tabIndex="0" onClick={this.handleClick} onKeyPress={this.handleClick}>
          Relevant Criteria Categories{' '}
          <FontAwesomeIcon
            icon={this.state.active ? 'chevron-up' : 'chevron-down'}
          />
        </div>

        <div
          className={this.state.active ? 'categoriesContent' : 'hiddenCustom'}
        >
          <div>{this.renderCriteriaDescription()}</div>
          <div className="hyperlink">
            For more information:{' '}
            <a
              href="https://www.acmg.net/docs/Standards_Guidelines_for_the_Interpretation_of_Sequence_Variants.pdf"
              target="_blank"
            >
              Click Here
            </a>
          </div>
        </div>
      </div>
    );
  }
}
