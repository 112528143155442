import * as constants from './Constants';

export default {
  PVS1: constants.PATHOGENIC_VERY_STRONG,
  PS1: constants.PATHOGENIC_STRONG,
  PS2: constants.PATHOGENIC_STRONG,
  PS3: constants.PATHOGENIC_STRONG,
  PS4: constants.PATHOGENIC_STRONG,
  PM1: constants.PATHOGENIC_MODERATE,
  PM2: constants.PATHOGENIC_MODERATE,
  PM3: constants.PATHOGENIC_MODERATE,
  PM4: constants.PATHOGENIC_MODERATE,
  PM5: constants.PATHOGENIC_MODERATE,
  PM6: constants.PATHOGENIC_MODERATE,
  PP1: constants.PATHOGENIC_SUPPORTING,
  PP2: constants.PATHOGENIC_SUPPORTING,
  PP3: constants.PATHOGENIC_SUPPORTING,
  PP4: constants.PATHOGENIC_SUPPORTING,
  PP5: constants.PATHOGENIC_SUPPORTING,
  BA1: constants.BENIGN_STAND_ALONE,
  BS1: constants.BENIGN_STRONG,
  BS2: constants.BENIGN_STRONG,
  BS3: constants.BENIGN_STRONG,
  BS4: constants.BENIGN_STRONG,
  BP1: constants.BENIGN_SUPPORTING,
  BP2: constants.BENIGN_SUPPORTING,
  BP3: constants.BENIGN_SUPPORTING,
  BP4: constants.BENIGN_SUPPORTING,
  BP5: constants.BENIGN_SUPPORTING,
  BP6: constants.BENIGN_SUPPORTING,
  BP7: constants.BENIGN_SUPPORTING,
};
