export default {
  'description': [
    'gnomAD',
    'SNP',
    'ClinVar',
    'UCSC',
  ],
  'Population Frequency': {
    'categories': [
      {
        'name': 'BA1',
        'description':
          'Allele frequency is >5% in Exome Sequencing Project, 1000 Genomes Project, or Exome Aggregation Consortium',
      },
      {
        'name': 'BS1',
        'description': 'Allele frequency is greater than expected for disorder',
      },
      {
        'name': 'BS2',
        'description':
          'Observed in a healthy adult individual for a recessive (homozygous), dominant (heterozygous), or X-linked(hemizygous) disorder, with full penetrance expected at an early age',
      },
      {
        'name': 'PM2',
        'description':
          'Absent from controls (or at extremely low frequency if recessive) in Exome Sequencing Project, 1000 Genomes Project, or Exome Aggregation Consortium',
      },
      {
        'name': 'PS4',
        'description':
          'The prevalence of the variant in affected individuals is significantly increased compared with the prevalencein controls',
      },
      {
        'name': 'Not Evaluated',
        'description':
          'Not Evaluated',
      },
      {
        'name': 'No Information on Variant',
        'description':
          'No Information on Variant',
      },
    ],
    'sources': ['gnomAD', 'SNP'],
  },
  'Disease Databases': {
    'categories': [
      {
        'name': 'PP5',
        'description':
          'Reputable source recently reports variant as pathogenic, but the evidence is not available to the laboratory to perform an independent evaluation',
      },
      {
        'name': 'BP6',
        'description':
          'Reputable source recently reports variant as benign, but the evidence is not available to the laboratory to perform anindependent evaluation',
      },
      {
        'name': 'Not Evaluated',
        'description':
          'Not Evaluated',
      },
      {
        'name': 'No Information on Variant',
        'description':
          'No Information on Variant',
      },
    ],
    'sources': ['ClinVar'],
  },
  'Nucleotide Conservation Prediction': {
    'categories': [
      {
        'name': 'PP3',
        'description':
          'Multiple lines of computational evidence support a deleterious effect on the gene or gene product (conservation, evolutionary, splicing impact, etc.)',
      },
      {
        'name': 'BP4',
        'description':
          'Multiple lines of computational evidence suggest no impact on gene or gene product (conservation, evolutionary, splicing impact, etc.)',
      },
      {
        'name': 'Not Evaluated',
        'description':
          'Not Evaluated',
      },
      {
        'name': 'No Information on Variant',
        'description':
          'No Information on Variant',
      },
    ],
    'sources': ['UCSC'],
  },
  'Literature': {
    'categories': [
      {
        'name': 'BA1',
        'description':
          'Allele frequency is >5% in Exome Sequencing Project, 1000 Genomes Project, or Exome Aggregation Consortium',
      },
      {
        'name': 'BS1',
        'description': 'Allele frequency is greater than expected for disorder',
      },
      {
        'name': 'BS2',
        'description':
          'Observed in a healthy adult individual for a recessive (homozygous), dominant (heterozygous), or X-linked(hemizygous) disorder, with full penetrance expected at an early age',
      },
      {
        'name': 'BS3',
        'description':
          'Well-established in vitro or in vivo functional studies show no damaging effect on protein function or splicing',
      },
      {
        'name': 'BS4',
        'description': 'Lack of segregation in affected members of a family',
      },
      {
        'name': 'BP1',
        'description':
          'Missense variant in a gene for which primarily truncating variants are known to cause disease',
      },
      {
        'name': 'BP2',
        'description':
          'Observed in trans with a pathogenic variant for a fully penetrant dominant gene/disorder or observed in cis with a pathogenic variant in any inheritance pattern',
      },
      {
        'name': 'BP3',
        'description':
          'In-frame deletions/insertions in a repetitive region without a known function',
      },
      {
        'name': 'BP4',
        'description':
          'Multiple lines of computational evidence suggest no impact on gene or gene product (conservation, evolutionary, splicing impact, etc.)',
      },
      {
        'name': 'BP5',
        'description':
          'Variant found in a case with an alternate molecular basis for disease',
      },
      {
        'name': 'BP6',
        'description':
          'Reputable source recently reports variant as benign, but the evidence is not available to the laboratory to perform an independent evaluation',
      },
      {
        'name': 'BP7',
        'description':
          'A synonymous (silent) variant for which splicing prediction algorithms predict no impact to the splice consensus sequence nor the creation of a new splice site AND the nucleotide is not highly conserved',
      },
      {
        'name': 'PP1',
        'description':
          'Cosegregation with disease in multiple affected family members in a gene definitively known to cause the disease',
      },
      {
        'name': 'PP2',
        'description':
          'Missense variant in a gene that has a low rate of benign missense variation and in which missense variants are a common mechanism of disease',
      },
      {
        'name': 'PP3',
        'description':
          'Multiple lines of computational evidence support a deleterious effect on the gene or gene product (conservation, evolutionary, splicing impact, etc.)',
      },
      {
        'name': 'PP4',
        'description':
          'Patient’s phenotype or family history is highly specific for a disease with a single genetic etiology',
      },
      {
        'name': 'PP5',
        'description':
          'Reputable source recently reports variant as pathogenic, but the evidence is not available to the laboratory to perform an independent evaluation',
      },
      {
        'name': 'PM1',
        'description':
          'Located in a mutational hot spot and/or critical and well-established functional domain (e.g., active site of an enzyme) without benign variation',
      },
      {
        'name': 'PM2',
        'description':
          'Absent from controls (or at extremely low frequency if recessive) (Table 6) in Exome Sequencing Project, 1000 Genomes Project, or Exome Aggregation Consortium',
      },
      {
        'name': 'PM3',
        'description':
          'For recessive disorders, detected in trans with a pathogenic variant',
      },
      {
        'name': 'PM4',
        'description':
          'Protein length changes as a result of in-frame deletions/insertions in a nonrepeat region or stop-loss variants',
      },
      {
        'name': 'PM5',
        'description':
          'Novel missense change at an amino acid residue where a different missense change determined to be pathogenic has been seen before',
      },
      {
        'name': 'PM6',
        'description':
          'Assumed de novo, but without confirmation of paternity and maternity',
      },
      {
        'name': 'PS1',
        'description':
          'Same amino acid change as a previously established pathogenic variant regardless of nucleotide change',
      },
      {
        'name': 'PS2',
        'description':
          'De novo (both maternity and paternity confirmed) in a patient with the disease and no family history',
      },
      {
        'name': 'PS3',
        'description':
          'Well-established in vitro or in vivo functional studies supportive of a damaging effect on the gene or gene product',
      },
      {
        'name': 'PS4',
        'description':
          'The prevalence of the variant in affected individuals is significantly increased compared with the prevalence in controls',
      },
      {
        'name': 'PVS1',
        'description':
          'null variant (nonsense, frameshift, canonical ±1 or 2 splice sites, initiation codon, single or multiexon deletion) in a gene where LOF is a known mechanism of disease',
      },
      {
        'name': 'Not Evaluated',
        'description':
          'Not Evaluated',
      },
      {
        'name': 'No Information on Variant',
        'description':
          'No Information on Variant',
      },
    ],
    'sources': ['PubMed'],
  },
  'Clinical': {
    'categories': [
      {
        'name': 'PS2',
        'description':
          'De novo (both maternity and paternity confirmed) in a patient with the disease and no family history',
      },
      {
        'name': 'PM3',
        'description':
          'For recessive disorders, detected in trans with a pathogenic variant',
      },
      {
        'name': 'PM6',
        'description':
          'Assumed de novo, but without confirmation of paternity and maternity',
      },
      {
        'name': 'PP1',
        'description':
          'Cosegregation with disease in multiple affected family members in a gene definitively known to cause the disease',
      },
      {
        'name': 'PP4',
        'description':
          'Patient’s phenotype or family history is highly specific for a disease with a single genetic etiology',
      },
      {
        'name': 'BS4',
        'description': 'Lack of segregation in affected members of a family',
      },
      {
        'name': 'BP2',
        'description':
          'Observed in trans with a pathogenic variant for a fully penetrant dominant gene/disorder or observed in cis with a pathogenic variant in any inheritance pattern',
      },
      {
        'name': 'Not Evaluated',
        'description':
          'Not Evaluated',
      },
      {
        'name': 'No Information on Variant',
        'description':
          'No Information on Variant',
      },
    ],
    'sources': [],
  },
  'Computational Predictive Programs': {
    'categories': [
      {
        'name': 'BP1',
        'description':
          'Missense in gene where only truncating causes disease',
      },
      {
        'name': 'BP4',
        'description':
          'Multiple lines of computational evidence suggest no impact on gene/gene product',
      },
      {
        'name': 'BP7',
        'description':
          'Silent variant with non predicted splice impact',
      },
      {
        'name': 'PP3',
        'description':
          'Multiple lines of computational evidence support a deleterious effect on the gene/gene product',
      },
      {
        'name': 'PM4',
        'description':
          'Novel missense change at an amino acid residue where a different pathogenic missense change has been seen before',
      },
      {
        'name': 'PM5',
        'description': 
          'Lack of segregation in affected members of a family',
      },
      {
        'name': 'PS1',
        'description':
          'Same amino acid change as an established pathogenic variant',
      },
      {
        'name': 'PVS1',
        'description':
          'Predicted null variant in a gene where LOF is a known mechanism of disease',
      },
      {
        'name': 'Not Evaluated',
        'description':
          'Not Evaluated',
      },
      {
        'name': 'No Information on Variant',
        'description':
          'No Information on Variant',
      },
    ],
    'sources': [],
  },
  'Functional Studies': {
    'categories': [
      {
        'name': 'BS3',
        'description':
          'Well-established functional studies show no deleterious effect',
      },
      {
        'name': 'PP2',
        'description': 
          'Missense in gene with low rate of benign missense variants and pathogenic missenses common',
      },
      {
        'name': 'PM1',
        'description':
          'Mutational hot spot or well-studied functional domain without benign variation',
      },
      {
        'name': 'PS3',
        'description':
          'Well-established functional studies show a deleterious effect',
      },
      {
        'name': 'Not Evaluated',
        'description':
          'Not Evaluated',
      },
      {
        'name': 'No Information on Variant',
        'description':
          'No Information on Variant',
      },
    ],
    'sources': [],
  },
};
