import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class BreadCrumbs extends Component {

    constructor(props) {
      super(props);
    }

    componentDidMount() {
      console.log('--BreadCrumbs.componentDidMount()');
    }

    generateProgramSummary = (isLast) => {
      if (isLast) {
        return(
          <li key="BreadCrumbs_programSummary">Program Summary</li>     
        );
      } else {
        return(
          <li key="BreadCrumbs_programSummary">
            <Link to={{
                pathname: '/programsummary',
              }}
              className="breadCrumb"
            >
              Program Summary
            </Link>
          </li>
        );
      }
    }

    generateVariantDetails = (i, breadCrumb, isLast) => {
      if (isLast) {
        return(
          <li key="BreadCrumbs_variantDetails" >Variant {breadCrumb.variantName}</li>
        );
      } else {
        return(
          <li key="BreadCrumbs_variantDetails">
            <Link to={{
                pathname: '/variantdetails/' + breadCrumb.variantName,
                state: {
                  variantId: breadCrumb.variantId,
                  variantName: breadCrumb.variantName,
                  breadCrumbs: this.props.breadCrumbs.slice(0, i),
                },
              }}
              className="breadCrumb"
            >
            Variant {breadCrumb.variantName}
            </Link>
          </li>
        );
      }
    }

    generateCaseDetails = (i, breadCrumb, isLast) => {
      if (isLast) {
        return(
          <li key="BreadCrumbs_caseDetails">Case {breadCrumb.caseId}</li>
        );
        } else {
        return(
            <li key="BreadCrumbs_caseDetails">
            <Link to={{
                pathname: '/casedetails/' + breadCrumb.caseId,
                state: {
                    caseId: breadCrumb.caseId,
                    breadCrumbs: this.props.breadCrumbs.slice(0, i),
                },
                }}
                className="breadCrumb"
            >
            Case {breadCrumb.caseId}
            </Link>
            </li>
        );
      }
    }

    generateAddInterpretation = () => {
      return(
        <li key="BreadCrumbs_addInterpretation">Add Interpretation</li>
      );
    }

    generateVariantConsensus = () => {
      return(
        <li key="BreadCrumbs_variantConsensus">Variant Consensus</li>
      );
    }

    generateUserDashboard = () => {
      return(
        <li key="BreadCrumbs_userDashboard">User Dashboard</li>
      );
    }

    generateProgramDashboard = (isLast) => {
      if (isLast) {
        return(
          <li key="BreadCrumbs_programDashboard">Program Dashboard</li>     
        );
      } else {
        return(
          <li key="BreadCrumbs_programDashboard">
            <Link to={{
                pathname: '/programdashboard',
              }}
              className="breadCrumb"
            >
              Program Dashboard
            </Link>
          </li>
        );
      }
    }

    generateVariantDashboard = (breadCrumb) => {
      return(
        <li key="BreadCrumbs_variantDashboard">Variant Dashboard: {breadCrumb.variantName}</li>
      );
    }

    generateDataUploads = () => {
      return(
        <li key="BreadCrumbs_dataUploads">Data Uploads</li>
      );
    }

    render() {
      let output = [];
      for (const [i, breadCrumb] of this.props.breadCrumbs.entries()) {                                                         
        switch(breadCrumb.name) {
          case 'ProgramSummary':
            if (i === (this.props.breadCrumbs.length - 1)) {
              output.push(this.generateProgramSummary(true));
            } else {
              output.push(this.generateProgramSummary(false));
            }
            break;
          case 'VariantDetails':
            if (i === (this.props.breadCrumbs.length - 1)) {
              output.push(this.generateVariantDetails(i, breadCrumb, true));
            } else {
              output.push(this.generateVariantDetails(i, breadCrumb, false));
            }
            break;
          case 'CaseDetails':
            if (i === (this.props.breadCrumbs.length - 1)) {
              output.push(this.generateCaseDetails(i, breadCrumb, true));
            } else {
              output.push(this.generateCaseDetails(i, breadCrumb, false));
            }
            break;
          case 'AddInterpretation':
            output.push(this.generateAddInterpretation());
            break;
          case 'VariantConsensus':
            output.push(this.generateVariantConsensus());
            break;
          case 'UserDashboard':
            output.push(this.generateUserDashboard());
            break;
          case 'ProgramDashboard':
            if (i === (this.props.breadCrumbs.length - 1)) {
              output.push(this.generateProgramDashboard(true));
            } else {
              output.push(this.generateProgramDashboard(false));
            }
            break;
          case 'VariantDashboard':
            output.push(this.generateVariantDashboard(breadCrumb));
            break;
          case 'DataUploads':
            output.push(this.generateDataUploads());
            break;
        }
      }

      return (
        <div>
          <ul className="breadCrumbs" >
            <li key="BreadCrumbs_baseUrl">
              <Link to={{
                  pathname: '/home',
                }}
                className="breadCrumb"
              >
                ED3N
              </Link>
            </li>
            {output}
          </ul>
        </div>
      );
    }

}