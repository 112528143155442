//DateUtils Libary
//SortDate: To use with <ReactTable>
export const SortDate =(a,b)=> {
   console.log('-- sortdate --')
    var a1 = new Date(a);
    var b1 = new Date(b);
    if (a1<b1) 
        return 1;
    else if(a1>b1) 
        return -1;
    else 
        return 0;
  };
 
  export const convertUTCDateToLocalDate=(utcDate) => {
   var dateLocal = new Date(utcDate);
   return dateLocal.toLocaleString();
  }
  
