import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "./index.css";


const Loading = ({  isLoading }) => {
  return (
      <div className="loading">
        {isLoading ? 
              <FontAwesomeIcon icon='spinner' className='spinner' size="10x" />
        :
              ('')
        }
      </div>
  );
}

export default Loading;
