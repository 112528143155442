import axios from 'axios';
import Paths from '../config/Paths.js';
import Oauth from './Oauth.js';

import SessionConstants from '../config/SessionConstants.js';

const Ed3nConfig = __ED3N_CONFIG__;

axios.defaults.baseURL = __API__;

//  Set the axios interceptor. This injects the authentication header and other required headers into every request
axios.interceptors.request.use(async (config) => {
        console.log('An interceptor is running');
        //  Set the authorization header
        if (config.url == Paths.ed3nToken || config.url == Paths.ed3nValidate) {
            //  we're hitting the ED3N token endpoint. Use basic auth
            config.headers.Authorization = 'Basic ' + window.btoa(Ed3nConfig.clientId + ':' + Ed3nConfig.clientSecret);
            
        } else {
            //  we're hitting a regular info endpoint. Use Bearer token auth
            if(!await Oauth.isEd3nTokenValid) await Oauth.refreshEd3nToken();
            const ed3nToken = sessionStorage.getItem(SessionConstants.ED3N_ACCESS_TOKEN);
            config.headers.Authorization = 'Bearer ' + ed3nToken;
        }

        return config;
    },
    error => Promise.reject(error)
);

axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error.response.status === 401) {
            alert("Your session token has expired. Please log in to ED3N again.");
            window.location.assign(Paths.samsLogout);
        } else {
            return Promise.reject(error);
        }
    }
);

const APIUtils = {

    /*  ========================================
     *  ============ OAUTH ENDPOINTS ===========
     *  ======================================== */

    /*  SAMS TOKEN ENDPOINTS */

    async getSamsTokens(code) {
        let url = Paths.samsToken;
        var formData = new FormData();
        formData.append('accessCode', code);

        var response = await axios.post(url, formData);
        var tokens = {
            accessToken: response.data.accessToken,
            refreshToken: response.data.refreshToken,
        };
        return tokens;
    },

    async refreshSamsTokens(token) {
        let url = Paths.samsRefreshToken;
        var formData = new FormData();
        formData.append('refreshToken', token);

        var response = await axios.post(url, formData);
        var tokens = {
            accessToken: response.data.accessToken,
            refreshToken: response.data.refreshToken,
        };
        return tokens;
    },

    async validateSamsToken(token) {
        let url = Paths.samsValidate;
        var formData = new FormData();
        formData.append('samsAccessToken', token);

        var response = await axios.post(url, formData);
        return response.data;
    },

    async getSamsUserInfo(token) {
        let url = Paths.samsUserInfo;
        let formData = new FormData();
        formData.append('samsAccessToken', token);

        var response = await axios.post(url, formData);
        return response.data;
    },

    /*  ED3N TOKEN ENDPOINTS */

    async getEd3nTokens(usr, pwd) {
        let url = Paths.ed3nToken;
        var formData = new FormData();
        formData.append('client_id', Ed3nConfig.clientId);
        formData.append('secret', Ed3nConfig.clientSecret);
        formData.append('grant_type', 'password');
        formData.append('username', usr);
        formData.append('password', pwd);

        var response = await axios.post(url, formData);
        var tokens = {
            accessToken: response.data.access_token,
            refreshToken: response.data.refresh_token,
        };
        return tokens;
    },

    async refreshEd3nTokens(token) {
        let url = Paths.ed3nToken;
        var formData = new FormData();
        formData.append('client_id', Ed3nConfig.clientId);
        formData.append('secret', Ed3nConfig.clientSecret);
        formData.append('grant_type', 'refresh_token');
        formData.append('refresh_token', token);

        var response = await axios.post(url, formData);
        var tokens = {
            accessToken: response.data.access_token,
            refreshToken: response.data.refresh_token,
        };
        return tokens;
    },

    async validateEd3nToken(token) {
        let url = Paths.ed3nValidate;
        var formData = new FormData();
        formData.append('token', token);

        var response = await axios.post(url, formData);
        return response.data;
    },

    async getEd3nUserInfo() {
        let url = Paths.ed3nUserInfo;

        var response = await axios.get(url);
        return response.data;
    },

    /*  ========================================
     *  ======= TASK MANAGEMENT ENDPOINTS ======
     *  ======================================== */
    async getAlerts() {
        let url = Paths.alert;

        var response = await axios.get(url);
        return response.data;
    },

    async getTasksByUser() {
        let url = Paths.taskGetByUser;

        var response = await axios.get(url);
        return response.data;
    },

    async getTaskById(taskId) {
        let url = Paths.taskGetById.replace('{taskId}', taskId);

        var response = await axios.get(url);
        return response.data;
    },

    async getTasksByVariant(variantId) {
        let url = Paths.taskGetByVariant.replace('{variantId}', variantId);

        var response = await axios.get(url);
        return response.data;
    },

    async updateTask(taskId, actionChoice, formData) {
        let url = Paths.taskUpdate.replace('{taskId}', taskId).replace('{taskAction}', actionChoice);

        await axios.post(url, formData);
    },

    /*  ========================================
     *  ========= DATASTORE ENDPOINTS ==========
     *  ======================================== */
    async getDatastoreVariant(variantId) {
        let url = Paths.datastoreGetVariant.replace('{variantId}', variantId);

        var response = await axios.get(url);
        return response.data;
    },

    /*  ========================================
    *   ========= MOLECULAR ENDPOINTS ==========
    *   ======================================== */

    /*  PROGRAM SUMMARY ENDPOINTS */
    async getVariantsByProgramId(programId) {
        let url = Paths.getVariantSummaryByProgramId.replace('{programId}', programId);
        var response = await axios.get(url);
        return response.data;
    },

    async getVariantsByProgramCaseId(programId, caseId) {
        let url = Paths.getVariantSummaryByProgramCaseId.replace('{programId}', programId).replace('{programCaseId}', caseId);

        var response = await axios.get(url);
        return response.data;
    },

    async getCasesByProgramId(programId) {
        let url = Paths.getCaseSummaryByProgramId.replace('{programId}', programId);

        var response = await axios.get(url);
        return response.data;
    },

    async getDataUploads(daysRange) {
        let url = Paths.molecularGetDataUploads.replace('{days}', daysRange);

        var response = await axios.get(url);
        return response.data;
    },

    /*  DATA UPLOADING ENDPOINTS */

    async uploadMolecularFile(formData) {
       let url = Paths.molecularFileUpload;     
       console.log("URL", url)
       var response = await axios.post(url, formData);
       return response.data;
    },

    /*  REVIEW ENDPOINTS */
    async getReview(programId, variantId) {
        let url = Paths.molecularGetReview.replace('{programId}', programId).replace('{variantId}', variantId);

        var response = await axios.get(url);
        return response.data;
    },
    async deleteReview(formData) {
        return this.getResponseData(Paths.molecularDeleteReview,formData);
    },
    async createReview(formData) {
        return this.getResponseData(Paths.molecularCreateReview,formData);
    },
    async reopenReview(formData) {
        return this.getResponseData(Paths.molecularReopenReview,formData);
    },
    /*  INTERPRETATION ENDPOINTS */

    async getVariantInterpretation(variantId) {
        let url = Paths.molecularGetInterpretation.replace('{variantId}', variantId);

        var response = await axios.get(url);
        return response.data;
    },

    async getPartialVariantInterpretation(programId, variantId) {
        let url = Paths.molecularGetPartialVariantInterpretation.replace('{programId}', programId).replace('{variantId}', variantId);

        var response = await axios.get(url);
        return response.data;
    },

    async addVariantInterpretation(programId, variantId, interpretationJson) {
        let url = Paths.molecularAddVariantInterpretation.replace('{programId}', programId).replace('{variantId}', variantId);

        var response = await axios.post(url, interpretationJson);
        return response.data;
    },

    async updateVariantInterpretation(programId, variantId, interpretationJson) {
        let url = Paths.molecularUpdateVariantInterpretation.replace('{programId}', programId).replace('{variantId}', variantId);

        var response = await axios.post(url, interpretationJson);
        return response.data;
    },

    async assignInterpretation(reviewId, formData) {
        let url = Paths.molecularAssignVariantInterpretation.replace('{variantReviewId}', reviewId);

        var response = await axios.post(url, formData);
        return response.data;
    },

    async unassignInterpretation(reviewId, formData) {
        let url = Paths.molecularUnassignVariantInterpretation.replace('{variantReviewId}', reviewId);

        var response = await axios.post(url, formData);
        return response.data;
    },
    async createInterpretation(reviewId, formData) {
        let url = Paths.molecularUnassignVariantInterpretation.replace('{variantReviewId}', reviewId);
        var response = await axios.post(url, formData);
        return response.data;
    },

    /*  CONSENSUS ENDPOINTS */

    async getFinalVariantInterpretations(variantId) {
        let url = Paths.molecularGetFormattedFinalVariantInterpretations.replace('{variantId}', variantId);

        var response = await axios.get(url);
        return response.data;
    },

    async getConsensusSummary(programId, variantId) {
        let url = Paths.molecularGetConsensusSummary.replace('{variantId}', variantId).replace('{programId}', programId);

        var response = await axios.get(url);
        return response.data;
    },

    /*  LITERATURE ENDPOINTS */

    async searchLiteratureForVariant(variantId) {
        let url = Paths.molecularSearchLiterature.replace("{variantId}", variantId);

        var response = await axios.get(url);
        return response.data;
    },

    async getLiteratureReviewsForReview(variantInterpretationId) {
        let url = Paths.molecularGetLiteratureReviews.replace("{variantInterpretationId}", variantInterpretationId);

        var response = await axios.get(url);
        return response.data;
    },

    async saveLiteratureReview(variantInterpretationId, formData) {
        let url = Paths.molecularSaveLiteratureReviews.replace('{variantInterpretationId}', variantInterpretationId);

        var response = await axios.post(url, formData);
        return response.data;
    },

    async deleteLiteratureReview(variantInterpretationId, pmid) {
        let url = Paths.molecularDeleteLiteratureReview.replace("{variantInterpretationId}", variantInterpretationId).replace("{pmid}", pmid);

        await axios.get(url);
    },

    async getLiteratureCategories() {
        let url = Paths.molecularGetLiteratureCategories;

        var response = await axios.get(url);
        return response.data;
    },

    /* ANNOTATION ENDPOINTS */

    async getAnnotationsForVariant(variantId) {
        let url = Paths.molecularGetAnnotations.replace('{variantId}', variantId);

        var response = await axios.get(url);
        // console.log("annotations for variant: " + response.data);
        return response.data;
    },

    /*  ===============================
     *  ===== REPORTING ENDPOINTS =====
     *  =============================== */

    async getCaseReport(programId, caseId) {
        let url = Paths.getCaseReport.replace('{programId}', programId).replace('{caseId}', caseId);
        let headers = {
            'Content-Type': 'application/xml',
            'Accept': 'application/xml'
        };

        var response = await axios.get({
            url: url,
            headers: headers,
        });
        return response.data;
    },

    /*  ===============================
     *  ====== SETTINGS ENDPOINTS =====
     *  =============================== */

    async getProgramSettings(programId) {
        let url = Paths.getProgramSetting.replace("{programId}", programId);

        var response = await axios.get(url);
        return response.data;
    },

    async updateProgramSettings(programId, formData) {
        let url = Paths.updateProgramSetting.replace('{programId}', programId);

        var response = await axios.post(url, formData);
        return response.data;
    },

    async updateReviewSettings(reviewId, formData) {
        let url = Paths.molecularUpdateReviewSetting.replace('{variantReviewId}', reviewId);

        var response = await axios.post(url, formData);
        return response.data;
    },

    async getProgramUsers(programId) {
        let url = Paths.getProgramUsers.replace('{programId}', programId);

        var response = await axios.get(url);
        return response.data;
    },
     // **** Helper **************//  
    async getResponseData(url,formData=null){
        if (formData ==null)
             return await axios.get(url).data;
        else
             return (await axios.post(url,formData)).data;
    }
}

export default APIUtils;