
import { makeStyles, withStyles, TableRow,TableCell,TablePagination,TableSortLabel}  from '@material-ui/core';

//#region useStyles (main table style)
export const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      fontFamily: "Open Sans",
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }));
//#endregion

//#region StyledTableRow
export const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
//#endregion

//#region StyledTabelCell
export const StyledTableCell = withStyles(() => ({
  head: {
    fontSize: '.9rem',
    padding: '7px 12px',
    color: '#000000',
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    borderBottom: '1px solid black'
  },
  body: {
    fontSize: '.9rem',
    padding: '7px 12px',
    color: '#000000',
    '& a': {
      color: 'var(--primary-color)',
      textDecoration: 'underline',
      cursor: 'pointer',
    }
  },
}))(TableCell);
//#endregion

//#region StyledTablePagination
export const StyledTablePagination = withStyles(() => ({
  root: {
    color: 'black',
    '&:focus': {
      color: 'red',
    },
    '& .MuiButtonBase-root': {
      disableRipple: true,
    },
    '& .MuiButtonBase-root:hover': {
      backgroundColor: '#006778',
      color: 'white',
      opacity: '1',
    },
    '& .Mui-focused': {
      outline: '#bb4d00 solid 3px',
      color: 'black',
      opacity: '1',
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: 'white',
    }
  },
}))(TablePagination);
//#endregion

//#region StyledTableSortLabel
export const StyledTableSortLabel = withStyles(() => ({
  root: {
    color: 'black',
    '&:hover': {
      color: '#006778',
      opacity: '1',
    },
    '&:focus-visible': {
      outline: '#bb4d00 solid 3px',
      color: 'black',
    },
  },
  icon: {
    color: 'inherit !important'
  },

}))(TableSortLabel);
//#endregion

  
