import React, { useState } from 'react';
import { makeStyles, Grid, FormControl, TextField, MenuItem } from '@material-ui/core';

import './index.css';

const useStyleFilter = makeStyles({
  //custom colors for MUI text field select
  uploadInputs: {
    "& .MuiOutlinedInput-input": {
        color: "#2e2e2e"
    },
    "& .MuiInputLabel-root": {
        color: "#2e2e2e"
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#737373"
    },
    "&:hover .MuiOutlinedInput-input": {
        color: "#006778"
    },
    "&:hover .MuiInputLabel-root": {
        color: "#006778"
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderColor: "#006778"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
        color: "#bb4d00",
        backgroundColor: "white"
    },
    "& .MuiInputLabel-root.Mui-focused": {
        color: "#bb4d00"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#bb4d00"
    },
    "& .MuiFormLabel-asterisk": {
        color: "red",
    },
  },
});

const Metadata = ({ metadata, handleInputChange }) => {
  const uploadClasses = useStyleFilter();
  const [formValues, setFormValues] = useState({
    sequencingPlatform: "",
    variantCaller: "",
    reagentKit: "",
  });

  const renderField = ({ key, label, name, required }) => {
    const sequencingPlatform = [
      { key: 0, label: "", value: "" },
      { key: 1, label: "Illumina MiSeqDx", value: "Illumina MiSeqDx" },
      { key: 2, label: "Illumina MiSeq", value: "Illumina MiSeq" },
      { key: 3, label: "Illumina NextSeq", value: "Illumina NextSeq" },
      { key: 4, label: "Illumina HiSeq", value: "Illumina HiSeq" },
      { key: 5, label: "Pacific Biosystems", value: "Pacific Biosystems" },
      { key: 6, label: "Ion GeneStudio S5", value: "Ion GeneStudio S5" },
      { key: 7, label: "Ion Genexus", value: "Ion Genexus" },
      { key: 8, label: "Roche 454", value: "Roche 454" },
      { key: 9, label: "SOLiD", value: "SOLiD" },
      { key: 10, label: "Other", value: "Other" },
    ];

    const variantCaller = [
      { key: 0, label: "", value: "" },
      { key: 1, label: "Illumina-Local Run Manager", value: "illumina-Local Run Manager" },
      { key: 2, label: "Ion Reporter", value: "Ion Reporter" },
      { key: 3, label: "Agilent-SureCall", value: "Agilent-SureCall" },
      { key: 4, label: "SoftGenetics- NextGENe", value: "SoftGenetics- NextGENe" },
      { key: 5, label: "GATK", value: "GATK" },
      { key: 6, label: "FreeBayes", value: "FreeBayes" },
      { key: 7, label: "LoFreq", value: "LoFreq" },
      { key: 8, label: "Sentieon", value: "Sentieon" },
      { key: 9, label: "DeepVariant", value: "DeepVariant" },
      { key: 10, label: "Platypus", value: "Platypus" },
      { key: 11, label: "Samtools/mpileup", value: "Samtools/mpileup" },
      { key: 12, label: "Other", value: "Other" },
    ];

    const reagentKit = [
      { key: 0, label: "", value: "" },
      { key: 1, label: "Illumina TruSight Cystic Fibrosis (139-variant Assay)", value: "Illumina TruSight Cystic Fibrosis (139-variant Assay)" },
      { key: 2, label: "Illumina TruSight Cystic Fibrosis (Clinical Sequencing Assay)", value: "Illumina TruSight Cystic Fibrosis(Clinical Sequencing Assay)" },
      { key: 3, label: "Illumina Custom assay", value: "Illumina Custom assay" },
      { key: 4, label: "SwiftBiosciences CFTR assay", value: "SwiftBiosciences CFTR assay" },
      { key: 5, label: "Swift Biosciences Custom Assay", value: "Swift Biosciences Custom Assay" },
      { key: 6, label: "Agilent-Custom Assay", value: "Agilent-Custom Assay" },
      { key: 7, label: "Ion AmpliSeq CFTR Panel", value: "Ion AmpliSeq CFTR Panel" },
      { key: 8, label: "Ion Custom Panel", value: "Ion Custom Panel" },
      { key: 9, label: "Archer Dx -Custom Panel", value: "Archer Dx -Custom Panel" },
      { key: 10, label: "Other", value: "Other" },
    ];

    const getFieldOptions = (fieldName) => {
      switch (fieldName) {
        case 'sequencingPlatform':
          return sequencingPlatform;
        case 'variantCaller':
          return variantCaller;
        case 'reagentKit':
          return reagentKit;
        default:
          return [];
      }
    };

    return (
      <div key={key}>
        <Grid container spacing={3} className="dashGridContainer">
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <FormControl fullWidth>
              <TextField
                fullWidth
                size="small"
                name={name}
                id={name}
                className={uploadClasses.uploadInputs}
                value={formValues[name]}
                onChange={(e) => {
                  setFormValues({ ...formValues, [e.target.name]: e.target.value });
                  handleInputChange(e);
                }}
                variant="outlined"
                label={label}
                required={required}
                select
                SelectProps={{ MenuProps: { disableScrollLock: true } }}
              >
                {getFieldOptions(name).map(option => (
                  <MenuItem key={option.key} value={option.value}>{option.label}</MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
        </Grid>
      </div>
    );
  };

  return (
    <div className="Metadata">
      <div className="uploadRequiredField">
        <Grid container spacing={3} className="dashGridContainer">
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <span className="requiredMessage">*</span> Indicates a required field.
          </Grid>
        </Grid>
      </div>
      <form>{metadata.map(renderField)}</form>
    </div>
  );
};

export default Metadata;
