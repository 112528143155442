import React from 'react'
import Link from 'react-router-dom/Link';
import { Button, makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faDna, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import LandingSnackbar from './Snackbar';
import DevLoginModal from '../DevLoginModal';

import './index.css';
import SessionConstants from '../../config/SessionConstants';

const useStyles = makeStyles({
    landingLogin: {
        fontSize: '1em',
        textDecoration: 'none',
        backgroundColor: 'var(--secondary-color)',
        color: 'black',
        padding: '0.7em 0.8em',
        textTransform: 'uppercase',
        borderRadius: '5px',
        '&:hover': { // WCAG AAA compliant contrast
            backgroundColor: 'var(--secondary-color)',
            filter: 'brightness(87%)'
        },
    },
});

export default function Landing(props) {
    const classes = useStyles();
    const [devLoginModalIsOpen, setDevLoginModalIsOpen] = React.useState(false);
    const openDevLoginModal = () => setDevLoginModalIsOpen(true);
    const closeDevLoginModal = () => setDevLoginModalIsOpen(false);

    const samsLogin = () => {
        sessionStorage.setItem(SessionConstants.DEVLOGIN, 'false');
        window.location.assign("/login");
    }

    return (
        <>
        <div className='landingContainer' role='main'>
            <div className='landingHeader'>
                <header>
                    <h1><FontAwesomeIcon icon={faDna} size="sm"></FontAwesomeIcon> ED3N</h1>
                </header>
            </div>
            <section className='landingBody'>
                <div className='landingTitle'>
                    <h2>
                        Welcome to ED3N
                    </h2>
                </div>
                <div className='landingDescription'>
                    The ED3N platform, developed by the CDC, aims to promote state-of-the-art detection of disease risk in newborns through enhanced data-driven decision-making tools, correlations, and resources. 
                </div>
                <div className='loginMessage'>
                    <div className='loginInstruction'>
                        <span>Access to ED3N is controlled using SAMS. You must have access to SAMS before accessing ED3N. If you require access to ED3N, please contact</span><a className='landingEmailLink' role='link' aria-label='send email' href="mailto:cdced3nsupport@cdc.gov?Subject=ED3N%20Access"><FontAwesomeIcon icon={faEnvelope} className='landingEmailIcon'></FontAwesomeIcon>CDC ED3N support</a>.
                    </div>
                    {/* <div className='linkWrapper'>
                        <Link className={'landingLoginLink'} to={ '/login' } role={'link'} aria-label={'login using SAMS'}><FontAwesomeIcon icon={faArrowRight} className='landingButtonIcons'></FontAwesomeIcon>Login using SAMS</Link>
                    </div> */}
                    <div className='linkWrapper'>
                        <Button onClick={samsLogin}
                            className={classes.landingLogin}
                            color="primary"
                            variant="contained"
                            aria-label="login using SAMS" >
                            <FontAwesomeIcon icon={faArrowRight} className='landingButtonIcons'></FontAwesomeIcon>Login using SAMS
                        </Button>                    </div>
                    {process.env.NODE_ENV == 'development' ?
                    <div className='linkWrapper'>
                        <Button onClick={openDevLoginModal}
                            color="secondary"
                            variant="contained"
                            aria-label="dev login" >
                            <FontAwesomeIcon icon={faArrowRight} className='landingButtonIcons'></FontAwesomeIcon>Dev Login
                        </Button>
                    </div> : ''}
                </div>
            </section>
            {devLoginModalIsOpen && (
                <DevLoginModal 
                    closeModal={closeDevLoginModal}
                    isModalOpen={devLoginModalIsOpen} 
                />
            )}
            <div className="landingFooter">
                <section>
                    <div className="landingFooterSection">
                        <div className="landingNotice">
                        WARNING: This warning banner provides privacy and security notices 
                        consistent with applicable federal laws, directives, and other 
                        federal guidance for accessing this Government system, which 
                        includes all devices/storage media attached to this system. This 
                        system is provided for Government-authorized use only. Unauthorized 
                        or improper use of this system is prohibited and may result in 
                        disciplinary action and/or civil and criminal penalties. At any 
                        time, and for any lawful Government purpose, the government may 
                        monitor, record, and audit your system usage and/or intercept, 
                        search and seize any communication or data transiting or stored on 
                        this system. Therefore, you have no reasonable expectation of 
                        privacy. Any communication or data transiting or stored on this 
                        system may be disclosed or used for any lawful Government purpose.
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <div className='bkgdLogo'>
            <FontAwesomeIcon icon={faDna} ></FontAwesomeIcon>
        </div>
        <LandingSnackbar />
        </>
    );
    
}