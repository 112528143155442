import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import '../../AddInterpretation/index.css'

const columns = [
  { id: 'category', label: 'Category', minWidth: 170 },
  { id: 'evaluation', label: 'Evaluation', minWidth: 170, align: 'left' },
  { id: 'comment', label: 'Comment', minWidth: 170, align: 'left' },
];

function createData(category,evaluation, comment) {
  return { category, evaluation, comment};
}

const evalColumn = [
  { id: 'classification', label: 'Classification', minWidth: 170 },
  { id: 'comments', label: 'Comment', minWidth: 170, align: 'left' },
];

function createEvalColumnData(classification, comments){
  return {classification, comments}
}


const useStyles = makeStyles({
  root: {
    background: '#006778',
    color: 'white',
    fontFamily: "Open Sans",
  },
  inline:{
    color: 'white'
  },
  paper: {
    width: '100%',
  },
  table: {
    minWidth: 750,
  },
  header: {
    height: '2rem',
  },
  cell: {
    wordBreak: 'break-word',
  }
});

export default function FinalReview(props) {

  const categories = props.categoryValues;
  const programClassification = props.programClassification;
  const comment = props.comments;
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const evalRows = [
    createEvalColumnData(programClassification, comment)
  ]

  const rows = [
    createData('Population Frequency', categories['POPULATION_FREQUENCY'].eval, categories['POPULATION_FREQUENCY'].comments),
    createData('Disease Databases', categories['DISEASE_DATABASES'].eval, categories['DISEASE_DATABASES'].comments),
    createData('Computational Predictive Programs', categories['COMPUTATIONAL_PREDICTIVE_PROGRAMS'].eval, categories['COMPUTATIONAL_PREDICTIVE_PROGRAMS'].comments),
    createData('Nucleotide Conservation', categories['NUCLEOTIDE_CONSERVATION'].eval, categories['NUCLEOTIDE_CONSERVATION'].comments),
  ];

  return (
    <div>
        <Paper>
          <TableContainer>
            <Table className={classes.table}
              size='small'
              aria-label="final review table"
            >
              <TableHead className={classes.header}>
                <TableRow className={classes.root}>
                  {columns.map((column, index) => (
                    <TableCell
                      key={index}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                      className={classes.inline}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                  return (
                    <TableRow hover tabIndex={-1} key={index}>
                      {columns.map((column, index) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={index} align={column.align} className={classes.cell}>
                            {column.format && typeof value === 'number' ? column.format(value) : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <br />
        <hr />
        <h2>Classification Summary</h2>
        <Paper>
          <TableContainer >
            <Table 
              className={classes.table}
              size='small'
              aria-label="classification summary table"
            >
              <TableHead className={classes.root}>
                <TableRow className={classes.root}>
                  {evalColumn.map((column, index) => (
                    <TableCell
                      key={index}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                      className={classes.inline}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
              {evalRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                    return (
                      <TableRow hover tabIndex={-1} key={index}>
                        {evalColumn.map((column, index) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={index} align={column.align}>
                              {column.format && typeof value === 'number' ? column.format(value) : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
    </div>

  );
}