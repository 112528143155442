import React, {useState} from 'react';
import {
  Snackbar,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SessionConstants from '../../config/SessionConstants';

export default function LandingSnackbar() {
  const [logoutOpen, setLogoutOpen] = useState(JSON.parse(sessionStorage.getItem(SessionConstants.LOGOUT_STATUS)));
  const [timeoutOpen, setTimeoutOpen] = useState(JSON.parse(sessionStorage.getItem(SessionConstants.TIMEOUT_STATUS)));

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    // user clicked close icon, clear the logout/timeout status to prevent message from showing again
    // only show this message once
    sessionStorage.setItem(SessionConstants.LOGOUT_STATUS, false);
    sessionStorage.setItem(SessionConstants.TIMEOUT_STATUS, false);
    setLogoutOpen(false);  
    setTimeoutOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    //Open either logout or timeout snackbar message
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={logoutOpen ? logoutOpen : timeoutOpen}
        //autoHideDuration={10000}
        onClose={handleClose}
        message={sessionStorage.getItem(SessionConstants.ERROR_DESCRIPTION)}
        action={action}
      />
    </div>
  );
}
