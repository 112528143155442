import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {Helmet} from "react-helmet";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import SessionConstants from '../../config/SessionConstants.js';
import BreadCrumbs from '../BreadCrumbs';
import CaseTable from '../CaseTable/index.js';
import ProgramVariantsTable from './ProgramVariantsTable/index.js';
import ProgramSettingsModal from './ProgramSettingsModal/index.js';
import APIUtils from '../../utils/APIUtils';
import Loading from '../Loading';
import './index.css';

export default class ProgramDashboard extends Component {

  state = {
    programId:'',
    breadCrumbs: [],
    interpretationsData: [],
    programInfo: null,
    progSettingsModalIsOpen: false,
    casesData:[],
    loading:true,
  }

    async componentDidMount() {
        console.log('--ProgramDashboard.componentDidMount()');
    
        var programId = sessionStorage.getItem(SessionConstants.PROGRAM_ID);
        //Load Data
        var variantResponse = await APIUtils.getVariantsByProgramId(programId);
        var caseResponse = await APIUtils.getCasesByProgramId(programId);

        this.setState({
            programId: programId,
            interpretationsData: variantResponse,
            casesData: caseResponse,
            breadCrumbs: [{
            name: 'ProgramDashboard',
            }],
            loading:false,
        });
    }

  openModal = () => {
    this.setState({ progSettingsModalIsOpen: true });
  };
  
  closeModal = () => {
    this.setState({
      progSettingsModalIsOpen: false,
    });
  };

  render() {

    return (
        <div className="managerSettings">
          <Loading isLoading={this.state.loading}/>
          <Helmet>
            <title>ED3N - Program Dashboard</title>
          </Helmet>
          <BreadCrumbs breadCrumbs={this.state.breadCrumbs} />
          <h1 className="pageTitle">Program Dashboard</h1>
          <div className="managerButtonBar">
              <div className="managerLeftName">
                  NBS Program: {this.state.programId}
              </div>
              <div className="managerButtons">
                  <button className="actionButton settingsButton" onClick={this.openModal}>
                      <FontAwesomeIcon icon={faCog} />Program Settings
                  </button>
              </div>
          </div>
          <Tabs className="tabs" selectedTabClassName="active">
              <TabList className="tabList">
                <Tab className="tab">Identified Variants</Tab>
                <Tab className="tab">Cases for Review</Tab>
              </TabList>
              <TabPanel className="tabPanel">
                  { this.state.interpretationsData.length ? (
                  <ProgramVariantsTable
                      data={this.state.interpretationsData}
                      programId={this.state.programId}
                      breadCrumbs={this.state.breadCrumbs}
                  />
                  ) : (
                      'No Variant Data'
                  )
                  }
              </TabPanel>
              <TabPanel className="tabPanel">
                 { this.state.casesData.length > 0 ? (
                    <div>
                  <CaseTable
                  data={this.state.casesData}
                  programId={this.state.programId}
                  breadCrumbs={this.state.breadCrumbs}
              />
              </div>
                  ) : (
                      'No Case Data'
                  )
                  }
              </TabPanel>
           </Tabs>
          <ProgramSettingsModal
              closeModal={this.closeModal}
              modalIsOpen={this.state.progSettingsModalIsOpen}
              
          /> 
        </div>

    );
  }
}
