import SessionConstants from '../config/SessionConstants.js';
import APIUtils from './APIUtils.js';


const Oauth = {

    //  Main function for checking if the current login is valid.
    //  This function will be called by every page component and must return true
    //      for a page to be loaded.
    async isLoginValid() {
        console.log('--Oauth.isLoginValid()');
        console.log('session storage dev login = ' + sessionStorage.getItem(SessionConstants.DEVLOGIN));
        console.log('dev environment = ' + (process.env.NODE_ENV == 'development'));
        //  If we're using the local dev login, check for a valid ED3N token
        if (this.isLocalDevLogin()) {
            console.log('Local dev login detected');
            if (this.isEd3nTokenValid()) {
                console.log('ED3N token is valid');
                return true;
            } else {
                console.log('ED3N token is no longer valid');
                this.setLoginAsExpired();
                return false;
            }
        }
        //  If we're not using local dev login, check for valid SAMS token
        else {
            console.log('SAMS login detected');
            if (this.samsTokenExists()) {
                if (this.isSamsTokenValid()) {
                    console.log('SAMS token is valid');
                    return true;
                } else {
                    console.log('SAMS token is no longer valid');
                    this.setLoginAsExpired();
                    return false;
                }
            } else {
                console.log('No valid SAMS token detected');
                return false;
            }
        }
    },


    //  isLocalDevLogin()
    //  Returns true if local dev login is being used
    isLocalDevLogin() {
        if ((sessionStorage.getItem(SessionConstants.DEVLOGIN) == 'true') && (process.env.NODE_ENV == 'development')) {
            return true;
        } else {
            return false;
        }
    },

    //  samsTokenExists()
    //  Returns true if a SAMS token exists in the session
    samsTokenExists() {
        console.log('--Oauth.samsTokenExists()');
        let samsToken = sessionStorage.getItem(SessionConstants.SAMS_ACCESS_TOKEN);
        return ((samsToken) && (samsToken != 'undefined')) 
    },

    //  isSamsTokenValid()
    //  Checks if the current SAMS token is valid and unexpired
    async isSamsTokenValid() {
        console.log('--Oauth.isSamsTokenValid()');
        try {
            let token = sessionStorage.getItem(SessionConstants.SAMS_ACCESS_TOKEN);
            //  run the validate endpoint
            var response = await APIUtils.validateSamsToken(token);
            //  if we get a response, then the token is valid.
            return (response == true);
        } catch (error) {
            console.log('***ERRORS RETURNED***')
            console.error('Error: ', error)
            sessionStorage.setItem(SessionConstants.ERROR_DESCRIPTION, error);
        }
    },

    //  isEd3nTokenValid()
    //  Checks if the current ED3N token is valid and unexpired
    async isEd3nTokenValid() {
        console.log('--Oauth.isEd3nTokenValid()');
        try {
            let token = sessionStorage.getItem(SessionConstants.ED3N_ACCESS_TOKEN);
            //  run the validate endpoint
            var response = await APIUtils.validateEd3nToken(token);
            //  if the response has an error element, then the token is expired.
            if (response.error == true) {
                return false;
            } else {
                return true;
            }
        } catch (error) {
            console.log('***ERRORS RETURNED***')
            console.error('Error: ', error)
            sessionStorage.setItem(SessionConstants.ERROR_DESCRIPTION, error);
        }
    },

    //  setLoginAsValid()
    //  Sets session variables as required to indicate a valid login
    setLoginAsValid() {
        sessionStorage.setItem(SessionConstants.LOGOUT_STATUS, false);
        sessionStorage.setItem(SessionConstants.TIMEOUT_STATUS, false);
    },

    //  setLoginAsExpired()
    //  Sets session variables as required to indicate an expired login
    setLoginAsExpired() {
        sessionStorage.setItem(SessionConstants.LOGOUT_STATUS, true);
        sessionStorage.setItem(SessionConstants.TIMEOUT_STATUS, true);
        sessionStorage.setItem(SessionConstants.ERROR_DESCRIPTION, 'Your login for ED3N has expired. Please log in again to continue using ED3N.');
    },

    //  getSamsToken(code)
    //  Sets the SAMS tokens using an access code received from SAMS
    async getSamsTokens(code) {
        console.log('--Oauth.getSamsTokens()');
        try {
            var tokens = await APIUtils.getSamsTokens(code);
            var accessToken = tokens.accessToken;
            var refreshToken = tokens.refreshToken;
        
            if ((accessToken) && (accessToken != 'undefined')) {
                console.log('Successfully authenticated with SAMS');
                sessionStorage.setItem(SessionConstants.SAMS_ACCESS_TOKEN, accessToken);
                sessionStorage.setItem(SessionConstants.SAMS_REFRESH_TOKEN, refreshToken);
            } else {
                console.log('Unable to obtain a SAMS access token');
                sessionStorage.setItem(SessionConstants.ERROR_DESCRIPTION, 'Unable to authenticate with SAMS');
                this.clearAllSessionVariables();
            }
        } catch (error) {
            console.log('***ERRORS RETURNED***')
            console.error('Error: ', error)
            sessionStorage.setItem(SessionConstants.ERROR_DESCRIPTION, error);
        }
    },

    //  getEd3nTokensUsingSamsToken()
    //  Sets the ED3N tokens using user data pulled using the SAMS token
    async getEd3nTokensUsingSamsToken() {
        console.log('--Oauth.getEd3nTokensUsingSamsToken()');
        try {
            var samsToken = sessionStorage.getItem(SessionConstants.SAMS_ACCESS_TOKEN);
            var samsUserInfo = await APIUtils.getSamsUserInfo(samsToken);
            let usr = samsUserInfo.email;
            let pwd = samsUserInfo.accountId + '#' + samsUserInfo.lastName.toUpperCase();
            await this.getEd3nTokens(usr, pwd);
        } catch (error) {
            console.log('***ERRORS RETURNED***')
            console.error('Error: ', error)
            sessionStorage.setItem(SessionConstants.ERROR_DESCRIPTION, error);
        }
    },

    //  getEd3nTokens()
    //  Sets the ED3N tokens
    async getEd3nTokens(usr, pwd) {
        console.log('--Oauth.getEd3nTokens()');
        try {
            var tokens = await APIUtils.getEd3nTokens(usr, pwd);
            var accessToken = tokens.accessToken;
            var refreshToken = tokens.refreshToken;
            
            if ((accessToken) && (accessToken != 'undefined')) {
                console.log('Successfully authenticated with ED3N');
                sessionStorage.setItem(SessionConstants.ED3N_ACCESS_TOKEN, accessToken);
                sessionStorage.setItem(SessionConstants.ED3N_REFRESH_TOKEN, refreshToken);
            } else {
                console.log('Unable to obtain a ED3N access token');
                sessionStorage.setItem(SessionConstants.ERROR_DESCRIPTION, 'Unable to authenticate with ED3N');
                this.clearAllSessionVariables();
            }
        } catch (error) {
            console.log('***ERRORS RETURNED***')
            console.error('Error: ', error)
            sessionStorage.setItem(SessionConstants.ERROR_DESCRIPTION, error);
        }
    },

    //  loadUserApiInfo()
    //  Sets the user information based on info from the ED3N user info endpoint
    async loadUserApiInfo() {
        console.log('--Oauth.loadUserApiInfo()');
        try {
            var userInfo = await APIUtils.getEd3nUserInfo();
            if (userInfo) {
                sessionStorage.setItem(SessionConstants.PROGRAM_ID, userInfo.nbsPrograms[0].shortName);
                sessionStorage.setItem(SessionConstants.USER_EMAIL, userInfo.email);
                sessionStorage.setItem(SessionConstants.USER_FIRSTNAME, userInfo.firstName);
                sessionStorage.setItem(SessionConstants.USER_LASTNAME, userInfo.lastName);
                sessionStorage.setItem(SessionConstants.NBS_PROGRAM, JSON.stringify(userInfo.nbsPrograms));
                (userInfo.roles.includes('PROGRAMADMIN') || userInfo.roles.includes('SYSADMIN')) ? 
                    sessionStorage.setItem(SessionConstants.IS_MANAGER, true) :
                    sessionStorage.setItem(SessionConstants.IS_MANAGER, false);
            } else {
                console.error('Unable to retrieve user role information from endpoint.')
                sessionStorage.setItem(SessionConstants.ERROR_MESSAGE, 'Could not login to ED3N');
                sessionStorage.setItem(SessionConstants.ERROR_DESCRIPTION, 'Unable to retrieve user information');
                this.clearAllSessionVariables();
            }
        } catch (error) {
            console.log('***ERRORS RETURNED***')
            console.error('Error: ', error)
            sessionStorage.setItem(SessionConstants.ERROR_DESCRIPTION, error);
        }
    },

    //  refreshAllTokens()
    //  Triggers refresh of SAMS and ED3N tokens
    async refreshAllTokens() {
        if (this.isLocalDevLogin()) {
            await this.refreshEd3nToken();
        } else {
            await this.refreshSamsToken();
            await this.refreshEd3nToken();
        }
    },

    async refreshSamsToken() {
        try {
            let token = sessionStorage.getItem(SessionConstants.SAMS_REFRESH_TOKEN);
            var tokens = await APIUtils.refreshSamsTokens(token);
            var accessToken = tokens.accessToken;
            var refreshToken = tokens.refreshToken;

            //  Did the refresh succeed? It did if we have an access token
            if ((accessToken) && (accessToken != 'undefined')) {
                console.log('Successfully refreshed SAMS tokens.');
                sessionStorage.setItem(SessionConstants.SAMS_ACCESS_TOKEN, accessToken);
                sessionStorage.setItem(SessionConstants.SAMS_REFRESH_TOKEN, refreshToken);
            } else {
            //  if we have a problem getting the token back, then send an alert and clear storage. This will happen
            //    if the app timed out, thus making the access and refresh tokens expire. Clearing storage is REQUIRED
            //    so that the tokens can't be used again. 
                sessionStorage.setItem(SessionConstants.ERROR_DESCRIPTION, 'Unable to refresh SAMS authentication');
                this.clearAllSessionVariables();
            }
        } catch (error) {
            console.log('***ERRORS RETURNED***')
            console.error('Error: ', error)
            sessionStorage.setItem(SessionConstants.ERROR_DESCRIPTION, error);
        }
    },

    async refreshEd3nToken() {
        try {
            let token = sessionStorage.getItem(SessionConstants.ED3N_REFRESH_TOKEN);
            var tokens = await APIUtils.refreshEd3nTokens(token);

            var accessToken = tokens.accessToken;
            var refreshToken = tokens.refreshToken;

            //  Did the refresh succeed? It did if we have an access token
            if ((accessToken) && (accessToken != 'undefined')) {
                console.log('Successfully refreshed ED3N tokens.');
                sessionStorage.setItem(SessionConstants.ED3N_ACCESS_TOKEN, accessToken);
                sessionStorage.setItem(SessionConstants.ED3N_REFRESH_TOKEN, refreshToken);
            } else {
                //  if we have a problem getting the token back, then send an alert and clear storage. This will happen
                //    if the app timed out, thus making the access and refresh tokens expire. Clearing storage is REQUIRED
                //    so that the tokens can't be used again. 
                sessionStorage.setItem(SessionConstants.ERROR_DESCRIPTION, 'Unable to refresh ED3N authentication');
                this.clearAllSessionVariables();
            }
        } catch (error) {
            console.log('***ERRORS RETURNED***')
            console.error('Error: ', error)
            sessionStorage.setItem(SessionConstants.ERROR_DESCRIPTION, error);
        }
    },

    //  clearAllTokens()
    //  clear all token session variables
    clearAllSessionVariables() {
        sessionStorage.removeItem(SessionConstants.SAMS_ACCESS_TOKEN);
        sessionStorage.removeItem(SessionConstants.SAMS_REFRESH_TOKEN);
        sessionStorage.removeItem(SessionConstants.ED3N_ACCESS_TOKEN);
        sessionStorage.removeItem(SessionConstants.ED3N_REFRESH_TOKEN);
        sessionStorage.removeItem(SessionConstants.PROGRAM_ID);
        sessionStorage.removeItem(SessionConstants.USER_EMAIL);
        sessionStorage.removeItem(SessionConstants.USER_FIRSTNAME);
        sessionStorage.removeItem(SessionConstants.USER_LASTNAME);
        sessionStorage.removeItem(SessionConstants.NBS_PROGRAM);
        sessionStorage.removeItem(SessionConstants.IS_MANAGER);
        sessionStorage.removeItem(SessionConstants.DEVLOGIN);
    },
};

export default Oauth;
