import React, { Component } from 'react';
import Modal from 'react-modal';
import Version from '../../../package.json'

import './index.css'

export default class AboutModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
       
    };
  }

  onClose = () => {
    this.props.closeModal();
  };

  returnFocus = () => {
    document.getElementById("userMenuButton").focus();
  }

  render() {
    return (
      <div>
        <Modal
          className="modal"
          overlayClassName="modalOverlay"
          aria-modal="true"
          contentLabel="About"
          shouldCloseOnOverlayClick={true}
          isOpen={this.props.modalIsOpen}
          onRequestClose={this.onClose}
          onAfterClose={this.returnFocus}
        >
          <div className="modalHeader">About ED3N</div>
          <div className="modalContent center">
            <div className="modalSection">
              ED3N Version: {Version.version}
            </div>
            <div className="modalSection">
              All variant annotation data provided in the ED3N molecular module is provided by Nirvana, and variant
              annotation aggregation tool published and maintained by Illumina. Usage of this tool and its associated data
              in ED3N for the purposes of molecular variant interpretation is permitted per General Public License (GPL) V3. 
            </div>
            <div>
              For more information on the GPL v3, please view the license text&nbsp;
              <a href="https://github.com/Illumina/Nirvana/blob/develop/LICENSE">here</a>.
            </div>
          </div>
          <div className="modalButtons">
            <button className="modalButton" onClick={this.onClose}>
              Close
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}
