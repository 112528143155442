export const PATHOGENIC_VERY_STRONG = 'Pathogenic Very Strong';
export const PATHOGENIC_STRONG = 'Pathogenic Strong';
export const PATHOGENIC_MODERATE = 'Pathogenic Moderate';
export const PATHOGENIC_SUPPORTING = 'Pathogenic Supporting';
export const BENIGN_STAND_ALONE = 'Benign Stand Alone';
export const BENIGN_STRONG = 'Benign Strong';
export const BENIGN_SUPPORTING = 'Benign Supporting';
export const NOT_APPLICABLE = 'Not Applicable';

export const CLASSIFICATION_LIST = [
    PATHOGENIC_VERY_STRONG,
    PATHOGENIC_STRONG,
    PATHOGENIC_MODERATE,
    PATHOGENIC_SUPPORTING,
    BENIGN_STAND_ALONE,
    BENIGN_STRONG,
    BENIGN_SUPPORTING,
    NOT_APPLICABLE
]

//TODO: CHECK, THIS MAY NO LONGER BE USED