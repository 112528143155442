const FilterNameConfig = {
  programId: "Program",
  sequencingPlatform: "Platform",
  variantCaller: "Variant Caller",
  reagentKit: "Reagent Kit",
  status: "Status",
  uploadedBy: "Uploaded By",
  sequencingPlatform: "Platform",
  processStartDate: "Date Uploaded",
  fileName: "File Name"
};

export default FilterNameConfig;