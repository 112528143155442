import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import AuthenticatedRoute from './AuthenticatedRoute';
import Home from './Home';
import Error from './Error';
import OAuthCallback from './OAuthCallback';
import UserDashboard from './UserDashboard';
import ProgramSummary from './ProgramSummary';
import CaseDetails from './CaseDetails';
import VariantDetails from './VariantDetails';
import AddInterpretation from './AddInterpretation';
import VariantConsensus from './VariantConsensus';
import ProgramDashboard from './ProgramDashboard';
import VariantDashboard from './VariantDashboard';
import DataUploads from './DataUploads/index.js';
import Landing from './Landing';
import Help from './Help';

import Paths from '../config/Paths.js';

const RouterComponent = () => {
    return (
        <Router>
            <Switch>
                <AuthenticatedRoute exact path="/home" component={Home} />
                <AuthenticatedRoute exact path="/programsummary" component={ProgramSummary} />
                <AuthenticatedRoute exact path="/casedetails/:caseId" component={CaseDetails} />
                <AuthenticatedRoute exact path="/variantdetails/:variantId" component={VariantDetails} />
                <AuthenticatedRoute exact path="/variantconsensus/:variantId" component={VariantConsensus} />
                <AuthenticatedRoute exact path="/addinterpretation" component={AddInterpretation} />
                <AuthenticatedRoute exact path="/userdashboard" component={UserDashboard} />
                <AuthenticatedRoute exact path="/datauploads" component={DataUploads} />
                <AuthenticatedRoute exact path="/programdashboard" component={ProgramDashboard} />
                <AuthenticatedRoute exact path="/variantdashboard/:variantId" component={VariantDashboard} />
                <AuthenticatedRoute exact path="/help" component={Help} />
                <Route exact path="/login" component={() => { 
                    window.location.href = Paths.samsAuth;
                    return null;  
                    }} />
                <Route exact path="/oauth-callback" component={OAuthCallback} />
                <Route exact path="/error" component={Error} />
                <Route exact path="/landing" component={Landing} />
                <Redirect from="/" to="/home" />
            </Switch>
        </Router>
    );
};

export default RouterComponent;