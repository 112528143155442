import React from 'react';
import PropTypes from 'prop-types';

function Button({text,onClick,className}) {
  return (
    <button className={className} onClick={onClick}>
        {text}
     </button>  
    );
}

  Button.defaultProps = {
    text: "Submit",
    className: "primaryButton"
  };
  // Button.propTypes = {
  //   onClick: PropTypes.element.isRequired,
  // }
  export default Button;