import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import Oauth from '../../utils/Oauth.js';
import SessionConstants from '../../config/SessionConstants.js';
import Loading from '../Loading';

export default class OAuthCallback extends Component {

    state = {
        loading: true,
        hasToken: false,
    };

    async componentDidMount() {
        //  search for the code querystring item. If it's there, we know this came from SAMS. Do a SAMS login
        //  if we find a devlogin flag, then do a local dev login
        //  if we find neither, not sure how we got here. Get outta here!
        const code = (location.search.match(/code=([^&]+)/) || [])[1];
        console.log("SAMS code = " + code);

        let hasToken;

        if (code != undefined) {
            await Oauth.getSamsTokens(code);
            if (sessionStorage.getItem(SessionConstants.SAMS_ACCESS_TOKEN) != null) {
                await Oauth.getEd3nTokensUsingSamsToken();
                if (sessionStorage.getItem(SessionConstants.ED3N_ACCESS_TOKEN) != null) {
                    await Oauth.loadUserApiInfo();
                    if (sessionStorage.getItem(SessionConstants.PROGRAM_ID) != null) {
                        hasToken = true;
                        sessionStorage.setItem(SessionConstants.LOGOUT_STATUS, false);
                    }
                }
            } else {
                sessionStorage.setItem(SessionConstants.ERROR_MESSAGE, 'Could not login to ED3N');
                hasToken = false;
            }
        } else if (Oauth.isLocalDevLogin) {
            const devloginusr = (location.search.match(/usr=([^&]+)/) || [])[1];
            const devloginpwd = (location.search.match(/pwd=([^&]+)/) || [])[1].replace('$', '#');
            console.log("Dev Login USR = " + devloginusr);
            console.log("Dev Login PWD = " + devloginpwd);
            await Oauth.getEd3nTokens(devloginusr, devloginpwd);
            if (sessionStorage.getItem(SessionConstants.ED3N_ACCESS_TOKEN) != null) {
                await Oauth.loadUserApiInfo();
                if (sessionStorage.getItem(SessionConstants.PROGRAM_ID) != null) {
                    hasToken = true;
                    sessionStorage.setItem(SessionConstants.LOGOUT_STATUS, false);
                }
            }
        } else {
            sessionStorage.setItem(SessionConstants.ERROR_MESSAGE, 'No SAMS or DEV login detected.');
            hasToken = false;
        }

        this.setState({
            loading: false,
            hasToken: hasToken,
        });
    }

    render() {
        return (
        <div>
            <Loading isLoading={this.state.loading}/>
            {this.state.loading ? ('Processing login...') : (
                this.state.hasToken ? (<Redirect to='/home' />) : 
                (
                    <Redirect to={{ pathname: "error" }} />
                )
            )}
        </div>
        )
    }
}
