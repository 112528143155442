import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import ReactTable from 'react-table';
import matchSorter from 'match-sorter';
import moment from 'moment';

import APIUtils from '../../utils/APIUtils';
import { convertUTCDateToLocalDate } from '../../utils/DateUtils';

export default class ConsensusReviews extends Component {
  constructor() {
    super();
    this.state = {
      programId: null,
      interpretations: [],
      interpretationsExist: false,
      startDate: null,
      filtered: [],
      loading: false,
      errorMsg: '',
    };
  }

  componentDidMount() {
    //console.log('---Interpretations.componentDidMount()');
  }

  //props.variantId in the initial render is undefined
  //get the interpretations for the given variantId after the component updates
  componentDidUpdate(prevProps) {
    //console.log('---Interpretations.componentDidUpdate()');
    
    var variantId = this.props.variantId;

    if (variantId !== prevProps.variantId) {
      console.log('getting interpretations again')
      this.getConsensusReviews(variantId);
    }
  }

    //  Pull consensus reviews done for the variant by all programs
    async getConsensusReviews(variantId) {
        console.log('---getConsensusReviews()')

        this.setState({ loading: true });

        var response = await APIUtils.getFinalVariantInterpretations(variantId);

        if (response) {
            var dataForUi = [];
            var interpretationsList = response.variantInterpretations;
            for (let programId in interpretationsList) {
                var myInterpretation = {};
                myInterpretation.programId = programId;
                myInterpretation.interpretedDate = interpretationsList[programId].interpretedDate;
                myInterpretation.programClassification = interpretationsList[programId].programClassification;
                myInterpretation.comment = interpretationsList[programId].comment;
                myInterpretation.partial = interpretationsList[programId].partial;
                dataForUi.push(myInterpretation);
            }
            if (dataForUi.length) {
                console.info('there were results')
                this.setState({
                    interpretations: dataForUi,
                    interpretationsExist: dataForUi[0].partial == false,
                    loading: false,
                    errorMsg: '',
                });
            } else {
                console.info('nothing found')
                this.setState({
                    interpretationsExist: false,
                    loading: false,
                    errorMsg: 'No interpretations found',
                });
            }
        } else {
            this.setState({
                loading: false,
                errorMsg: 'There was an error retriving the data.',
                interpretationsExist: false,
            });
        }
    }

    interpretedDateCell = (rowInfo) => {
        return convertUTCDateToLocalDate(rowInfo.original.interpretedDate);
    }

  render() {
    const columns = [
      {
        Header: 'Program',
        accessor: 'programId',
        style: { 'whiteSpace': 'unset' },
        maxWidth: 100
      },
      {
        Header: 'Date',
        accessor: 'interpretedDate',
          maxWidth: 190,
        Cell: this.interpretedDateCell,
        filterMethod: (filter, row) => {
          if (
            moment(row[filter.id]).isSame(filter.value) ||
            this.state.startDate === null
          ) {
            return true;
          } else {
            return false;
          }
        },
        Filter: ({ filter, onChange }) => (
          <DatePicker
            className='datepicker-column-100'
            selected={this.state.startDate}
            onChange={date => {
              this.setState({ startDate: interpretedDate });
              onChange(date);
            }}
            todayButton={'Today'}
            placeholderText="Click to select a date"
          />
        ),
      },
      {
        Header: 'NBS Program Classification',
        accessor: 'programClassification',
        style: { 'whiteSpace': 'unset' },
        maxWidth: 220,
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ['programClassification'], }),
        filterAll: true,
      },
      {
        Header: 'Comments',
        accessor: 'comment',
        filterable: false,
        filterMethod: (filter, rows) =>
            matchSorter(rows, filter.value, { keys: ['comment'], }),
        filterAll: true,
      },
    ];

    return (
      <div>
        {this.state.interpretationsExist ? (
          <div>
            <ReactTable
              data={this.state.interpretations}
              showPagination={false}
              minRows={1}
              defaultSorted={[
                {
                  id: 'date',
                  desc: true,
                },
              ]}
              noDataText={null}
              // filterable
              columns={columns}
              defaultPageSize={5}
              getTheadFilterThProps={() => {
                return { style: { position: 'inherit', overflow: 'inherit' } };
              }}
              getTdProps={() => {
                return { style: { whiteSpace: 'unset' } };
              }}
              filtered={this.state.filtered}
              onFilteredChange={filtered => this.setState({ filtered })}
              //Make table headers focusable and add keypress for sorting for 508 accessibility
              getTheadThProps={(state, rowInfo, column, instance) => {
                return {
                  tabIndex: 0,
                  onKeyPress: (e, handleOriginal) => {
                    if(e.which === 13 || e.which === 32) {
                      instance.sortColumn(column);
                    }
                  }
                };
              }}
            />
          </div>
        ) : (
          <div className="headerText">
            There are no final consensus reviews for this variant yet
          </div>
        )}
      </div>
    );
  }
}

