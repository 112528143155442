import React, { Component } from 'react';
import Modal from 'react-modal';
import PropTypes from "prop-types"
import './index.css'


//////////////////////////////////////////////////////
// Reusable component for simple success message
/////////////////////////////////////////////////////
export default function SuccessMessageModal(props) {
  // console.log(props.message)
    return (
      <div>
        <Modal
          className="modal"
          overlayClassName="modalOverlay"
          aria-modal="true"
          contentLabel="Success"
          shouldCloseOnOverlayClick={true}
          isOpen={props.modalIsOpen}
          onRequestClose={props.closeModal}
        >
          <div className="modalHeader">Transaction completed</div>
          <div className="modalContent center">
            <ul>
            {props.message.map((item,i)=><li key={i}>{item}</li>)}
            </ul>
          </div>
          <div className="modalButtons">
            <button className="modalButton" onClick={props.closeModal}>
              OK
            </button>
          </div>
        </Modal>
      </div>
    );
  }

SuccessMessageModal.prototype={
    closeModal:PropTypes.func,
    modalIsOpen:PropTypes.bool,
    message:PropTypes.array
}