import React from 'react';
import Modal from 'react-modal';
import Button from './Button';

// Confirmation Modal
// Example:
{/* 
<ConfirmationModal 
title={this.state.modalTitle}
message={this.state.modalMessage}
onCancel={this.onCancel}
onConfirmation={this.confirmAction}
onClose={this.onCancel}
isOpen={this.state.isOpen}/> 
*/}

const ConfirmationModal = (props) => {  
  return (
      <div>
        <Modal
          className="modal"
          overlayClassName="modalOverlay"
          aria-modal="true"
          contentLabel="Confirmation"
          shouldCloseOnOverlayClick={true}
          isOpen={props.isOpen}
          onRequestClose={props.onCancel}
          onCancel={props.onCancel}
        >
          <div className="modalHeader">{props.title}</div>
          <div className="modalContent center">
            <div>
              {props.message}
            </div>
          </div>
          <div className="modalButtons">
            <Button className="modalButton primaryButton" onClick={props.onConfirmation} text={props.confirmText} />
            <Button className="modalButton" onClick={props.onCancel} text={props.cancelText}/>
          </div>
        </Modal>
      </div>
    );
}

  ConfirmationModal.defaultProps = {
    title: "Alert",
    message: "Are you sure?",
    confirmText: "Yes",
    cancelText: "No"
  };

  export default ConfirmationModal;