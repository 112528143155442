import React, { Component } from 'react';
import Modal from 'react-modal';
import Combobox from "react-widgets/Combobox";
import { TextField, MenuItem } from '@material-ui/core';
import "react-widgets/styles.css";
import './index.css'

import APIUtils from '../../../utils/APIUtils'
import SessionConstants from '../../../config/SessionConstants';
import SuccessMessageModal from '../../SuccessMessageModal';

export default class ProgramSettingsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      programId: '',
      reminderFrequency: '',
      initialInterpretationCount: 3,
      defaultInterpretationCount: 3,
      reviewThreshold: '',
      initialInterpreters:[],
      defaultInterpreters:[],
      availableUsers:[],
      errorMessage:[],
      successMessageModalIsOpen:false,
      successMessage:["Program setting has been saved successfully."]
    };
  }

    async componentDidMount()  {
        var programId = sessionStorage.getItem(SessionConstants.PROGRAM_ID);
        var response = await APIUtils.getProgramSettings(programId);
        var userResponse = await APIUtils.getProgramUsers(programId);
        userResponse.forEach(user => {
            user.fullName= user.firstName + ' ' + user.lastName;
        })
        let initialInterpreters = response.defaultInterpreters;
        let defaultInterpreters = response.defaultInterpreters;
        for (var i = defaultInterpreters.length; i < response.defaultInterpretationCount; i++) {
            defaultInterpreters[i] = '';
            initialInterpreters[i] = '';
        }
        this.setState({
            programId: response.shortName,
            reminderFrequency: response.reminderFrequency,
            reviewThreshold: response.reviewThreshold,
            initialInterpretationCount: response.defaultInterpretationCount,
            defaultInterpretationCount: response.defaultInterpretationCount,
            initialInterpreters: initialInterpreters,
            defaultInterpreters: defaultInterpreters,
            availableUsers: userResponse,
        });
    }

    onClose = () => {
        let initInterps = this.state.initialInterpreters;
        let initCount = this.state.initialInterpretationCount;
        this.setState({
            defaultInterpretationCount: initCount,
            errorMessage: [],
            defaultInterpreters: initInterps,
        });
        this.props.closeModal();
    };

    openSuccessMessageModal = () => {
        this.setState({
            errorMessage: [],
            successMessageModalIsOpen: true
        });
    };
  
    closeSuccessMessageModal = () => {
        this.setState({
            successMessageModalIsOpen: false,
        });
    };

    //////////////////////////////////////////
    ///SAVE SETTING
    //////////////////////////////////////////
    onSubmit = () => {
        const { reminderFrequency, defaultInterpretationCount,defaultInterpreters,reviewThreshold } = this.state;
        let visibleUser = []
        for (var i = 0; i < defaultInterpretationCount; i++) {  
            if ((defaultInterpreters[i] != undefined) && (defaultInterpreters[i] != ""))
                visibleUser.push(defaultInterpreters[i]);
        }

        if (this.state.errorMessage.length == 0) {
            if (defaultInterpreters.length > defaultInterpretationCount)
                defaultInterpreters.splice(defaultInterpretationCount, defaultInterpreters.length - defaultInterpretationCount)

            let formData = new FormData();
            formData.append('reminderFrequency', reminderFrequency);
            formData.append('reviewThreshold',reviewThreshold);
            formData.append('defaultInterpretationCount', defaultInterpretationCount);
            formData.append('defaultInterpreters', visibleUser);
            this.saveSetting(formData);
            this.setState({
                initialInterpreters: defaultInterpreters,
                initialInterpretationCount: defaultInterpretationCount
            });
        }
    }
    async saveSetting(formData) {
        let response = await APIUtils.updateProgramSettings(this.state.programId, formData);
        this.onClose(); //close program setting modal
        this.setState({successMessageModalIsOpen: true})
    }

    setDefaultInterpreters = (index, selectedUser, defaultInterpretationCount) => {   
        let defaultInterpreters = [...this.state.defaultInterpreters]
        defaultInterpreters[index] = selectedUser.email;
        let errorMessage = [];
        let visibleUser = []
        for (var i = 0; i < defaultInterpretationCount; i++) {  
            if ((defaultInterpreters[i] != undefined) && (defaultInterpreters[i] != ""))
                visibleUser.push(defaultInterpreters[i]);
        }
   
        //check for duplicates, but don't count blanks as duplicates
        var unique = Array.from(new Set(visibleUser));
        var unique2 = unique.filter(function (value, index1, arr) {
            return value != "";
        });

        if (visibleUser.length != unique2.length)
            errorMessage.push("Please remove duplicate interpreters.")

        this.setState({
            errorMessage: errorMessage,
            defaultInterpreters: defaultInterpreters
        });
    }

    setDefaultInterpretationCount = (e) => {
        this.setState({defaultInterpretationCount: e.target.value})
        let defaultInterpreters= [...this.state.defaultInterpreters]
        if (e.target.value >= defaultInterpreters.length){
        //ensure array size insync with any change to defaultInterpretationCount
        for(var i=defaultInterpreters.length; i<e.target.value;i++ ){
            defaultInterpreters.push('');
        }
            this.setState({defaultInterpreters:defaultInterpreters })
        }
    }
   
  getDefaultUsers=(i)=>{
    try{
      //find user by email
      const user = this.state.availableUsers.filter(user=>user.email == this.state.defaultInterpreters[i]);
      return user[0].fullName;
    }
    catch{
      return ""
    }
  }


  render() {
    const DAILY = "Daily";
    const WEEKLY = "Weekly";
    const combobox=[];
    for(var i=0; i< this.state.defaultInterpretationCount; i++){
      const defaultUser = this.getDefaultUsers(i)
      const index = i;//need to save this
      combobox.push(
        <div key={i} className="comboBox interpreterSettingsCombo">
          <label className='interpreterLabel' htmlFor={'interpreter_' + (i+1).toString() + '_input'} >{'Interpreter ' + (i+1)}</label>
          <Combobox    
            className="interpreterSelect"
            data={this.state.availableUsers}
            dataKey='email'
            textField='fullName'
            defaultValue={defaultUser}
            onChange={key=>this.setDefaultInterpreters(index,key,this.state.defaultInterpretationCount)}
            filter="contains"
            hideCaret
            hideEmptyPopup
            focusFirstItem
            id={'interpreter_' + (i+1).toString()}
          />
        </div>
        );
    }

    return (
      <div>
        <Modal
          className="modal"
          overlayClassName="modalOverlay"
          aria-modal="true"
          contentLabel="Program Settings"
          shouldCloseOnOverlayClick={true}
          isOpen={this.props.modalIsOpen}
          onRequestClose={this.onClose}
        >
          
          <div className="modalHeader">Program Settings</div>
         {this.state.programId ? 
            (
              <>   
              <div className="modalContent center">
                {/* COMMENTING OUT this section for now until alerts are reimplemented                 
                <div className="sectionHeading center">
                  Reminder Frequency
                </div>
                <div className="subSection">
                  <input type="radio" name="dailyOrWeekly" value={DAILY}                 
                    defaultChecked={this.state.reminderFrequency == DAILY} 
                    onChange={(e) =>  this.setState({ reminderFrequency: e.target.value })}
                    id="daily"
                    /><label htmlFor="daily">{DAILY}</label>
                  <input type="radio" name="dailyOrWeekly" value={WEEKLY}
                    defaultChecked={this.state.reminderFrequency == WEEKLY} 
                    onChange={(e) =>  this.setState({ reminderFrequency: e.target.value })}
                    id="weekly"
                    /><label htmlFor="weekly">{WEEKLY}</label>
                </div>*/}
                <div className="sectionHeading center">
                  <label htmlFor="number-of-interpreters">Default Number of Required Interpretations (1-5)</label>
                </div>
            
                <div className="subSection">
                    {/* default value of num of interpretations will need to pull from state/props, and also update on save*/}
                  <div className="modalInput">
                  <TextField
                    select
                    size="small"
                    name="numberOfInterpreters" 
                    id="number-of-interpreters"
                    aria-label="Set number of Interpretations" 
                    // defaultValue={this.state.defaultInterpretationCount}
                    value={this.state.defaultInterpretationCount ? this.state.defaultInterpretationCount : ""}
                    onChange={this.setDefaultInterpretationCount} 
                    variant="outlined"
                    SelectProps={{ 
                      MenuProps: { 
                        disableScrollLock: true, 
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left"
                        },
                        getContentAnchorEl: null
                      }, 
                    }}
                  >
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                  </TextField>
                  {/* <input name="defaultInterpretationCount" aria-label="Set number of Interpretations"
                    type="number" min="1" max="5"
                    defaultValue={this.state.defaultInterpretationCount} 

                      onChange={this.setDefaultInterpretationCount}
                    id="number-of-interpreters"
                  />  */}
                  <span className="validity"></span></div>
                </div>
                <div className="sectionHeading center">
                  <label htmlFor="review-threshold">Default Review Threshold (days)</label>
                </div>
                <div className="subSection">
                  <div className="modalInput">
                  <TextField
                    select
                    size="small"
                    name="reviewThreshold" 
                    id="review-threshold"
                    aria-label="Set review threshold" 
                    value={this.state.reviewThreshold}
                    onChange={(e) =>  this.setState({ reviewThreshold: e.target.value })}
                    variant="outlined"
                    SelectProps={{ 
                      MenuProps: { 
                        disableScrollLock: true, 
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left"
                        },
                        getContentAnchorEl: null
                      }, 
                    }}
                  >
                    <MenuItem value={180}>180</MenuItem>
                    <MenuItem value={120}>120</MenuItem>
                    <MenuItem value={90}>90</MenuItem>
                    <MenuItem value={60}>60</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                  </TextField>
                </div>
                </div>
                <div className="sectionHeading center">
                  Default Assigned Interpreters
                </div>
                {this.state.errorMessage.map((err,i)=><li key={i} className="error">{err}</li>)}
                <div className="subSection"> 
                  {combobox}   
                </div>
            
              </div>
              <div className="modalButtons">
                <button
                  className="modalButton primaryButton"
                  onClick={this.onSubmit}
                >
                  Save
                </button>
                <button className="modalButton" onClick={this.onClose}>
                  Cancel
                </button>
              </div>
              </> 
            ): ""}
   
        </Modal>
        
        <SuccessMessageModal 
           closeModal={this.closeSuccessMessageModal}
           modalIsOpen={this.state.successMessageModalIsOpen}
           message={this.state.successMessage}
        />
      </div>
    );
  }
}
