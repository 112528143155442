import React, {useState} from 'react';
import { Grid } from '@material-ui/core';
import { Collapse } from '@material-ui/core';
import FooterBanner from '../FooterBanner';
import SessionConstants from '../../config/SessionConstants';
import './index.css';

export default function CdcFooter() {
    //check session for fullscreen setting
    const [fullscreen] = useState(JSON.parse(sessionStorage.getItem(SessionConstants.FULLSCREEN)));

    /*Handle CDC mobile footer reworked accordion features in React*/ 
    /*collapse */
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);
    /*rotate caret icon */
    const [toggleClass1, setToggleClass1] = useState(false)
    const [toggleClass2, setToggleClass2] = useState(false)
    const [toggleClass3, setToggleClass3] = useState(false)
    const [toggleClass4, setToggleClass4] = useState(false)
    /*handle click functions */
    const clickContact = (e) => {
        e.preventDefault();
        setOpen1(!open1);
        setToggleClass1(!toggleClass1);
    }; 
    const clickAbout = (e) => {
        e.preventDefault();
        setOpen2(!open2);
        setToggleClass2(!toggleClass2);
    }; 
    const clickPolicies = (e) => {
        e.preventDefault();
        setOpen3(!open3);
        setToggleClass3(!toggleClass3);
    }; 
    const clickLanguage = (e) => {
        e.preventDefault();
        setOpen4(!open4);
        setToggleClass4(!toggleClass4);
    }; 

    return (
        <footer role="contentinfo" aria-label="Footer">
            <div className="container-fluid footer-wrapper">
                <FooterBanner />
                <div className={ fullscreen? 'container-wide' : 'container' }>
                    {/* DESKTOP FOOTER VIEW */}
                    <div className="footer-desktop">
                        <div className="row">
                            <Grid container spacing={0}>
                                <Grid item xs={12} sm={3}>
                                    <address className="col-md-3" itemScope="itemScope" itemType="https://schema.org/Organization">
                                        <strong>HAVE QUESTIONS?</strong>
                                        <div className="row mt-2">
                                            <div className="col-1"><span className="fi cdc-icon-desktop x24" aria-hidden="true"></span></div>
                                            <div className="col">
                                                <span className="pl-1"><a href="https://www.cdc.gov/cdc-info/index.html">Visit CDC-INFO</a></span>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-1"><span className="fi cdc-icon-phone x24"></span></div>
                                            <div className="col">
                                                <span className="pl-1" itemProp="telephone">Call 800-232-4636</span>
                                            </div>
                                        </div>
                                        <div className="row mt-2">
                                            <div className="col-1"><span className="fi cdc-icon-email x24"></span></div>
                                            <div className="col">
                                                <span className="pl-1" itemProp="url"><a href="https://wwwn.cdc.gov/dcs/contactus/form">Email CDC-INFO</a></span>
                                            </div>
                                        </div>
                                            <div className="row mt-2">
                                                <div className="col-1"><span className="fi cdc-icon-clock_01 x24"></span></div>
                                                <div className="col">
                                                    <span className="pl-1"><a href="https://www.cdc.gov/cdc-info/index.html">Open 24/7</a></span>
                                                </div>
                                            </div>
                                    </address>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <div className="col-md-3 mt-3 mt-md-0">
                                        <strong>CDC INFORMATION</strong>
                                        <ul>
                                            <li><a href="https://www.cdc.gov/about/default.htm">About CDC</a></li>
                                            <li><a href="https://jobs.cdc.gov">Jobs</a></li>
                                            <li><a href="https://www.cdc.gov/funding">Funding</a></li>
                                            <li><a href="https://www.cdc.gov/Other/policies.html">Policies</a></li>
                                            <li><a href="https://www.cdc.gov/other/plugins/index.html">File Viewers &amp; Players</a></li>
                                            <li><a href="https://wwwn.cdc.gov/pubs/other-languages">Other Languages</a></li>
                                        </ul>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <div className="col-md-3">
                                        <ul>
                                            <li><a href="https://www.cdc.gov/other/privacy.html">Privacy</a></li>
                                            <li><a href="https://www.cdc.gov/od/foia">FOIA</a></li>
                                            <li><a href="https://www.cdc.gov/eeo/nofearact/index.htm">No Fear Act</a></li>
                                            <li><a href="https://oig.hhs.gov">OIG</a></li>
                                            <li><a href="https://www.cdc.gov/other/nondiscrimination.html">Nondiscrimination</a></li>
                                            <li><a href="https://www.cdc.gov/contact/accessibility.html">Accessibility</a></li>
                                            <li><a href="https://www.hhs.gov/vulnerability-disclosure-policy/index.html">Vulnerability Disclosure Policy</a></li>
                                        </ul>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <div className="col-md-3 mt-2 mt-md-0 connect-icons">
                                        <strong>CONNECT WITH CDC</strong><br />
                                        <ul className="list-inline mt-3">
                                            <li><a href="https://www.facebook.com/CDC" target="_blank" rel="noreferrer noopener"><span className="sr-only">Facebook</span><span className="fi cdc-icon-fb-white x24" aria-hidden="true"></span></a></li>
                                            <li><a href="https://twitter.com/CDCgov" target="_blank" rel="noreferrer noopener"><span className="sr-only">Twitter</span><span className="fi cdc-icon-twitter-white x24" aria-hidden="true"></span></a></li>
                                            <li><a href="https://www.instagram.com/CDCgov/" target="_blank" rel="noreferrer noopener"><span className="sr-only">Instagram</span><span className="fi cdc-icon-ig-white x24" aria-hidden="true"></span></a></li>
                                            <li><a href="https://www.linkedin.com/company/centers-for-disease-control-and-prevention" target="_blank" rel="noreferrer noopener"><span className="sr-only">LinkedIn</span><span className="fi cdc-icon-linkedin-white x24" aria-hidden="true"></span></a></li>
                                            <li><a href="https://www.snapchat.com/add/cdcgov" target="_blank" rel="noreferrer noopener"><span className="sr-only">Snapchat</span><span className="fi cdc-icon-snapchat x24" aria-hidden="true"></span></a></li>
                                                </ul><br className="d-none d-md-block" />
                                                <ul className="list-inline mt-2">
                                            <li><a href="https://www.youtube.com/user/CDCstreamingHealth" target="_blank" rel="noreferrer noopener"><span className="sr-only">Youtube</span><span className="fi cdc-icon-youtube-white x24" aria-hidden="true"></span></a></li>
                                            {/* Syndication link for ED3N, likely not used. Set to cdc.gov */}
                                            <li><a href="https://tools.cdc.gov/medialibrary/index.aspx#/sharecontent/https://www.cdc.gov/" className="footer-syndlink"><span className="sr-only">Syndicate</span><span className="fi cdc-icon-syndication-white x24" aria-hidden="true"></span></a></li>
                                                        <li><a href="https://www.cdc.gov/cdctv"><span className="sr-only">CDC TV</span><span className="fi cdc-icon-cdc-tv-white x24" aria-hidden="true"></span></a></li>
                                                        <li><a href="https://tools.cdc.gov/podcasts/rss.asp"><span className="sr-only">RSS</span><span className="fi cdc-icon-rss-white x24" aria-hidden="true"></span></a></li>
                                            <li><a href="https://wwwn.cdc.gov/dcs/RequestForm.aspx"><span className="sr-only">Email</span><span className="fi cdc-icon-email x24" aria-hidden="true"></span></a></li>
                                        </ul>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                    {/* MOBILE FOOTER VIEW */}
                    <div className="footer-mobile">
                        <div className="accordion indicator-arrow mb-3" >
                            <div className="card footer-mobile_contact">
                                <a href="#" aria-label='tab button' onClick={clickContact} className="footerTab">
                                    <div className={toggleClass1 ? 'card-header' : 'card-header collapsed'} data-target="#footer-mobile-collapse-1"  id="footer-mobile-card-1" >
                                        CONTACT CDC
                                    </div>
                                </a>
                                <Collapse in={open1} timeout="auto" unmountOnExit>
                                    <div aria-labelledby="footer-mobile-card-1" className="collapse" id="footer-mobile-collapse-1" >
                                        <div className="card-body">
                                            <address itemScope="itemScope" itemType="https://schema.org/Organization">
                                                <div className="row mt-2">
                                                    <div className="col-1">
                                                        <span aria-hidden="true" className="fi cdc-icon-desktop x24"></span>
                                                    </div>
                                                    <div className="col">
                                                        <span className="pl-1"><a href="https://www.cdc.gov/cdc-info/index.html">Visit CDC-INFO</a></span>
                                                    </div>
                                                </div>
                                                <div className="row mt-2">
                                                    <div className="col-1">
                                                        <span className="fi cdc-icon-phone x24"></span>
                                                    </div>
                                                    <div className="col">
                                                        <span className="pl-1" itemProp="telephone">Call 800-232-4636</span>
                                                    </div>
                                                </div>
                                                <div className="row mt-2">
                                                    <div className="col-1">
                                                        <span className="fi cdc-icon-email x24"></span>
                                                    </div>
                                                    <div className="col">
                                                        <span className="pl-1" itemProp="url"><a href="https://wwwn.cdc.gov/dcs/contactus/form">Email CDC-INFO</a></span>
                                                    </div>
                                                </div>
                                                <div className="row mt-2">
                                                    <div className="col-1">
                                                        <span className="fi cdc-icon-clock_01 x24"></span>
                                                    </div>
                                                    <div className="col">
                                                        <span className="pl-1"><a href="https://www.cdc.gov/cdc-info/index.html">Open 24/7</a></span>
                                                    </div>
                                                </div>
                                            </address>
                                        </div>
                                    </div>
                                </Collapse>
                            </div>
                            <div className="card footer-mobile_about">
                                <a href="#" aria-label='tab button' onClick={clickAbout} className="footerTab">
                                    <div className={toggleClass2 ? 'card-header' : 'card-header collapsed'} data-target="#footer-mobile-collapse-2" data-toggle="collapse" id="footer-mobile-card-2" >
                                        ABOUT CDC
                                    </div>
                                </a>
                                <Collapse in={open2} timeout="auto" unmountOnExit>
                                    <div aria-labelledby="footer-mobile-card-2" className="collapse" id="footer-mobile-collapse-2" >
                                        <div className="card-body">
                                            <ul>
                                                <li><a href="https://www.cdc.gov/about/default.htm">About CDC</a></li>
                                                <li><a href="https://jobs.cdc.gov">Jobs</a></li>
                                                <li><a href="https://www.cdc.gov/funding">Funding</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </Collapse>
                            </div>
                            <div className="card footer-mobile_policies">
                                <a href="#" aria-label='tab button' onClick={clickPolicies} className="footerTab">
                                    <div className={toggleClass3 ? 'card-header' : 'card-header collapsed'} data-target="#footer-mobile-collapse-3" data-toggle="collapse" id="footer-mobile-card-3" >
                                        POLICIES
                                    </div>
                                </a>
                                <Collapse in={open3} timeout="auto" unmountOnExit>
                                    <div aria-labelledby="footer-mobile-card-3" className="collapse" id="footer-mobile-collapse-3" >
                                        <div className="card-body">
                                            <ul>
                                                <li><a href="https://www.cdc.gov/Other/policies.html">Policies</a></li>
                                                <li><a href="https://www.cdc.gov/other/plugins/index.html">File Viewers &amp; Players</a></li>
                                                <li><a href="https://wwwn.cdc.gov/pubs/other-languages">Other Languages</a></li>
                                                <li><a href="https://www.cdc.gov/other/privacy.html">Privacy</a></li>
                                                <li><a href="https://www.cdc.gov/od/foia">FOIA</a></li>
                                                <li><a href="https://www.cdc.gov/eeo/nofearact/index.htm">No Fear Act</a></li>
                                                <li><a href="https://oig.hhs.gov">OIG</a></li>
                                                <li><a href="https://www.cdc.gov/other/nondiscrimination.html">Nondiscrimination</a></li>
                                                <li><a href="https://www.cdc.gov/contact/accessibility.html">Accessibility</a></li>
                                                <li><a href="https://www.hhs.gov/vulnerability-disclosure-policy/index.html">Vulnerability Disclosure Policy</a></li>
                                                <li><a className="footer-ext" href="https://www.cdc.gov/Other/disclaimer.html">CDC Website Exit Disclaimer</a> <span className="sr-only">external icon</span><span aria-hidden="true" className="fi cdc-icon-external x16"></span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </Collapse>
                            </div>
                            <div className="card footer-mobile_language">
                                <a href="#" aria-label='tab button' onClick={clickLanguage} className="footerTab">
                                    <div className={toggleClass4 ? 'card-header' : 'card-header collapsed'} data-target="#footer-mobile-collapse-4" data-toggle="collapse" id="footer-mobile-card-4" >
                                        LANGUAGE ASSISTANCE
                                    </div>
                                </a>
                                <Collapse in={open4} timeout="auto" unmountOnExit>
                                    <div aria-labelledby="footer-mobile-card-4" className="collapse" id="footer-mobile-collapse-4" >
                                        <div className="card-body">
                                            <ul className="bullet-list cc-2 lsp-out">
                                                <li lang="es"><a href="https://www.cdc.gov/other/language-assistance.html#Spanish" title="Spanish">Español</a></li>
                                                <li lang="zh"><a href="https://www.cdc.gov/other/language-assistance.html#Chinese" title="Chinese">繁體中文</a></li>
                                                <li lang="vi"><a href="https://www.cdc.gov/other/language-assistance.html#Vietnamese" title="Vietnamese">Tiếng Việt</a></li>
                                                <li lang="ko"><a href="https://www.cdc.gov/other/language-assistance.html#Korean" title="Korean">한국어</a></li>
                                                <li lang="tl"><a href="https://www.cdc.gov/other/language-assistance.html#Tagalog" title="Tagalog">Tagalog</a></li>
                                                <li lang="ru"><a href="https://www.cdc.gov/other/language-assistance.html#Russian" title="Russian">Русский</a></li>
                                                <li lang="ar"><a href="https://www.cdc.gov/other/language-assistance.html#Arabic" title="Arabic">العربية</a></li>
                                                <li lang="ht"><a href="https://www.cdc.gov/other/language-assistance.html#Haitian" title="Haitian">Kreyòl Ayisyen</a></li>
                                                <li lang="fr"><a href="https://www.cdc.gov/other/language-assistance.html#French" title="French">Français</a></li>
                                                <li lang="pl"><a href="https://www.cdc.gov/other/language-assistance.html#Polish" title="Polish">Polski</a></li>
                                                <li lang="pt"><a href="https://www.cdc.gov/other/language-assistance.html#Portuguese" title="Portuguese">Português</a></li>
                                                <li lang="it"><a href="https://www.cdc.gov/other/language-assistance.html#Italian" title="Italian">Italiano</a></li>
                                                <li lang="de"><a href="https://www.cdc.gov/other/language-assistance.html#German" title="German">Deutsch</a></li>
                                                <li lang="ja"><a href="https://www.cdc.gov/other/language-assistance.html#Japanese" title="Japanese">日本語</a></li>
                                                <li lang="fa"><a href="https://www.cdc.gov/other/language-assistance.html#Farsi" title="Farsi">فارسی</a></li>
                                                <li lang="en"><a href="https://www.cdc.gov/other/language-assistance.html#English" title="English">English</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </Collapse>
                            </div>
                        </div>
                        <div className="mobile-footer-social pl-3 pt-2">
                            <ul className="list-inline mb-0">
                                <li className="list-inline-item">
                                    <a href="https://www.facebook.com/CDC" target="_blank" rel="noreferrer noopener"><span className="sr-only">Facebook</span><span aria-hidden="true" className="fi cdc-icon-fb-white x32"></span></a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="https://twitter.com/CDCgov" target="_blank" rel="noreferrer noopener"><span className="sr-only">Twitter</span><span aria-hidden="true" className="fi cdc-icon-twitter-white x32"></span></a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="https://www.instagram.com/CDCgov/" target="_blank" rel="noreferrer noopener"><span className="sr-only">Instagram</span><span aria-hidden="true" className="fi cdc-icon-insta x32"></span></a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="https://www.linkedin.com/company/centers-for-disease-control-and-prevention" target="_blank" rel="noreferrer noopener"><span className="sr-only">LinkedIn</span><span aria-hidden="true" className="fi cdc-icon-linkedin-white x32"></span></a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="https://www.snapchat.com/add/cdcgov" target="_blank" rel="noreferrer noopener"><span className="sr-only">Snapchat</span><span aria-hidden="true" className="fi cdc-icon-snapchat x32"></span></a>
                                </li>
                                <li className="list-inline-item"><a href="https://www.youtube.com/user/CDCstreamingHealth" target="_blank" rel="noreferrer noopener"><span className="sr-only">Youtube</span><span className="fi cdc-icon-youtube-white x32" aria-hidden="true"></span></a></li>
                                <li className="list-inline-item"><a href="https://tools.cdc.gov/medialibrary/index.aspx#/sharecontent/https://www.cdc.gov/nndss/index.html" className="footer-syndlink"><span className="sr-only">Syndicate</span><span className="fi cdc-icon-syndication-white x32" aria-hidden="true"></span></a></li>
                                <li className="list-inline-item"><a href="https://www.cdc.gov/cdctv"><span className="sr-only">CDC TV</span><span className="fi cdc-icon-cdc-tv-white x32" aria-hidden="true"></span></a></li>
                                <li className="list-inline-item"><a href="https://tools.cdc.gov/podcasts/rss.asp"><span className="sr-only">RSS</span><span className="fi cdc-icon-rss-white x32" aria-hidden="true"></span></a></li>
                                <li className="list-inline-item"><a href="https://wwwn.cdc.gov/dcs/RequestForm.aspx"><span className="sr-only">Email</span><span className="fi cdc-icon-email x32" aria-hidden="true"></span></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* AGENCY FOOTER SECTION */}
            <div className="container-fluid agency-footer">
                <div className={ fullscreen? 'container-wide' : 'container'}>
                    <div className="row" itemScope="itemScope" itemType="https://schema.org/Organization">
                        <Grid container spacing={0}>
                            <Grid item xs={12} sm={6}>
                                <div className="col-md-6 mt-1 mt-md-0">
                                    <span className="value" itemProp="contactPoint" itemScope="itemScope" itemType="https://schema.org/ContactPoint">
                                        <span itemProp="url"><a href="https://www.hhs.gov">U.S. Department of Health &amp; Human Services</a></span>
                                    </span>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <div className="col-md-3 mt-1 mt-md-0">
                                    <span className="value" itemProp="contactPoint" itemScope="itemScope" itemType="https://schema.org/ContactPoint">
                                        <span itemProp="url"><a href="https://www.usa.gov/">USA.gov</a></span>
                                    </span>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <div className="col-md-3 mt-1 mt-md-0">
                                    <span className="value">
                                        <span><a href="https://www.cdc.gov/Other/disclaimer.html" className="footer-ext">CDC Website Exit Disclaimer</a> <span className="sr-only">external icon</span><span className="fi cdc-icon-external x16" aria-hidden="true"></span></span>
                                    </span>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        </footer>
    );
}
