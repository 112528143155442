import React, { Component } from 'react';
import ReactTable from 'react-table';
import moment from 'moment';

export default class AdministrativeTasksTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: [],
    };
  }

  componentDidMount() {
    this.getColumns(); //fix column resize on window change
    window.addEventListener("resize", this.getColumns.bind(this)); 
  }

  getColumns = () => {
    this.setState({
        columns: [
            {
                Header: 'Created Date',
                accessor: 'itemType', 
                Cell: this.createDate,
                minWidth: 30,
            },
            {
                Header: 'Description',
                accessor: 'taskDescription',
                style: { 'whiteSpace': 'unset' }, //make words wrap in columns
            },
        ]
    });
  }

  createDate = (rowInfo) => {
    var tasks = rowInfo.original.taskHistoryItems.filter(function (e) {
      return  e.itemType.value == "CREATED"
    });
    return tasks.length > 0 ?  moment(tasks[0].taskHistoryItemPk.itemDate).format("MM/DD/YYYY") : "";
  }

  render() {

    return (
      <div>
        <ReactTable
          data={this.props.data} 
          showPagination={false}
          minRows={1}
          defaultSorted={[
            {
              id: 'itemType',
              desc: true,
            },
          ]}
          columns={this.state.columns}
          //Make table headers focusable and add keypress for sorting for 508 accessibility
          getTheadThProps={(state, rowInfo, column, instance) => {
            return {
              tabIndex: 0,
              onKeyPress: (e, handleOriginal) => {
                if(e.which === 13 || e.which === 32) {
                  instance.sortColumn(column);
                }
              }
            };
          }}
        />
      </div>
    );
  }
}
