import React, {useState, useEffect} from 'react';
import AnnotationNamesConfig from '../../../AddInterpretation/AnnotationNamesConfig.js';
import AnnotationSectionsConfig from '../../../AddInterpretation/AnnotationSectionsConfig.js';
import {StyledTableRow,StyledTableCell} from '../../../../uiComponents/MuiTable/StyledTable.js'
import MuiTableHeader from '../../../../uiComponents/MuiTable/MuiTableHeader';
import { 
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  Paper,
  TextField,
  MenuItem
} from '@material-ui/core';
import ReviewStatus from '../../../../config/ReviewStatus.js';
import './index.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    fontFamily: "Open Sans",
  },
  paper: {
    width: '100%',
  },
  table: {
    minWidth: 750,
    // "& .MuiTableBody-root .MuiTableRow-root:last-child": {
    //   borderTop: "1px solid black",
    //   backgroundColor: "white"
    // },
  },
}));

const useStyleInputs = makeStyles({
  //custom colors for MUI text field select
  styleInputs: {
      "& .MuiOutlinedInput-input": {
          color: "#2e2e2e", 
          backgroundColor: "white"
      },
      "& .MuiInputLabel-root": {
          color: "#2e2e2e"
      },
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "#737373"
      },
      "&:hover .MuiOutlinedInput-input": {
          color: "#006778"
      },
      "&:hover .MuiInputLabel-root": {
          color: "#006778"
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "#006778"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
          color: "#bb4d00",
          backgroundColor: "white"
      },
      "& .MuiInputLabel-root.Mui-focused": {
          color: "#bb4d00"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "#bb4d00"
      },
  }
});

export default function ConsensusCategoryTable(props) {

  const classes = useStyles();
  const [tableData, setTableData] = useState(props.data);
  // const reviewStatus = props.reviewStatus;
  const readyForConsensus = props.reviewStatus === ReviewStatus.READY_FOR_CONSENSUS ? true : false;
  const closed = props.reviewStatus === ReviewStatus.CLOSED ? true : false;
  // const formValues = props.formValues;
  // const finalVarInterp = props.finalVarInterp;
  const final = "FINAL@CDC.GOV";
  const [evalValue, setEvalValue] = useState({});

  //initialize saved final eval data based on finalVarInterp prop
  useEffect(() => { 
    if (!readyForConsensus & !closed) {
      setEvalValue(props.finalVarInterp);
    }
  }, [props.finalVarInterp] );

  //intialize tableData
  useEffect(() => { 
    setTableData(props.data);
  }, [props.data] );

  //initialize calculated final eval data based on form values
  useEffect(() => {
    if (readyForConsensus) {
      setEvalValue(props.formValues);
    }
  }, [props.formValues])

  // console.log("++++review status: " + reviewStatus);
  // console.log("++++closed: " + closed);
  // console.log("++++formValues: " + JSON.stringify(formValues));
  // console.log("++++evalValue: " + JSON.stringify(evalValue));
  // console.log("--------length: " + Object.keys(evalValue).length);
  // console.log("--------finalVarInterp CONST: " + JSON.stringify(finalVarInterp));

  //Important: The id here must match the column name in the returned data. Label can be different for display.
  const columns = [
    {label: 'Reviewer', id: 'sortName', numeric: false, disablePadding: false},
  ];
  Object.entries(AnnotationNamesConfig).forEach(([annotation, annotationString]) => {
    columns.push({label:annotationString,id:annotation, numeric:false, disablePadding:false});
  })

  const renderEvaluationOptions = (selectedCategory) => {
    let array = [];
    
    let categoryEnum = selectedCategory;
    //compare the current enum to the category string list
    let categoryName = AnnotationNamesConfig[categoryEnum];
    let annotationSections = AnnotationSectionsConfig[categoryName];
    if (annotationSections != null) {
    let value = annotationSections["categories"];
    value.map(category => {
        let name = category.name;
        array.push(
          <MenuItem key={name} value={name}>{name}</MenuItem>
        );
    });
    }
    return array;
  }

  const evaluationCell = (row, category) => {
    const classes = useStyleInputs();
    let evidenceCategory = category;

    return (
      <TextField
        fullWidth
        size="small"
        id={"finalEvaluationFor_" + category}
        className={classes.styleInputs}
        value={evalValue[category] || ''}
        variant="outlined"
        label="Evaluation"
        name={evidenceCategory}
        onChange={handleCategoryEvalChange(category)}
        select
      >
        {renderEvaluationOptions(category)}
      </TextField>
    )
  }

  const categoryTableCell = (row, category) => {
    if (!closed && row.reviewId === final) {
      return (
        evaluationCell(row, category)
      )
    } else {
      return (
        <>
        <div className='rowGroup'>{row[category] ? row[category]["evaluation"] : ''}</div>
        {row[category] ? row[category]["comment"] : ''}
        </>
      )
    }
  }

  const handleCategoryEvalChange = (category) => (event) => {
    let newValue = event.target.value;
    let newEvalValue = evalValue;
    newEvalValue[category] = newValue;
    // Remember: this is a really complicated spread function you have to use to update object key value pairs
    setEvalValue({...evalValue, [category]:newValue});
    console.log("evalValue Object: " + evalValue);
    props.onEvaluationChange(evalValue);
  } 

  return (
    <>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <TableContainer>
            <Table
              className={classes.table}
              size='small'
              aria-label="consensus category table">
              <MuiTableHeader 
                noSortHeadCells={columns} 
              />
              <TableBody>
                {/* set table data default sort before render since this table should not be sortable by user, move final review to bottom of table */}
                {tableData.sort((a,b) => {
                  if (a.reviewId === final && b.reviewId !== final) {
                    return 1;
                  } else if (a.reviewId !== final && b.reviewId === final) {
                    return -1;
                  } else {
                    return a.sortName.localeCompare(b.sortName);
                  }
                }).map((row) => {
                  return (
                    <StyledTableRow tabIndex={-1} key={row.reviewId}>
                      <StyledTableCell style={{ width: '15%' }}>{row.sortName}</StyledTableCell>
                      <StyledTableCell style={{ width: '17%', verticalAlign: 'top' }}>
                        {categoryTableCell(row, 'POPULATION_FREQUENCY')}
                      </StyledTableCell>
                      <StyledTableCell style={{ width: '17%', verticalAlign: 'top' }}>
                        {categoryTableCell(row, 'DISEASE_DATABASES')}
                      </StyledTableCell>
                      <StyledTableCell style={{ width: '17%', verticalAlign: 'top' }}>
                        {categoryTableCell(row, 'COMPUTATIONAL_PREDICTIVE_PROGRAMS')}
                      </StyledTableCell>
                      <StyledTableCell style={{ width: '17%', verticalAlign: 'top' }}>
                        {categoryTableCell(row, 'NUCLEOTIDE_CONSERVATION')}
                      </StyledTableCell>
                      {/* <StyledTableCell style={{ width: '17%', verticalAlign: 'top' }}>
                        {categoryTableCell(row, 'FUNCTIONAL_STUDIES_DATA')}
                      </StyledTableCell> */}
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>
    </>
  );
}
