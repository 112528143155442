import React, { Component } from 'react';
import ReactTable from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle, faPaperPlane, faUserTimes, faTimes } from '@fortawesome/free-solid-svg-icons';

import './index.css'

export default class AssignedInterpreters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      taskData: this.props.taskData,
      columns: [],
    };
  }

  componentDidMount() {
    console.log("Assigned Interpreters componentDidMount")
    this.getColumns(); //to fix column resize on window change

    window.addEventListener("resize", this.getColumns.bind(this)); 
  }

  nameCell = (rowInfo) => {
    let userFullName = `${rowInfo.original.user.firstName} ${rowInfo.original.user.lastName}`;
    return (
        <div className="nameCell">
            <div>
                <p> {userFullName} </p>
            </div>
        </div> 
      )
  }

  inProgressCell = (rowInfo) => {
    return (
      rowInfo.original.partial ? ( <div className="iconCell"><FontAwesomeIcon icon={faCheckCircle} className="interpreterCheckCircle"/></div> ) : ( '' )
    )
  }
  completionCell = (rowInfo) => {
      return (
        rowInfo.original.partial == false ? ( <div className="iconCell"><FontAwesomeIcon icon={faCheckCircle} className="interpreterCheckCircle"/></div> ) : ( '' )
      )
  }

  getTaskHistoryByStatus = (userId,status)=>{
    //TODO: Need to revisit the logic.  Currently, tasks are not tied to user.  Maybe  
    //addition filter from itemDescription to ensure that item is belong to current user
    let taskData = [...this.state.taskData];
    let userTasks = taskData.filter(function (e) {
      return e.assignedUser == userId && e.taskType.value == "Molecular Interpretation"
    });
    let task = null;
    if (userTasks.length> 0){
        task = userTasks[0].taskHistoryItems.filter(function (e) {
             return  e.itemType.value == status
         });
    }
    return task != null && task.length > 0 ? true : false;
  }

  getColumns = () => {
    this.setState({
      columns: [
        {
          Header: 'Name',
          accessor: 'user',
          width: Math.round(window.innerWidth * 0.16),
          Cell: this.nameCell,
        },
        {
          Header: 'In Progress',
          headerStyle: { 'whiteSpace': 'unset' },
          accessor: 'partial',
          style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
          minWidth: 100,
          Cell: this.inProgressCell,
        },
        {
          Header: 'Completed',
          headerStyle: { 'whiteSpace': 'unset' },
          accessor: 'partial',
          style: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'},
          minWidth: 100,
          Cell: this.completionCell,
        },
        {
          Header: 'Classification',
          headerStyle: { 'whiteSpace': 'unset' },
          accessor: 'programClassification',
          style: { 'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center' },
          minWidth: 100,
        },
        {
          Header: 'Comments',
          accessor: 'comment',
          style: { 'whiteSpace': 'unset', 'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center' },
          minWidth: 350,
        },
      ]
    });
  }

  render() {
    return (
      <div key={this.props.key}>
        <ReactTable
          data={this.props.data} 
          showPagination={false}
          minRows={1}
          defaultSorted={[
            {
              id: 'name',
              desc: true,
            },
          ]}
          columns={this.state.columns}
          //Make table headers focusable and add keypress for sorting for 508 accessibility
          getTheadThProps={(state, rowInfo, column, instance) => {
            return {
              tabIndex: 0,
              onKeyPress: (e, handleOriginal) => {
                if(e.which === 13 || e.which === 32) {
                  instance.sortColumn(column);
                }
              }
            };
          }}
        />
      </div>
    );
  }
}
