import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ReactTable from 'react-table';
import {Helmet} from "react-helmet";
import matchSorter from 'match-sorter';
import moment from 'moment';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faArrowCircleRight, faFileAlt, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import BreadCrumbs from '../BreadCrumbs';
import APIUtils from '../../utils/APIUtils';
import ReviewStatus from '../../config/ReviewStatus.js';
import SessionConstants from '../../config/SessionConstants.js';
import Loading from '../Loading';

export default class CaseDetails extends Component {
  constructor() {
    super();
    this.state = {
      breadCrumbs: [],
      programId: null,
      caseId: null,
      startDate: null,
      filtered: [],
      variants: [],
      dataExists: false,
      loading: true,
      errorMsg: '',
      loadingReport: false,
      caseReport: '',
    };
  }

  async componentDidMount() {
    console.log('-----------CaseDetails.componentDidMount()');

    const {
      match: { params },
    } = this.props;

    // This is loading caseId from a url variable parameter
    let caseId = this.props.match.params.caseId;
    let breadCrumbs = this.props.location.state.breadCrumbs;
    breadCrumbs = [...breadCrumbs, {
      name: 'CaseDetails',
      caseId: caseId,
    }];
    let programId = sessionStorage.getItem(SessionConstants.PROGRAM_ID);

    this.setState({
      breadCrumbs: breadCrumbs,
      caseId: caseId,
      programId: programId,
    });

    this.getVariants(caseId, programId);
    // console.log('-------response data: ' + this.state.variants);
  }

  async getVariants(caseId, programId) {
      console.log('---CaseDetails.getVariants()');

    var response = await APIUtils.getVariantsByProgramCaseId(programId, caseId);
    if (response) {
      console.log('have response data');
      // console.log('-------response data: ' + this.state.variants);
      this.setState({
          variants: response,
          loading: false,
          // data: response,
          dataExists: true,
      });
    } else {
      this.setState({
          loading: false,
          errorMsg: 'There was an error retrieving the data.',
          dataExists: false,
      });
    }
  }

  statusCell = (rowInfo) => {
    if (rowInfo.original.reviewStatus.value == ReviewStatus.CLOSED) {
      return (
        <Link
          to={{
            pathname: '/variantconsensus/' + rowInfo.original.variantId,
            state: {
                variantId: rowInfo.original.variantId,
                reviewStatus: rowInfo.original.reviewStatus.value,
                variantName: rowInfo.original.variantName,
                gene: rowInfo.original.gene,
                potentialCondition: rowInfo.original.potentialCondition,
                breadCrumbs: this.state.breadCrumbs,
            }
          }}
        >
          Consensus review complete <FontAwesomeIcon icon={faCheckCircle}/>
        </Link>
      )
    } else if (rowInfo.original.reviewStatus.value == ReviewStatus.READY_FOR_CONSENSUS) {
      return (
        <Link
          to={{
            pathname: '/variantconsensus/' + rowInfo.original.variantId,
            state: {
                variantId: rowInfo.original.variantId,
                reviewStatus: rowInfo.original.reviewStatus.value,
                variantName: rowInfo.original.variantName,
                gene: rowInfo.original.gene,
                potentialCondition: rowInfo.original.potentialCondition,
                breadCrumbs: this.state.breadCrumbs,
            }
          }}
        >
          Ready for consensus review <FontAwesomeIcon sytle='color: blue' icon={faArrowCircleRight}/>
        </Link>
      )
    } else if (rowInfo.original.reviewStatus.value == ReviewStatus.CONSENSUS_IN_PROGRESS) {
      return (
        <Link
          to={{
            pathname: '/variantconsensus/' + rowInfo.original.variantId,
            state: {
                variantId: rowInfo.original.variantId,
                reviewStatus: rowInfo.original.reviewStatus.value,
                variantName: rowInfo.original.variantName,
                gene: rowInfo.original.gene,
                potentialCondition: rowInfo.original.potentialCondition,
                breadCrumbs: this.state.breadCrumbs,
            }
          }}
        >
          Consensus review in progress <FontAwesomeIcon sytle='color: blue' icon={faArrowCircleRight}/>
        </Link>
      )
    } else {
      return (
        rowInfo.original.expected.toString() + ' expected, ' +
        rowInfo.original.completed.toString() + ' completed, ' +
        rowInfo.original.inProgress.toString() + ' in progress'
      )
    }
  }

  //show 'None' if last reviewed date is empty
  lastReviewedCell = (rowInfo) => {
    return rowInfo.original.lastReviewed ? moment(rowInfo.original.lastReviewed).format("MM/DD/YYYY") : 'None';
  }

  async getReport(programId, caseId) {
    console.log('---CaseDetails.getReport()');

    this.setState({ loadingReport: true });
    var response = await APIUtils.getCaseReport(programId, caseId);

    if (response) {
      // Will open html report in new window
      console.log('have response data');
      let blob = new Blob([response], { type: 'text/html' });
      let url = URL.createObjectURL(blob);
      window.open(url);
      // If want automatic download of html report, uncomment this line
      //this.downloadReportFile(programId, caseId, url);
      URL.revokeObjectURL(url);
      this.setState({
          caseReport: response,
          loadingReport: false,
      });
    } else {
      console.log('no response data');
      console.error("err=" + err);
      this.setState({
          loadingReport: false,
          errorMsg: 'There was an error retrieving the report.',
      });
    }
  }

  downloadReportFile = (programId, caseId, url) => {
    console.log('in downloadFile()');
    const element = document.createElement("a");
    console.log('created element');
    element.href = url;
    console.log('set url link');

    let fileName = programId + '_' + caseId + '_' + this.getDateString();
    console.log('fileName='+fileName);
    element.download = fileName;
    console.log('set download option');
    element.click();
    console.log('triggered element');
  }

  getDateString() {
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    let dateString = year+'-'+month+'-'+date;
    return dateString;
  }

  render() {
    const columns = [
      {
        Header: 'Variant Name',
        accessor: 'variantName',
        Cell: rowInfo => (
          <Link
            to={{
              pathname: '/variantdetails/' + rowInfo.original.variantName,
              state: {
                variantId: rowInfo.original.variantId,
                variantName: rowInfo.original.variantName,
                gene: rowInfo.original.gene,
                potentialCondition: rowInfo.original.potentialCondition,
                expected: rowInfo.original.expected,
                completed: rowInfo.original.completed,
                reviewStatus: rowInfo.original.reviewStatus.value,
                breadCrumbs: this.state.breadCrumbs,
              }
            }}
          >
            {rowInfo.original.variantName}
          </Link>
        ),
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ['variantName'] }),
        filterAll: true,
      },
      {
        Header: 'Review Status',
        accessor: 'reviewStatus',
        style: { 'whiteSpace': 'unset' },
        Cell: this.statusCell,
      },
      {
        Header: 'Gene',
        accessor: 'gene',
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ['gene'] }),
        filterAll: true,
      },
      {
        Header: 'Potential Condition',
        accessor: 'potentialCondition',
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ['potentialCondition'] }),
        filterAll: true,
      },
      {
        Header: 'NBS Program Classification',
        accessor: 'stateClassification',
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ['stateClassification'] }),
        filterAll: true,
      },
      {
        Header: 'Last Reviewed by State X',
        accessor: 'lastReviewed',
        Cell: this.lastReviewedCell,
        filterMethod: (filter, row) => {
          if (
            moment(row[filter.id]).isSame(filter.value) ||
            this.state.startDate === null
          ) {
            return true;
          } else {
            return false;
          }
        },
        Filter: ({ filter, onChange }) => (
          <DatePicker
            selected={this.state.startDate}
            onChange={date => {
              this.setState({ startDate: date });
              onChange(date);
            }}
            todayButton={'Today'}
            placeholderText="Click to select a date"
          />
        ),
      },
    ];
    return (
      <div>
          <Loading isLoading={this.state.loading}/>
        <Helmet>
          <title>ED3N - Case Details</title>
        </Helmet>
        <BreadCrumbs breadCrumbs={this.state.breadCrumbs} />
        <h1 className="pageTitle">Case ID: {this.state.caseId}</h1>
        <div className="button-left-right-bar">
          <div>
          <Link
            tabIndex="-1"
            to={{
                pathname: "/programsummary",
            }}
          >
              <button className="actionButton">
                <FontAwesomeIcon icon={faChevronLeft} />Back to Program Summary
              </button>
            </Link>
          </div>
        </div>
        <Tabs className="tabs" selectedTabClassName="active" defaultIndex={1}>
          <TabList className="tabList">
            <Tab className="tab">Biochemical</Tab>
            <Tab className="tab">Molecular</Tab>
            <Tab className="tab">Clinical</Tab>
          </TabList>
          <TabPanel>
            <div className="comingSoonBanner">Coming Soon</div>
          </TabPanel>
          <TabPanel className="tabPanel">
            <div className="button-bar">
              {/* <button className="actionButton" onClick={() => this.getReport(this.state.programId, this.state.caseId)}>
                <FontAwesomeIcon icon={faFileAlt}/>Create Report
              </button> */}
            </div>
            { this.state.variants ? (
              <ReactTable
                data={this.state.variants}
                showPagination={false}
                minRows={1}
                defaultSorted={[
                  {
                    id: 'lastReviewed',
                    desc: true,
                  },
                ]}
                noDataText={null}
                // filterable
                columns={columns}
                defaultPageSize={10}
                getTheadFilterThProps={() => {
                  return { style: { position: 'inherit', overflow: 'inherit' } };
                }}
                filtered={this.state.filtered}
                onFilteredChange={filtered => this.setState({ filtered })}
                //Make table headers focusable and add keypress for sorting for 508 accessibility
                getTheadThProps={(state, rowInfo, column, instance) => {
                  return {
                    tabIndex: 0,
                    onKeyPress: (e, handleOriginal) => {
                      if(e.which === 13 || e.which === 32) {
                        instance.sortColumn(column);
                      }
                    }
                  };
                }}
              />
              ) : (
                'No case data'
              )
            }
            
          </TabPanel>
          <TabPanel>
            <div className="comingSoonBanner">Coming Soon</div>
          </TabPanel>
        </Tabs>
      </div>
    );
  }
}
