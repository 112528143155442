import React, { Component } from 'react';

import Dropzone from 'react-dropzone';
import {useDropzone} from 'react-dropzone';

import './index.css';

export default class FileUpload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accepted: [],
    };
  }

  onDrop = accepted => {
    this.setState({ accepted });
    this.props.acceptedFiles(accepted);
    this.props.errorMsg();
  };

  render() {
    var accepted = this.state.accepted;
    return (
      <div className="file-upload-content">
        <div className={accepted.length === 0 ? 'dropzone' : 'hiddenCustom'}>
          {/* <Dropzone
            tabIndex="-1"
            accept={this.props.accept}
            className="hidden"
            onDrop={accepted => {
              this.onDrop(accepted);
            }}
            multiple={false}
          >
            <p>{this.props.dropZoneMsg}</p>
            <p>or</p>
            <p>
              <button className="button">Choose File</button>
            </p>
          </Dropzone> */}

          <Dropzone 
            tabIndex="-1"
            accept={this.props.accept}
            className="hidden"
            onDrop={accepted => {
              this.onDrop(accepted);
            }}
            multiple={false}
          >
            {({getRootProps, getInputProps}) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps({'aria-label': 'File Dropzone'})} />
                  <p>{this.props.dropZoneMsg}</p>
                  <p>or</p>
                  <p>
                    <button className="button">Choose File</button>
                  </p>
                </div>
              </section>
            )}
          </Dropzone>
        </div>

        <div className={accepted.length > 0 ? 'selectedFiles' : 'hiddenCustom'}>
          <div className="fileName">
            {accepted.map(file => {
              return <div key={file.size}>{file.name}</div>;
            })}
          </div>
        </div>
      </div>
    );
  }
}
