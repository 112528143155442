import React, { useState } from 'react';
import Modal from 'react-modal';
import { Button, TextField, makeStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import SessionConstants from '../../config/SessionConstants';

import './index.css';

const useStyleInputs = makeStyles({
  //custom colors for MUI text field select
  styleInputs: {
      "& .MuiOutlinedInput-input": {
          color: "#2e2e2e", 
          backgroundColor: "white"
      },
      "& .MuiInputLabel-root": {
          color: "#2e2e2e"
      },
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "#737373"
      },
      "&:hover .MuiOutlinedInput-input": {
          color: "#006778"
      },
      "&:hover .MuiInputLabel-root": {
          color: "#006778"
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          borderColor: "#006778"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
          color: "#bb4d00",
          backgroundColor: "white"
      },
      "& .MuiInputLabel-root.Mui-focused": {
          color: "#bb4d00"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "#bb4d00"
      },
  }
});

export default function DevLoginModal(props) {
  
  const isModalOpen = props.isModalOpen;
  const closeModal = props.closeModal;
  const classes = useStyleInputs();
  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [devLoginUsername, setDevLoginUsername] = React.useState('');
  const [devLoginPassword, setDevLoginPassword] = React.useState('');

  const onUsernameChange = (value) => {
    setDevLoginUsername(value);
    setUsernameError(value.trim() === "");
  };

  const onPasswordChange = (value) => {
    setDevLoginPassword(value);
    setPasswordError(value.trim() === "");
  };

  const devLoginFunction = () => {
    if (devLoginUsername.trim() === "") {
      setUsernameError(true);
    }
    if (devLoginPassword.trim() === "") {
      setPasswordError(true);
    }

    if (devLoginUsername.trim() !== "" && devLoginPassword.trim() !== "") {
      // Proceed with login
      sessionStorage.setItem(SessionConstants.DEVLOGIN, 'true');
      window.location.href = '/oauth-callback?usr=' + devLoginUsername + '&pwd=' + devLoginPassword.replace('#', '$');
    }
  };

  return (
    <>
      <Modal
        className="modal"
        overlayClassName="modalOverlay"
        aria-modal="true"
        contentLabel="Dev Login"
        shouldCloseOnOverlayClick={true}
        isOpen={isModalOpen}
        onRequestClose={closeModal}
      >
        <div className="modalHeader">Dev Login</div>
        <div className="modalContent">
          <div className="devLoginInput">
            <TextField
              className={classes.styleInputs}
              size="small"
              id="devLogin_username"
              variant="outlined"
              label="Username"
              onChange={(e) => onUsernameChange(e.target.value)}
              required
              error={usernameError}
              helperText={usernameError ? "Username is required" : ""}
            />
          </div>
          <div className="devLoginInput">
            <TextField
              className={classes.styleInputs}
              size="small"
              id="devLogin_password"
              variant="outlined"
              label="Password"
              onChange={(e) => onPasswordChange(e.target.value)}
              required
              error={passwordError}
              helperText={passwordError ? "Password is required" : ""}
            />
          </div>
        </div>
        <div className="modalButtons">
          <Button
            style={{ margin: "0 10px", width: "180px" }}
            onClick={devLoginFunction}
            color="primary"
            variant="contained"
            aria-label="do dev login"
          >
            <FontAwesomeIcon icon={faArrowRight} className="landingButtonIcons"></FontAwesomeIcon>
            Login
          </Button>
          <Button
            onClick={closeModal}
            style={{ margin: "0 10px", width: "180px" }}
            variant="contained"
            aria-label="cancel dev login"
          >
            <FontAwesomeIcon icon={faArrowRight} className="landingButtonIcons"></FontAwesomeIcon>
            Cancel
          </Button>
        </div>
      </Modal>
    </>
  );
}
