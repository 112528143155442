import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import APIUtils from '../../utils/APIUtils';
import SessionConstants from '../../config/SessionConstants';
import Oauth from '../../utils/Oauth';

import './index.css'

export default class AlertModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            programId: null,
            userId: null,
            alertList: [],
            taskList:[],
            isAuthenticated: true,
        };
    }

    async componentDidMount() {
      var isLoginValid = await Oauth.isLoginValid();
      if (isLoginValid == true) {
        var programId = sessionStorage.getItem(SessionConstants.programId);
        var alertResponse = await APIUtils.getAlerts();
        var taskResponse = await APIUtils.getTasksByUser();

        var taskList  = taskResponse.filter(function (e) {
            return  e.taskStatus.value == "NEW"
        });

        this.setState({
            programId: programId,
            alertList: alertResponse,
            taskList: taskList,
        });

        this.props.setBadgeContent(taskList.length + alertResponse.length)
      } 
    }

  onClose = () => {
    this.props.closeModal();
  };

  returnFocus = () => {
    document.getElementById("userMenuButton").focus();
  }

  clearAlertItem = () => {
    //REMOVE THE ALERT ITEM FROM THE MODAL ALERT LIST, REFRESH LIST WITH MODAL OPEN
    //ALSO SHOULD UPDATE THE ALERT NUMBER VALUE USED IN THE MENU NOTIFICATION BADGE
    //need to pass alert item id to know which to delete?
  }

  clearAllAlerts = () => {
    //REMOVE -ALL- ALERT ITEMS FROM THE MODAL ALERT LIST, REFRESH LIST WITH MODAL OPEN
    //ALSO SHOULD UPDATE THE ALERT NUMBER VALUE USED IN THE MENU NOTIFICATION BADGE
  }

  alertList = () => {
    return (
      <>
        <div>
            {(this.state.alertList) ?
            this.state.alertList.map((item, index) => (
                <div className="alertBox" key={index}>
                    <div className="alertIcon">
                        <FontAwesomeIcon icon={faExclamationCircle} className="exclamation" />
                    </div>
                    <div className="alertText">
                        {item.alertText}
                    </div>
                </div>
            )) 
          : ('You have no alerts')}
        </div>
        <div>
          {(this.state.taskList) ?
            this.state.taskList.map((item, index) =>(
              <div className="alertBox" key={index}>
                  <div className="alertIcon">
                      <FontAwesomeIcon icon={faExclamationCircle} className="exclamation" />
                  </div>
                  <div className="alertText">
                    <Link to={{ pathname: '/userdashboard'}}  onClick={this.onClose}>
                  {item.variant.hgvsGrch38Name} has been assigned to you for your interpretation.                    
                    </Link>
                  </div>
              </div>
          )) : ('')} 
        </div>
       </>
     
    )
  }

  render() {
    return (
      <div>
        <Modal
          className="modal alertModal"
          overlayClassName="modalOverlay"
          aria-modal="true"
          contentLabel="Alerts"
          shouldCloseOnOverlayClick={true}
          isOpen={this.props.modalIsOpen}
          onRequestClose={this.onClose}
          onAfterClose={this.returnFocus}
        >
          <div className="modalHeader">Alerts</div>
          <div className="modalContent center">
            {this.alertList()}
          </div>
          <div className="modalButtons">
            <button className="modalButton" onClick={this.onClose}>
              Close
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}
