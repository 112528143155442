import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Loading from '../Loading';
import {Helmet} from "react-helmet";

import FeedbackModal from './FeedbackModal';
import UploadModal from './UploadModal';
import CaseTable from '../CaseTable/index.js';
import VariantsTable from './VariantsTable';

import SessionConstants from '../../config/SessionConstants.js';
import APIUtils from '../../utils/APIUtils';
import BreadCrumbs from '../BreadCrumbs';
import { Button } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import './index.css'

export default class ProgramSummary extends Component {
  constructor(props) {
    super(props);

    this.state = {
        isAuthenticated: true,
        breadCrumbs: [],
        caseData: [],
        variantData: [],
        taskList: [],
        uploadModalIsOpen: false,
        feedbackModalIsOpen: false,
        loading: true,
        errorMsg: '',
        loadedFile: false,
        programId: null,
        feedbackMsg: '',
        sortTableByDateDesc: false,
    };
  }

  // componentDidUpdate() {
  //   console.log('---ProgramSummary.componentDidUpdate()');
  // }

  async componentDidMount() {
    console.log('---ProgramSummary.componentDidMount()');

    const {
      match: { params },
    } = this.props;

    var programId = sessionStorage.getItem(SessionConstants.PROGRAM_ID);

    this.setState({
      programId: programId,
      breadCrumbs: [{
        name: 'ProgramSummary',
      }],
    });
    this.getProgramData(programId);
  }

  openUploadModal = () => {
    this.setState({ uploadModalIsOpen: true });
  };

  closeUploadModal = (response,feedback) => {
    var programId = sessionStorage.getItem(SessionConstants.PROGRAM_ID);
    if (feedback) { //feedback is true if upload attempt completed, undefined if upload attempt did not complete or user clicked cancel
      this.setState({
        uploadModalIsOpen: false,
        feedbackModalIsOpen: true,
        feedbackMsg: response,
        sortTableByDateDesc: true,
      });
    } else {
      this.setState({
        uploadModalIsOpen: false,
        feedbackModalIsOpen: false,
        feedbackMsg: '', //clear message
        sortTableByDateDesc: false,
      });
      this.getProgramData(programId);
    }
  };

  closeFeedbackModal = () => {
      var programId = sessionStorage.getItem(SessionConstants.PROGRAM_ID);
      this.setState({
        feedbackModalIsOpen: false,
        programId: programId,
        loading: true,
      });
      this.getProgramData(programId);
  };

  async getProgramData(programId) {
    console.log('ProgramSummary.getProgramData()');

    // Get case load data
    try {
      var cases = await APIUtils.getCasesByProgramId(programId);
      if (cases) {
        this.setState({
          caseData: cases,
        });
      } 
    } catch (error) {
      console.error('Error getting cases')
    }
    
    // Get variant data
    try {
      var variants = await APIUtils.getVariantsByProgramId(programId);
      if (variants) {
        this.setState({
          variantData: variants,
        });
      }
    } catch (error) {
      console.error('Error getting variants')
    }

    // Get tasks by user
    try {
      var taskResponse = await APIUtils.getTasksByUser();
      if (taskResponse) {
        this.setState({
          taskList: taskResponse,
        })
      }
    } catch (error) {
      console.error('Error getting tasks')
    }

    this.setState({
      loading: false
    });
  };

  render() {
    return (
      <div>
        <Loading isLoading={this.state.loading}/>
        <Helmet>
          <title>ED3N - Program Summary</title>
        </Helmet>
        <BreadCrumbs breadCrumbs={this.state.breadCrumbs} />
        <h1 className="pageTitle">Program Summary</h1>
        {!(this.state.loading) ? (
          <>
          <div className="button-bar">
            <Button 
              onClick={this.openUploadModal}
              color="primary"
              variant="contained"
              aria-label="Upload data" 
            >
              <CloudUploadIcon className="iconMarginRight" />Upload Data
            </Button>
          </div>
          <div className="progSummaryText">
            <Tabs className="tabs" selectedTabClassName="active">
              <TabList className="tabList">
                <Tab className="tab">Identified Variants</Tab>
                <Tab className="tab">Cases for Review</Tab>
              </TabList>
              <TabPanel className="tabPanel">
                { this.state.variantData.length > 0 ? (
                    <VariantsTable 
                      data={this.state.variantData}
                      programId={this.state.programId}
                      breadCrumbs={this.state.breadCrumbs}
                      sortTableByDateDesc={this.state.sortTableByDateDesc}
                      taskList={this.state.taskList}
                    />
                  ) : (
                    <div className="noData">
                      No Variant Data
                    </div>
                  )
                }
              </TabPanel>
              <TabPanel className="tabPanel">
                { this.state.caseData.length > 0 ? (
                  <CaseTable
                    data={this.state.caseData}
                    programId={this.state.programId}
                    breadCrumbs={this.state.breadCrumbs}
                  />
                  ) : (
                    <div className="noData">
                      No Case Data
                    </div>
                  )
                }
              </TabPanel>
            </Tabs>
          </div>
          </>
        ) : (
          // spacing for load spinner
          <div className="noData"></div>
        )}
        <UploadModal
          closeModal={this.closeUploadModal}
          modalIsOpen={this.state.uploadModalIsOpen}
          programId={sessionStorage.getItem('program')}
        />
        <FeedbackModal
          closeModal={this.closeFeedbackModal}
          modalIsOpen={this.state.feedbackModalIsOpen}
          message={this.state.feedbackMsg}
        />
      </div>
    );
  }
}
