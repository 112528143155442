const SessionConstants = {

    SAMS_ACCESS_TOKEN: 'samsToken',
    SAMS_REFRESH_TOKEN: 'samsRefreshToken',
    ED3N_ACCESS_TOKEN: 'ed3nToken',
    ED3N_REFRESH_TOKEN: 'ed3nRefreshToken',
    USER_EMAIL: 'useremail',
    USER_FIRSTNAME: 'firstname',
    USER_LASTNAME: 'lastName',
    PROGRAM_ID: 'programId',
    NBS_PROGRAM: 'nbsprograms',
    IS_MANAGER: 'isManager',
    ERROR_MESSAGE: 'errorMessage',
    ERROR_DESCRIPTION: 'errorDescription',
    TIMEOUT_STATUS: false,
    LOGOUT_STATUS: false,
    FULLSCREEN: false,
    DEVLOGIN: 'devLogin',

};

export default SessionConstants;