import * as constants from './Constants';
import classificationStrengths from './ClassificationStrengths';

/**
Given an list of evaluation values, create an strengthToCountsObj that maps the
strength value to the count of strength value in the given array
Given: ['PS1', 'PS2', 'PS3', 'BA1']
Return: {Pathogenic Strong: 3, Benign Stand Alone: 1}
**/
function strengthToCountMapping(evaluationList) {
  var strengthToCounts = {};
  evaluationList.forEach(evaluation => {
    if (strengthToCounts[classificationStrengths[evaluation]] == undefined) {
      strengthToCounts[classificationStrengths[evaluation]] = 0;
    }
    strengthToCounts[classificationStrengths[evaluation]]++;
  });
  return strengthToCounts;
}

export default function classificationLogic(evaluationList) {
  var strengthToCountsObj = strengthToCountMapping(evaluationList);

  const pathogenic1 =
    strengthToCountsObj[constants.PATHOGENIC_VERY_STRONG] === 1;

  const pathogenic1a = strengthToCountsObj[constants.PATHOGENIC_STRONG] >= 1;

  const pathogenic1b = strengthToCountsObj[constants.PATHOGENIC_MODERATE] >= 2;

  const pathogenic1c =
    strengthToCountsObj[constants.PATHOGENIC_MODERATE] === 1 &&
    strengthToCountsObj[constants.PATHOGENIC_SUPPORTING] === 1;

  const pathogenic1d =
    strengthToCountsObj[constants.PATHOGENIC_SUPPORTING] >= 2;

  const pathogenic2 = strengthToCountsObj[constants.PATHOGENIC_STRONG] >= 2;

  const pathogenic3 = strengthToCountsObj[constants.PATHOGENIC_STRONG] === 1;

  const pathogenic3a = strengthToCountsObj[constants.PATHOGENIC_MODERATE] >= 3;

  const pathogenic3b =
    strengthToCountsObj[constants.PATHOGENIC_MODERATE] === 2 &&
    strengthToCountsObj[constants.PATHOGENIC_SUPPORTING] >= 2;

  const pathogenic3c =
    strengthToCountsObj[constants.PATHOGENIC_MODERATE] === 1 &&
    strengthToCountsObj[constants.PATHOGENIC_SUPPORTING] >= 4;

  const likelyPathogenic1 =
    strengthToCountsObj[constants.PATHOGENIC_VERY_STRONG] === 1 &&
    strengthToCountsObj[constants.PATHOGENIC_MODERATE] === 1;

  const likelyPathogenic2 =
    strengthToCountsObj[constants.PATHOGENIC_STRONG] === 1 &&
    strengthToCountsObj[constants.PATHOGENIC_MODERATE] <= 2;

  const likelyPathogenic3 =
    strengthToCountsObj[constants.PATHOGENIC_STRONG] === 1 &&
    strengthToCountsObj[constants.PATHOGENIC_SUPPORTING] >= 2;

  const likelyPathogenic4 =
    strengthToCountsObj[constants.PATHOGENIC_MODERATE] >= 3;

  const likelyPathogenic5 =
    strengthToCountsObj[constants.PATHOGENIC_MODERATE] === 2 &&
    strengthToCountsObj[constants.PATHOGENIC_SUPPORTING] >= 2;

  const likelyPathogenic6 =
    strengthToCountsObj[constants.PATHOGENIC_MODERATE] === 1 &&
    strengthToCountsObj[constants.PATHOGENIC_SUPPORTING] >= 4;

  const benign1 = strengthToCountsObj[constants.BENIGN_STAND_ALONE] === 1;

  const benign2 = strengthToCountsObj[constants.BENIGN_STRONG] >= 2;

  const likelyBenign1 =
    strengthToCountsObj[constants.BENIGN_STRONG] === 1 &&
    strengthToCountsObj[constants.BENIGN_SUPPORTING] === 1;

  const likelyBenign2 = strengthToCountsObj[constants.BENIGN_SUPPORTING] >= 2;

  const pathogenic =
    (pathogenic1 &&
      (pathogenic1a || pathogenic1b || pathogenic1c || pathogenic1d)) ||
    pathogenic2 ||
    (pathogenic3 && (pathogenic3a || pathogenic3b || pathogenic3c));

  const likelyPathogenic =
    likelyPathogenic1 ||
    likelyPathogenic2 ||
    likelyPathogenic3 ||
    likelyPathogenic4 ||
    likelyPathogenic5 ||
    likelyPathogenic6;

  const benign = benign1 || benign2;

  const likelyBenign = likelyBenign1 || likelyBenign2;

  if (pathogenic) {
    return 'Pathogenic ';
  } else if (likelyPathogenic) {
    return 'Likely Pathogenic';
  } else if (benign) {
    return 'Benign';
  } else if (likelyBenign) {
    return 'Likely Benign';
  } else if (Object.keys(strengthToCountsObj).length === 0) {
    return 'None';
  } else {
    return 'Uncertain Significance';
  }
}
