const samsConfig = __SAMS_CONFIG__;

//azure function
const azConfig =  __AZ_CONFIG__;

const basePath = {
    oauth: '/ed3n-oauth/oauth',
    sams: '/ed3n-oauth/sams',
    rest: '/ed3n-rest',

    molecularVariant: '/molecular/variant',
    molecularFile: '/molecular/file',
    fileUpload: '/file',
    biochemical: '/biochemical',
    heartbeat: '/heartbeat',
    molecularAnnotation: '/annotation',
    molecularReview: '/molecular/review',
    molecularInterpretation: '/molecular/interpretation',
    molecularLiterature: '/molecular/literature',
    summary: '/summary',
    taskManagement: '/taskmanagement',
    setting: '/setting',
    datastore: '/datastore',
    user: '/user',
    report: '/report',
};

const Paths = {
    //  ====== ED3N OAUTH ENDPOINTS ======
    ed3nToken: basePath.oauth + '/token',
    ed3nLogout: basePath.oauth + '/revoke',
    ed3nValidate: basePath.oauth + '/check_token',

    //  ====== SAMS ENDPOINTS ======
    samsAuth: samsConfig.authUrl + '?response_type=code&client_id=' + samsConfig.clientId +
        '&client_secret=' + samsConfig.clientSecret + '&redirect_uri=' + samsConfig.redirectUri + '&scope=openid profile email',
    samsToken: basePath.sams + '/token',
    samsUserInfo: basePath.sams + '/userinfo',
    samsValidate: basePath.sams + '/validate',
    samsRefreshToken: basePath.sams + '/refreshToken',
    samsLogout: samsConfig.logoutUrl,

    //  ====== USER ENDPOINTS ======
    ed3nUserInfo: basePath.rest + basePath.user + '/info',
    getProgramUsers: basePath.rest + basePath.user + '/get/{programId}',

    //  ====== PROGRAM SETTING ENDPOINTS ======
    updateProgramSetting: basePath.rest + basePath.setting + '/update/{programId}',
    getProgramSetting: basePath.rest + basePath.setting + '/get/{programId}',

    //  ====== BIOCHEMICAL ENDPOINTS ======
    biochemUpload: basePath.rest + basePath.biochemical + '/file/{programId}/upload',
    // TODO - not currently used
    getBiochemMetadata: basePath.rest + basePath.biochemical + '/{programId}/{caseId}',
    // TODO - not currently used
    getBiochemHistory: basePath.rest + basePath.biochemical + '/history/{programId}/{caseId}',

    //  ====== PROGRAM SUMMARY ENDPOINTS ======
    getCaseSummaryByProgramId: basePath.rest + basePath.summary + '/case/{programId}',
    getVariantSummaryByProgramId: basePath.rest + basePath.summary + '/variant/{programId}',
    getVariantSummaryByProgramCaseId: basePath.rest + basePath.summary + '/variant/{programId}/{programCaseId}',

    //  ====== REPORTING ENDPOINTS ======
    getCaseReport: basePath.rest + basePath.report + '/case/{programId}/{caseId}',

    //  ====== MOLECULAR ENDPOINTS ======
    molecularFileUpload: basePath.rest + basePath.fileUpload + '/add',
    //molecularFileUpload: azConfig.azHost + azConfig.azUpload + azConfig.azToken,
    
    molecularGetDataUploads: basePath.rest + basePath.molecularFile + '/get/{days}',
    molecularGetReview: basePath.rest + basePath.molecularReview + '/get/{programId}/{variantId}',
    molecularUpdateReviewSetting: basePath.rest + basePath.molecularReview + '/update/{variantReviewId}',
    molecularCreateReview: basePath.rest + basePath.molecularReview + '/create',
    molecularDeleteReview: basePath.rest + basePath.molecularReview + '/delete',
    molecularReopenReview: basePath.rest + basePath.molecularReview + '/reopen',

    molecularAddVariantInterpretation: basePath.rest + basePath.molecularInterpretation + '/add/{programId}/{variantId}',
    molecularUpdateVariantInterpretation: basePath.rest + basePath.molecularInterpretation + '/update/{programId}/{variantId}',
    molecularGetInterpretation: basePath.rest + basePath.molecularInterpretation + '/get/{variantId}',
    molecularGetPartialVariantInterpretation: basePath.rest + basePath.molecularInterpretation + '/partial/get/{programId}/{variantId}',
    molecularGetConsensusSummary: basePath.rest + basePath.molecularInterpretation + '/consensus/get/{programId}/{variantId}',
    molecularGetFormattedFinalVariantInterpretations: basePath.rest + basePath.molecularInterpretation + '/final/get/{variantId}',
    molecularAssignVariantInterpretation: basePath.rest + basePath.molecularInterpretation + '/assign/user/{variantReviewId}',
    molecularUnassignVariantInterpretation: basePath.rest + basePath.molecularInterpretation + '/unassign/user/{variantReviewId}',

    molecularGetAnnotations: basePath.rest + basePath.molecularAnnotation + '/get/summary/{variantId}',

    //  ====== DATASTORE ENDPOINTS ======
    molecularSearchLiterature: basePath.rest + basePath.molecularLiterature + '/search/{variantId}',
    molecularGetLiteratureReviews: basePath.rest + basePath.molecularLiterature + '/get/{variantInterpretationId}',
    molecularSaveLiteratureReviews: basePath.rest + basePath.molecularLiterature + '/add/{variantInterpretationId}',
    molecularDeleteLiteratureReview: basePath.rest + basePath.molecularLiterature + '/delete/{variantInterpretationId}/{pmid}',
    molecularGetLiteratureCategories: basePath.rest + basePath.molecularLiterature + '/categories',
    datastoreGetVariant: basePath.rest + basePath.datastore + '/variant/get/{variantId}',

    //  ====== TASK MANAGEMENT ENDPOINTS ======
    alert: basePath.rest + basePath.taskManagement + '/alert/get',
    taskGetByUser: basePath.rest + basePath.taskManagement + '/task/get',
    taskGetByVariant: basePath.rest + basePath.taskManagement + '/task/get/variant/{variantId}',
    taskGetById: basePath.rest + basePath.taskManagement + '/task/get/{taskId}',
    taskUpdate: basePath.rest + basePath.taskManagement + '/task/set/{taskId}/{taskAction}',
  
};

export default Paths;
