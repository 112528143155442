import React, { Component } from 'react';
import Modal from 'react-modal';
import { PulseLoader } from 'react-spinners';

import SessionConstants from '../../../config/SessionConstants';
import APIUtils from '../../../utils/APIUtils';
import FileUpload from './FileUpload';
import Metadata from './Metadata';

import './index.css';

import molVcfMetadataFields from './MolecularVcfFields';

export default class UploadModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filetype: 'molecularVcf',
      metadata: molVcfMetadataFields,
      accepted: [],
      formValues: {
        sequencingPlatform: "",
        variantCaller: "",
        reagentKit: "",
      },
      errorMsg: '',
      loading: false,
      toCaseLoad: false,
      needRefresh: false,
      programId: null,
      userEmail:'',
    };
  }

  componentDidMount = () => {
    this.setState({
        programId: sessionStorage.getItem(SessionConstants.PROGRAM_ID),
        userEmail: sessionStorage.getItem(SessionConstants.USER_EMAIL)
    })
  }

  //calls the closeModal from props which closes the modal
  onClose = (response,feedback) => {
    this.setState({ loading: false, errorMsg: '', accepted: [] }); //clear accepted on cancel to force next attempt validation
    this.props.closeModal(response,feedback);
  };

    handleInputChange = event => {
        var { name, value } = event.target;
        console.log("Changed " + name + " to " + value);
        let current = this.state.formValues;
        current[name] = value;
        this.setState({
            formValues: current
        });
    };

  //method to be passed down to FileUpload
  //takes the accepted files and sets the state
  acceptedFiles = accepted => {
    this.setState({ accepted });
  };

  //method to be passed down to FileUpload
  errorMsg = () => {
    this.setState({ errorMsg: '' });
  };

  //from the metadata array, extract the required fields names and put them in an array
  getRequiredFields() {
    var retArray = [];

    this.state.metadata.forEach(function(item) {
      if (item.required) {
        retArray.push(item.name);
      }
    });
    return retArray;
  }

    //returns true if validation errors exist in the upload form and false if no errors exist
    isError() {
        var errors = false;
        this.getRequiredFields().map(reqField => {
            if ((this.state.formValues[reqField] === undefined) || (this.state.formValues[reqField] === '')) {
                errors = true;
            }
        });
        //if there were no errors, clear the error message state
        if (!(errors)) {
            this.setState({ errorMsg: '' });
        }

        return errors;
    }

  //upload the given file to the backend
  async uploadFile(file) {
    //set up the form data object with metadata from the form
    console.log('---starting attempting to upload');
    //once upload is attempted, clear the accepted file list for next attempt submit validation
    this.setState({ accepted: [] });

    var formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', file.name);
    formData.append('programId', this.state.programId)
    formData.append('userEmail', this.state.userEmail);
    for (const key of Object.keys(this.state.formValues)) {
        formData.append(key, this.state.formValues[key]);
    }
    var response = await APIUtils.uploadMolecularFile(formData);
    
    if (response) {
      //if success response then close the modal and return the caseload page
      var feedback = true; //send this to onClose so we can check if it's true or undefined
      this.onClose(response,feedback);
    } else {
      //if errors exist then display the error message
      console.error(err);
      this.setState({
        errorMsg: 'There was an error uploading the file.',
        loading: false,
      });
    }
  };

  //Check validations on form and either upload the file or display correct validation error
  submitClicked = () => {
    console.log('uploading a file, I just clicked SUBMIT');
    const file = this.state.accepted[0];
    if (file === undefined || (file != undefined) & (file.length === 0)) {
      console.log('cannot find the file');
      this.setState({
        errorMsg: 'Please select a file',
        loading: false,
      });
    } else if (this.isError()) {
      console.log('there was an error, probably missing a required field');
      this.setState({
        errorMsg: 'Please fill out all required fields',
        loading: false,
      });
    } else {
      console.log('no errors here, loading=true');
      this.setState({ loading: true });
      console.log('about to upload the file');
      this.uploadFile(file);
    }
  };

  //generic method to construct each tab in the modal
  tabPanel = (dropzoneTxt, fileExt) => {
    return (
      <div>
        <div className="error">{this.state.errorMsg}</div>
        <div>
          <PulseLoader
            color="var(--primary-color)"
            sizeUnit={'px'}
            size={10}
            loading={this.state.loading}
          />
        </div>
        <FileUpload
          dropZoneMsg={dropzoneTxt}
          accept={fileExt}
          acceptedFiles={accepted => {
            this.acceptedFiles(accepted);
          }}
          errorMsg={this.errorMsg}
        />
        <Metadata
          metadata={this.state.metadata}
          handleInputChange={this.handleInputChange}
        />
      </div>
    );
  };

  render() {
    return (
      <div>
        <Modal
          className="modal fileUploadModal"
          overlayClassName="modalOverlay"
          aria-modal="true"
          contentLabel="Upload a New File"
          shouldCloseOnOverlayClick={true}
          isOpen={this.props.modalIsOpen}
          onRequestClose={this.onClose}
        >
          <div className="modalHeader">Upload a new file</div>
          <div className="modalContent">
            <div className="sectionHeading center">
              Molecular (VCF)
            </div>
            <div>
              {this.tabPanel(
                'Drop a VCF file here',
                '.VCF'
              )}
            </div>
          </div>
          <div className="modalButtons">
            <button
              className="modalButton primaryButton"
              onClick={this.submitClicked}
            >
              Submit
            </button>
            <button className="modalButton" onClick={this.onClose}>
              Cancel
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}
