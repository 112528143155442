import React, { Component } from 'react';
import './index.css';

export default class ClassificationWarning extends Component {

  render() {
    return (
      <>
      {this.props.calcClassification != this.props.classification
        ?
      ( <div className="classification-warning">
        Warning: Your selected classification is different from calculated classification.
      </div>)
      : ""
      }
      </>
    );
  }
}
