import React, { useRef, useState } from "react";
import Oauth from "../../utils/Oauth";
import IdleTimer from "react-idle-timer";
import SessionTimeoutModal from "./SessionTimoutModal.js";
import SessionConstants from '../../config/SessionConstants.js';
import Paths from '../../config/Paths.js';

let countdownInterval;
let timeout;

const SessionTimeout = () => {
    const [timeoutModalOpen, setTimeoutModalOpen] = useState(false);
    const [timeoutCountdown, setTimeoutCountdown] = useState(0);
    const idleTimer = useRef(null);
    const clearSessionTimeout = () => {
        clearTimeout(timeout);
    };
    const clearSessionInterval = () => {
        clearInterval(countdownInterval);
    };
    const handleLogout = async (isTimeout) => {
        setTimeoutModalOpen(false);
        clearSessionInterval();
        clearSessionTimeout();
        Oauth.clearAllSessionVariables();

        sessionStorage.setItem(SessionConstants.LOGOUT_STATUS, true);
        sessionStorage.setItem(SessionConstants.ERROR_DESCRIPTION, 'Logged out of ED3N'); // Ensure snackbar is not blank here
        window.location.assign(Paths.samsLogout);
        // if (!isTimeout){
        //     //user clicked logout
        //     sessionStorage.setItem(SessionConstants.LOGOUT_STATUS, true);
        //     window.location.assign(Paths.samsLogout);
        // }
        // else{
        //     //auto logout
        //     TODO: Display pop-up modal with Session timout message
        //           and When user clicks OK then redirect to landing page.
        //     sessionStorage.setItem(SessionConstants.ERROR_MESSAGE, 'Session timeout');
        //     sessionStorage.setItem(SessionConstants.ERROR_DESCRIPTION, 'Session Timeout: Your login for ED3N has expired');
        //     sessionStorage.setItem(SessionConstants.TIMEOUT_STATUS, true);
        //     window.location.assign("/landing");
        // }
    };
    const handleContinue = () => {
        setTimeoutModalOpen(false);
        clearSessionInterval();
        clearSessionTimeout();
        Oauth.refreshAllTokens();
    };
    const onActive = () => {
        console.log(" --onActive");
        if (!timeoutModalOpen) {
            clearSessionInterval();
            clearSessionTimeout();
            Oauth.refreshAllTokens();
        }
    };
    const onIdle = () => {
        console.log(" --OnIdle --")
        //delay for another 10 mins in addition to 15 mins timeout from IdleTimer 
        //(for some reason it does not work when IdelTimeout more than 15 mins)
        const delay = 10 * 60 * 1000;
         if (!timeoutModalOpen) {          
             timeout = setTimeout(countDown, delay); 
         }
    };

    const countDown=()=>{
        let countDown = 5 * 60; //5 mins count down
        setTimeoutModalOpen(true);
        setTimeoutCountdown(countDown);
        countdownInterval = setInterval(() => {
             if (countDown > 0) {
                  setTimeoutCountdown(--countDown);
              } else {
                  handleLogout(true);
              }
        }, 1000); //count down every second
    }

return (
<>
    <IdleTimer
        ref={idleTimer}
        onActive={onActive}
        onIdle={onIdle}
        debounce={250}
        timeout={1000 * 60 * 15}
    />
    <SessionTimeoutModal
        countdown={timeoutCountdown}
        onContinue={handleContinue}
        onLogout={() => handleLogout(false)}
        open={timeoutModalOpen}
    />
</>
);

}
export default SessionTimeout;