import React, {useEffect, useState } from 'react';
import LiteratureTable from '../LiteratureTable';
import LiteratureModal from '../LiteratureModal';
import AnnotationSectionsConfig from '../AnnotationSectionsConfig';

import APIUtils from "../../../utils/APIUtils";

const Literature = (props) => {

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [literatureReview, setLiteratureReview] = useState();
    const [literatureReviews, setLiteratureReviews] = useState([]);
    const [literatures, setLiteratures] = useState([]);
    const [categories, setCategories] = useState([]);
    const [focus, setFocus] = useState('');

    function onOpenModal (selectedliteratureReview, focus)  {
        setLiteratureReview(selectedliteratureReview);
        setFocus(focus);
        setModalIsOpen(true);
    };

    useEffect(() => {
        fetchArticles();
        fetchLiteratureReviews();
        fetchCategories();
    }, [props.variantInterpretationId]);
     
    function closeModal  ()  {
        setModalIsOpen(false);
    };

    async function fetchArticles() {
        console.log('Fetching Literature Articles');
        const response = await APIUtils.searchLiteratureForVariant(props.variantId);
        setLiteratures(response);  
    }

    async function fetchLiteratureReviews() {
        var response = await APIUtils.getLiteratureReviewsForReview(props.variantInterpretationId);
        setLiteratureReviews(response);
        if (response) {
            let evals = response.map(review => {
                return review.acmgEvidCriteriaId
            });
            props.onLiteratureEvaluationChange(evals);
            const articlesToAdd = literatures.filter(
                (array_el) =>
                    response.filter(
                    (anotherOne_el) => anotherOne_el.pmid === array_el.pmid
                  ).length === 0
            );
            setLiteratures(articlesToAdd);
        }
    }

    async function fetchCategories() {
        const response = await APIUtils.getLiteratureCategories();
        setCategories(response);
    }

    async function deleteReview(selectedLiteratureReview){
        await APIUtils.deleteLiteratureReview(props.variantInterpretationId, selectedLiteratureReview.pmid);
        //refresh data
        await fetchLiteratureReviews();
        await fetchArticles();
    }
  
    function renderSourceList  () {
        var array = [];
        AnnotationSectionsConfig[props.sourceType].sources.map(source => {
            array.push(
                <option key={source} value={source}>
                {source}
                </option>
            );
        });
        return array;
    }

    async function onAddOrUpdate(){
        //refresh data
        await fetchLiteratureReviews();
        setModalIsOpen(false);
    }

    return (
        <>
            {literatureReview != null ?
            <LiteratureModal 
                renderSourceList={renderSourceList()} 
                onAddOrUpdate={onAddOrUpdate}
                closeModal={closeModal}
                modalIsOpen={modalIsOpen}
                variantInterpretationId={props.variantInterpretationId}
                literatureReview={literatureReview}
                categories={categories}
                focus={focus}
            />
            : '' }

            <LiteratureTable variantId={props.variantId}
                variantInterpretationId={props.variantInterpretationId}
                onOpenModal={onOpenModal}   
                onDelete={deleteReview} 
                variantName={props.variantName}   
                gene={props.gene}      
                literatureReviews={literatureReviews}
                literatures={literatures}
                categories={categories}
            />
        </>
    );
}
  
export default Literature;