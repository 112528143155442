import React, { useState, useEffect } from 'react';
import LinkLabel from '../../uiComponents/LinkLabel';
import { convertUTCDateToLocalDate } from '../../utils/DateUtils';
import { TableContainer,TableBody,Paper,Table,makeStyles} from '@material-ui/core';
import MuiTableHeader from '../../uiComponents/MuiTable/MuiTableHeader';
import MuiTablePagination from '../../uiComponents/MuiTable/MuiTablePagination';
import {stableSort,getComparator} from '../../uiComponents/MuiTable/MuiHelper'
import {StyledTableRow,StyledTableCell} from '../../uiComponents/MuiTable/StyledTable'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    fontFamily: "Open Sans",
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

 //#region create columns header array
const columns = [
  {label: 'Case ID', id: 'programCaseId',numeric: false, disablePadding: false},
  {label: 'Date Uploaded',id: 'dateUploaded',numeric: false, disablePadding: false},
  {label: 'Biochemical: Potential Condition',id: 'potentialConditionsString',numeric: false, disablePadding: false},
  {label: 'Molecular: Number of Variant Calls',id: 'numVariants',numeric: true, disablePadding: false,align:'center'},
];
//#endregion

export default function CaseTable(props) {
  const classes = useState();
  const [rows,setRows] = useState(props.data);   //hold original dataset
  const [tableData,setTableData] = useState([]); //  
  //props on sort table
  const [order,setOrder] = useState('desc');
  const [orderBy,setOrderBy] = useState('dateUploaded');
  //prop for paging
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  
  useEffect(() => {
    console.log("case table:", rows)
    rows.forEach(row => {
        //add column potentialConditionsString so we can sort this column by potentialConditionsString
        row.potentialConditionsString = potentialConditionsCell(row);
    });
    setTableData(rows);
  }, [rows]);

  const potentialConditionsCell = (rowInfo) => {
    return rowInfo.potentialConditions
      .filter(cond => cond.trim() !== "")  // Filter out empty strings or spaces
      .join(",");                          // Join the remaining values with commas
  }

  //#region Sorting 
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  //#endregion

  //#region Paging
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };    

  return (
  <div className={classes.root}>
    <Paper className={classes.paper}>       
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="case table">
          <MuiTableHeader 
            headCells={columns} 
            order={order}  
            orderBy={orderBy} 
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {stableSort(tableData, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return(
                <StyledTableRow tabIndex={-1}  key={row.programCaseId}>
                  <StyledTableCell>
                    <LinkLabel  
                      for={row.programCaseId}
                      path={'/casedetails/' + row.programCaseId}
                      text={row.programCaseId}
                      state={ {programId: props.programId,variantName: row.variantName,breadCrumbs: props.breadCrumbs,}}
                    />
                  </StyledTableCell>
                  <StyledTableCell>{convertUTCDateToLocalDate(row.dateUploaded)}</StyledTableCell>
                  <StyledTableCell>{row.potentialConditionsString}</StyledTableCell>
                  <StyledTableCell align='center'>{row.numVariants === undefined
                    ? 'N/A'
                    : row.numVariants}
                  </StyledTableCell>
                  </StyledTableRow>
                );
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
      <MuiTablePagination 
        rowsPerPage={rowsPerPage}
        page={page}
        count={tableData.length}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage} />
      </Paper>
    </div>
      );
}

