import React  from 'react';
import PropTypes from 'prop-types';
import {StyledTablePagination} from './StyledTable'

function MuiTablePagination(props) {
return (
       <StyledTablePagination
              rowsPerPageOptions={[5, 10, 25, 50]}
              component="div"
              count={props.count}
              rowsPerPage={props.rowsPerPage}
              page={props.page}
              onPageChange={props.onPageChange}
              onRowsPerPageChange={props.onRowsPerPageChange}
              //Important: form labels for accessibility
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                  'aria-labelledby': 'rowsPage',
                },
                id: 'rowsPage',
              }}
          />
  )}

MuiTablePagination.propTypes = {
    rowsPerPage: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    onRowsPerPageChange: PropTypes.func.isRequired,
  };

export default MuiTablePagination