import React, { useState, useEffect } from 'react';
import Oauth from '../../utils/Oauth.js';

const LoginValidator = ({ onValidation, children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null);

    useEffect(() => {
        const validateLogin = async () => {
            try {
                const isLoginValid = await Oauth.isLoginValid();
                if (isLoginValid) {
                    Oauth.setLoginAsValid();
                    if (!Oauth.isLocalDevLogin()) {
                        await Oauth.refreshSamsToken();
                    }
                    //await Oauth.refreshEd3nToken();
                    setIsAuthenticated(true);
                    onValidation(true);
                } else {
                    setIsAuthenticated(false);
                    onValidation(false);
                }
            } catch (error) {
                console.error('Error validating login:', error);
                setIsAuthenticated(false);
                onValidation(false);
            }
        };

        validateLogin();
    }, [onValidation]);

    if (isAuthenticated === null) {
        return <div>Loading...</div>; // Optionally show a loading indicator
    }

    return <>{children}</>; // Render children if authenticated
};

export default LoginValidator;