import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import SessionConstants from '../../config/SessionConstants.js';
import BreadCrumbs from '../BreadCrumbs';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import AssignedInterpreters from './AssignedInterpreters/index.js';
import VariantSettingsModal from './VariantSettingsModal/index.js';
import VariantReviewHistory from './VariantReviewHistory/index.js';
import APIUtils from '../../utils/APIUtils';
import ReviewStatus from '../../config/ReviewStatus.js';
import Loading from '../Loading';
import './index.css';

export default class VariantDashboard extends Component {

    state = {
        breadCrumbs: [],
        variantSettingsModalIsOpen: false,
        variantId: null,
        variantName: null,
        requiredInterpretations: null,
        variantReview: null,
        interpreterData: [],
        reviewHistoryData: [],
        taskData:[],
        showVariantSetting: false,
        loading: true,
        childComponentKey: 0 //change this value each time you want to relaod child component
    }

    async componentDidMount() {
        console.log('--VariantSettings.componentDidMount()');
    
        //load passed params for breadcrumbs, etc
        this.loadPassedParameters();
        await this.loadData();
        this.setState({
            loading: false,
        });
    }

    loadPassedParameters() {
        console.log('---VariantDashboard.loadPassedParameters()');
        const {
            match: { params },
        } = this.props;

        var variantId = this.props.location.state.variantId;
        var variantName = this.props.location.state.variantName;
        var breadCrumbs = this.props.location.state.breadCrumbs;
        breadCrumbs = [...breadCrumbs, {
            name: 'VariantDashboard',
            variantId: variantId,
            variantName: variantName,
        }]

        this.setState({
            breadCrumbs: breadCrumbs,
            variantId: variantId,
            variantName: variantName,
        });
    }
 
    loadData = async() => {
        //Load Variant Review
        const programId = sessionStorage.getItem(SessionConstants.PROGRAM_ID);

        const variantResponse = await APIUtils.getReview(programId, this.props.location.state.variantId);
        const taskResponse = await APIUtils.getTasksByVariant(this.props.location.state.variantId); 
        const variantReview = variantResponse;
        const childComponentKey = this.state.childComponentKey + 1;
        const showVariantSetting = ((variantReview.reviewStatus == ReviewStatus.REVIEW_CREATED) ||
            (variantReview.reviewStatus == ReviewStatus.IN_PROGRESS));
        this.setState ({
            variantReview: variantReview,
            requiredInterpretations: variantReview.requiredInterpretations, 
            interpreterData: variantReview.variantInterpretations,
            reviewHistoryData: variantReview.historyItems,
            taskData: taskResponse,
            showVariantSetting: showVariantSetting,
            childComponentKey: childComponentKey,
            loading: false,
        })
        // console.log("variantResponse",variantReview.variantInterpretations)
    }

    openModal = () => {
        this.setState({ variantSettingsModalIsOpen: true });
    };
  
    closeModal = () => {
        this.setState({variantSettingsModalIsOpen: false });
    };

    refresh = async() => {
        this.setState({ loading: true });
        await this.loadData();
    }

    render() {
        return (
            <div className="managerSettings">
                <Loading isLoading={this.state.loading} />
                <Helmet>
                <title>ED3N - Variant Review Dashboard</title>
                </Helmet>
                <BreadCrumbs breadCrumbs={this.state.breadCrumbs} />
                <h1 className="pageTitle">Variant Review Dashboard</h1>
                <div className="managerButtonBar">
                    <div className="managerLeftName">
                        Variant {this.state.variantName}
                    </div>
                    {this.state.showVariantSetting ? (
                    <div className="managerButtons">
                        <button className="actionButton settingsButton" onClick={this.openModal}>
                            <FontAwesomeIcon icon={faCog} /> Variant Review Settings
                        </button>
                    </div>
                    )
                    : ''}
                </div>
                <Grid container spacing={3} className="dashGridContainer">
                <Grid item xs={12}>
                    <Paper className="card userDashTable" elevation={0}>
                        <div className="requiredInterpretations">Required Interpretations: {this.state.requiredInterpretations}</div>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper className="card userDashTable" elevation={0}>
                        <div className="dashTableDescription">Assigned Interpreters</div>
                        { this.state.interpreterData ? (
                        <AssignedInterpreters
                            data={this.state.interpreterData}
                            taskData={this.state.taskData}
                            childKey={this.state.childComponentKey}
                        />
                        ) : (
                            'No Interpreters Assigned'
                        )
                        }
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper className="card userDashTable" elevation={0}>
                        <div className="dashTableDescription">Variant Review History</div>
                        { this.state.reviewHistoryData  ? (
                        <VariantReviewHistory
                            data={this.state.reviewHistoryData}
                            childKey={this.state.childComponentKey}
                        />
                        ) : (
                            'No Variant Review History'
                        )
                        }
                    </Paper>
                </Grid>
                </Grid>
                {this.state.variantReview ? (
                <VariantSettingsModal
                    closeModal={this.closeModal}
                    modalIsOpen={this.state.variantSettingsModalIsOpen}
                    refresh={this.refresh}
                    data={this.state.variantReview}
                    key={this.state.childComponentKey}
                />
                ) : ('')}
            </div>

        );
    }
}
