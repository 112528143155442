import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Table, TableBody, TableContainer, Paper } from '@material-ui/core';
import { StyledTableRow, StyledTableCell} from '../../../../uiComponents/MuiTable/StyledTable.js'
import { stableSort, getComparator} from '../../../../uiComponents/MuiTable/MuiHelper';
import MuiTableHeader from "../../../../uiComponents/MuiTable/MuiTableHeader.js";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './index.css';

const useStyles = makeStyles(theme => ({
  divRoot: {
    width: '100%',
    marginBottom: '.75rem',
  },
  div: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  root: {
    background: '#006778',
    color: 'white',
    fontFamily: "Open Sans",
  },
  inline:{
    color: 'white'
  },
  paper: {
    width: '100%',
  },
  table: {
    minWidth: 750,
  },
  header: {
    height: '2rem',
  },
  cell: {
    wordBreak: 'break-word',
  }
}));

const dbSnpUrlBase = "https://www.ncbi.nlm.nih.gov/snp/";
const clinvarUrlBase = "https://www.ncbi.nlm.nih.gov/clinvar/";
const omimUrlBase = "https://www.omim.org/entry/";
const pubMedUrlBase = "https://pubmed.ncbi.nlm.nih.gov/";
const medGenUrlBase = "https://www.ncbi.nlm.nih.gov/medgen/?term=";

const valueCell = (row) => {
  const { key, value } = row;

  if (String(key).toLowerCase().startsWith("omim")) {
    // Handle the case where the key starts with "OMIM" (case insensitive)
    const omimId = String(value).split('.')[0];
    return (<a target="_blank" key={value} href={omimUrlBase + omimId}>{value}</a>);
  }

  if (String(key).toLowerCase().startsWith("medgen")) {
    // Handle the case where the key starts with "MedGen" (case insensitive)
    return (<a target="_blank" key={value} href={medGenUrlBase + String(value)}>{value}</a>);
  }

  // If value is an array, join it with comma separator
  if (Array.isArray(value)) {
    var values = [];        
    for (let i = 0; i < value.length; i++) {
      // Check for dbSnp value. This value will always be in an array in Nirvana
      if (String(value[i]).startsWith("rs")) {
        values.push((<a target="_blank" key={value[i]} href={dbSnpUrlBase + value[i]}>{value[i]}</a>));
      } else if (String(key).toLowerCase().startsWith("pubmed")) {
        // Handle the case where the key starts with "PubMed" (case insensitive)
        values.push((<a target="_blank" key={value[i]} href={pubMedUrlBase + value[i]}>{value[i]}</a>));
      } else {
        values.push((value[i]));
      }

      if (i < (value.length - 1)) {
        values.push((", "));
      }
    }
    return <>{values}</>;
  }

  //  Check for a hyperlinkable value (VCV and RCV numbers for ClinVar)
  if (String(value).toLowerCase().startsWith("vcv")) {
    return (<a target="_blank" key={value} href={clinvarUrlBase + "variation/" + String(value).split('.')[0].replace(/^(VCV)0+/, '')}>{value}</a>);
  } 

  if (String(value).toLowerCase().startsWith("rcv")) {
    const rcvVal = String(value).split('.')[0];
    return (<a target="_blank" key={value} href={clinvarUrlBase + rcvVal}>{value}</a>);
  }

  return <span>{value}</span>;
};

const AnnotationData = ({ annotationData, fieldDescription, annotationSources }) => {
  const classes = useStyles();
  const [sections, setSections] = useState([]);
  const [expandedAccordions, setExpandedAccordions] = useState([]);

  useEffect(() => {
    // console.log('--AnnotationData.componentDidMount()');
    //  form the anno table JSON for each anno section by checking the root JSON element for each anno source
    const sections=[];
    Object.keys(annotationData).map(key => {
      const tables = [];
      var element = annotationData[key];

      //  Is the root element an array element?
      if (JSON.stringify(element).startsWith("[")) {
        //  The root element is an array. Is there only one element in the array?
        if (Object.keys(element).length == 1) {
          // the root element is an array with only one element (probably a score or something). Is it a key-value pair?
          if ((element) == "[object Object]") {
            // it's a key-value pair. Is this gene data? If so, we need to go a level lower to get the fields.
            if ('gene' in element[0]){
              //TODO: need to revisit this gene/fields format, some are aray of genes, some array of feilds    
              if (typeof element[0].fields.length === "undefined" ){    
                tables.push({order: 'desc', orderBy: '', "tableName": "Gene:" + element[0].gene, "rows": renderAnnotationTable(element[0].fields)});
              } else {
                element[0].fields.forEach(field => {  
                  tables.push({order: 'desc', orderBy: '', "tableName": "Gene:" + element[0].gene, "rows": renderAnnotationTable(field)});
                })
              }
            } else {
              //  the array has one element and it's just a value. Render a simple table
              tables.push({order: 'desc', orderBy: '', "tableName": key, "rows":  renderAnnotationTable(element[0])});
            }
          } else {
            //  the array has one element and it's just a value. Render a simple table
            tables.push({order: 'desc', orderBy: '', "tableName": key, "rows": renderAnnotationTableWithSingleElement(key, element)});
          }
        } else {
          //  The root element is an array with multiple elements. Make a table for each element in the array
          element.forEach(item => {          
            if ('gene' in item) {
              tables.push({order: 'desc', orderBy: '', "tableName": "Gene:" + item.gene, "rows": renderAnnotationTable(item.fields)});
            } else {
              tables.push({order: 'desc', orderBy: '', "tableName": key, "rows": renderAnnotationTable(item)});
            }
          })//end foreach
        }
      }//end complex array 
      else {
        //  the root element isn't an array. Does the root element have key values? 
        if ((element) == "[object Object]") {
          tables.push({order: 'desc', orderBy: '', "tableName": key, "rows": renderAnnotationTable(element)});
        } else {
          //  the root element is just a value
          tables.push({order: 'desc', orderBy: '', "tableName": key, "rows": renderAnnotationTableWithSingleElement(key, element)});
        }
      }

      sections.push({
        sectionName: key,
        tables: tables,
      });
    });

    setSections(sections);
  }, [annotationData]);

  const handleAccordionChange = (index) => {
    setExpandedAccordions(prevState => {
      const isExpanded = prevState.includes(index);
      return isExpanded ? prevState.filter(item => item !== index) : prevState.concat(index);
    });
  };

  const isAccordionExpanded = (index) => {
    return expandedAccordions.includes(index);
  };

  const renderAnnotationTable = (pairs) => {
    const rows = [];
    for (const key of Object.keys(pairs)) {
      // Check if the value is an object
      if (typeof pairs[key] === 'object' && !Array.isArray(pairs[key]) && pairs[key] !== null) {
         //  it's a JSON element (currently should only happen for aminoAcidConservation). dig for the scores and concat them together
        if (pairs[key].scores && Array.isArray(pairs[key].scores)) {
          var rowValue = pairs[key].scores.join(", ");
          rows.push({ key: key, value: rowValue, description: fieldDescription[key] });
        } else {
          // If it's another type of object, handle it accordingly
          rows.push({ key: key, value: JSON.stringify(pairs[key]), description: fieldDescription[key] });
        }
      } else {
         // it's just a value. Give it its own row.
        rows.push({ key: key, value: pairs[key], description: fieldDescription[key] });
      }
    }
    return rows;
  };

  const renderAnnotationTableWithSingleElement = (key, value) => {
    const rows = []; 
    rows.push({"key":key, "value":value, "description": fieldDescription[key] });
    return rows;
  };

  const handleRequestSort = (sectionIndex, tableIndex, property) => {
    setSections(prevSections => {
      const newSections = [...prevSections];
      const section = newSections[sectionIndex];
      const table = section.tables[tableIndex];
      const isAsc = table.orderBy === property && table.order === 'asc';
      table.order = isAsc ? 'desc' : 'asc';
      table.orderBy = property;
      return newSections;
    });
  };

  const columns = [
    { label: 'Name', id: 'key', numeric: false, disablePadding: false },
    { label: 'Value', id: 'value', numeric: false, disablePadding: false },
    { label: 'Description', id: 'description', numeric: false, disablePadding: false },
  ];

  const handleAnnotationData = () => {
    return sections.length > 0 ? (
      //  hide annotation table if no column data
      sections.map((section, index) => (
        <div key={section.sectionName} className={classes.divRoot}>
          {section.tables.length > 0 && (
            <Accordion onChange={() => handleAccordionChange(index)} >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
                expanded={isAccordionExpanded(index).toString()}
              >
                <Typography className={classes.heading}>
                  { annotationSources[section.sectionName].sourceFullName}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {section.tables.map((table, i) => (
                  <div className="accordionInnerDiv" key={i}>
                    <TableContainer component={Paper}>
                      <Table 
                        className={classes.table} 
                        size="small"
                        //  append name to each table in array, add table number if more than one table
                        aria-label={`${annotationSources[section.sectionName].sourceFullName} table${section.tables.length > 1 ? ' ' + (i + 1) : ''}`} 
                      >
                        <MuiTableHeader
                          headCells={columns}
                          order={table.order}
                          orderBy={table.orderBy}
                          onRequestSort={(event, property) => handleRequestSort(index, i, property)}
                        />
                        <TableBody>
                          {stableSort(table.rows, getComparator(table.order, table.orderBy))
                            .map((row, rowIndex) => {
                            return (
                              <StyledTableRow tabIndex={-1} key={rowIndex}>
                                <StyledTableCell style={{width: '33%'}}>{row.key}</StyledTableCell>
                                <StyledTableCell style={{width: '33%'}}>{valueCell(row)}</StyledTableCell>
                                <StyledTableCell style={{width: '33%'}}>{row.description}</StyledTableCell>
                              </StyledTableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                ))}
              </AccordionDetails>
            </Accordion>
          )}
        </div>
      ))
    ) : (
      <div>No annotation data in this category was found for this variant.</div>
    );
  };

  return <div>{handleAnnotationData()}</div>;
};

export default AnnotationData;