import React, {useState, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import ReviewStatus from '../../../config/ReviewStatus.js';
import moment from 'moment';
import {StyledTableRow, StyledTableCell} from '../../../uiComponents/MuiTable/StyledTable.js'
import { NewReleases, AssignmentInd } from '@material-ui/icons';
import MuiTableHeader from '../../../uiComponents/MuiTable/MuiTableHeader';
import TableFilter from '../../../uiComponents/MuiTable/TableFilterGrid';
import MuiTablePagination from '../../../uiComponents/MuiTable/MuiTablePagination';
import {stableSort, getComparator} from '../../../uiComponents/MuiTable/MuiHelper'
import { 
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  Paper,
} from '@material-ui/core';
import './index.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    fontFamily: "Open Sans",
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

//Important: The id here must match the column name in the returned data. Label can be different for display.
const headCells = [
  { label: 'Variant Name', id: 'variantName', numeric: false, disablePadding: false },
  { label: 'Latest Review Status', id: 'interpretationStatusString', numeric: false, disablePadding: false },
  { label: 'Review Version', id: 'currentReviewVersion', numeric: true, disablePadding: false, align: 'left' },
  { label: 'Cases', id: 'numCasesIdentified', numeric: false, disablePadding: false },
  { label: 'Gene', id: 'gene', numeric: false, disablePadding: false },
  { label: 'Condition', id: 'potentialCondition', numeric: false, disablePadding: false },
  { label: 'Program Classification', id: 'programClassification', numeric: false, disablePadding: false },
  { label: 'Date Uploaded', id: 'createdDate', numeric: false, disablePadding: false },
  { label: 'Last Reviewed', id: 'lastReviewed', numeric: false, disablePadding: false },
];
const blankHeadCells=[
  {id: 'uploadedToday', hiddenHeader:'New'},
  {id: 'AssignedToMe', hiddenHeader:'Assigned to Me'}
]
export default function VariantsTable(props) {
  const rows = props.data;
  const taskList = props.taskList;
  const classes = useStyles();
  const [tableData, setTableData] = useState(props.data);
  const [order, setOrder] = useState('desc'); //default sort direction
  const [orderBy, setOrderBy] = useState('createdDate'); //default sort column for table
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [modalCasesIsOpen, setModalCasesIsOpen] = useState(false);
  const [modalVariantName, setModalVariantName] = useState(null);
  const [modalCasesList, setModalCasesList] = useState([]);
  const [focus, setFocus] = useState('');
  //intialize tableData
  useEffect(() => { 
    setTableData(props.data)
  }, [props.data] );

  useEffect(() => {
    //if upload was successful, sort by date desc
    if (props.sortTableByDateDesc) {
      setOrder('desc');
      setOrderBy('createdDate');
    }
  }, [props.sortTableByDateDesc]);

  const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
  };
  
  const handleChangePage = (event, newPage) => {
      setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
  };

  //Check if variant was uploaded today and display new icon
  const uploadedTodayCell = (rowDate) => {
    const date = new Date(rowDate);
    const currentDate = new Date();
    if (
      date.getFullYear() === currentDate.getFullYear() &&
      date.getMonth() === currentDate.getMonth() &&
      date.getDate() === currentDate.getDate()
    ) {return (
      <>
        <div
          className="accessibleIcon center" 
          aria-label="New Review Opened Today"
          tabIndex={0} 
          role="img"
        >
          <NewReleases className="newIcon" alt="New Review Opened Today" fontSize='medium' /> New Review
        </div>
      </>
    )} else {return (
      ''
    )}
  };

  const assignedToMeCell = (variantName) => {
    //Show assigned icon if this row variant name matches a variant in the user's tasks and task status is not completed (submitted) or unassigned 
    const matchedInterpretation = taskList.find(item => item.variant.hgvsGrch38Name == variantName && item.taskStatus !== 'UNASSIGNED' && item.taskStatus !== 'COMPLETED');
    if (matchedInterpretation) {
      return (
        <>
          <div
            className="accessibleIcon center" 
            aria-label="Interpretation Assigned to Me"
            tabIndex={0} 
            role="img"
          >
            <AssignmentInd className="newIcon" alt="Interpretation Assigned to Me" fontSize='medium' /> Assigned to Me
          </div>

        </>
      )
    } else {return (
      ''
    )}
  }

  const variantNameCell = (row) => {
    {return (
      <Link
          to={{
            pathname: '/variantdetails/' + row.variantId,
            state: {
              variantId: row.variantId,
              variantName: row.variantName,
              reviewStatus: row.reviewStatus.value,
              breadCrumbs: props.breadCrumbs,
              expected: row.expected,
              completed: row.completed,
              inProgress: row.inProgress,
            }
          }}>
          {row.variantName}
      </Link>
    )}
  }

  const statusCell = (row) => {
    if (row.interpretationStatusString === ReviewStatus.CLOSED) {
      return (
        <Link
          to={{
            pathname: '/variantconsensus/' + row.variantId,
            state: {
              variantId: row.variantId,
              reviewStatus: row.reviewStatus,
              variantName: row.variantName,
              gene: row.gene,
              potentialCondition: row.potentialCondition,
              breadCrumbs: props.breadCrumbs,
            }
          }}
        >
          Consensus review complete <FontAwesomeIcon icon={faCheckCircle}/>
        </Link>
      )
    } else if (row.interpretationStatusString === ReviewStatus.READY_FOR_CONSENSUS) {
      return (
        <Link
          to={{
            pathname: '/variantconsensus/' + row.variantId,
            state: {
              variantId: row.variantId,
              reviewStatus: row.reviewStatus,
              variantName: row.variantName,
              gene: row.gene,
              potentialCondition: row.potentialCondition,
              breadCrumbs: props.breadCrumbs,
            }
          }}
        >
          Ready for consensus review <FontAwesomeIcon sytle='color: blue' icon={faArrowCircleRight}/>
        </Link>
      )
    } else if (row.interpretationStatusString === ReviewStatus.CONSENSUS_IN_PROGRESS) {
      return (
        <Link
          to={{
            pathname: '/variantconsensus/' + row.variantId,
            state: {
              variantId: row.variantId,
              reviewStatus: row.reviewStatus,
              variantName: row.variantName,
              gene: row.gene,
              potentialCondition: row.potentialCondition,
              breadCrumbs: props.breadCrumbs,
          }
          }}
        >
          Consensus review in progress <FontAwesomeIcon sytle='color: blue' icon={faArrowCircleRight}/>
        </Link>
      )
    } else {
      return (
        row.interpretationStatusString
      )
    }    
  }

  //open cases modal
  const handleCasesClick = (event, variantName, casesList, variantId) => {
    event.preventDefault();
    setModalCasesIsOpen(true);
    setModalVariantName(variantName);
    setModalCasesList(casesList);
    setFocus(variantId);
  }
  //keyboard accessibility to open cases modal
  const handleCasesKey = (event, variantName, casesList, variantId) => {
    if (event.keyCode === 13 || event.keycode === 32) {
      event.preventDefault();
      setModalCasesIsOpen(true);
      setModalVariantName(variantName);
      setModalCasesList(casesList);
      setFocus(variantId);
    }
  }

  const casesCell = (row) => {
    return (
      <a
        id={row.variantId} //set id for focus return on modal close
        href='#'
        tabIndex="0"
        onClick={(event) => {handleCasesClick(event, row.variantName, row.casesList, row.variantId)}}
        onKeyDown={(event) => {handleCasesKey(event, row.variantName, row.casesList, row.variantId)}}
      >
        {row.numCasesIdentified}
      </a>
    )
  }

  const geneCell = (row) => {
    return row.gene.split(',').join(', ');
  }

  //load cases list for modal
  const casesList = () => {
    return (
      modalCasesList ? 
      (
        <div>
          {modalCasesList.map((item, index) =>(
              <div className="caseListItem" key={index}>
                <Link
                  to={{
                    pathname: '/casedetails/' + item,
                    state: {
                      programId: props.programId,
                      breadCrumbs: props.breadCrumbs,
                    },
                  }}>
                  {item}
                </Link>
              </div>
            ))}
        </div>
      ) :
      (
        <div>No Cases Found</div>
      )
    )
  }

  const closeModal = () => {
    setModalCasesIsOpen(false);
  };

  const returnFocus = () => {
    document.getElementById(focus).focus();
  };

  //+++FILTERS+++
  //Get table rows, select unique gene and conditions on load, sort alphabetical, map to new array for use in filters
  const [searchFilter, setSearchFilter] = useState('');
  const [filterBy, setFilterBy] = useState('');

  //Search Filter Type Select
  const filterTypeChange = (event) => {
    setSearchFilter(event.target.value);
    setFilterBy(''); //clear any set filters on type change
    setTableData(rows); //reset filters to initial data when changing top level search filter type
  };

  //++++Filter By Gene or Condition+++
  const handleFilterChange = (event) => {
    setFilterBy(event.target.value)
    let filteredValues = event.target.value
      ? rows.filter(r => (searchFilter === 'gene' ? r.gene : r.potentialCondition) === event.target.value) //filter by selected searchFilter type if it matches input value
      : rows; //filter from the original array rows = props.data
    setTableData(filteredValues); 
  };

  const clearFilters = (event) => {
    setSearchFilter('');
    setFilterBy('');
    setTableData(rows); //reset filters to initial data
  };

  return (
    <>
      <div className="tableFilterGrid">
      <TableFilter rows={props.data} 
        searchFilter={searchFilter}
        filterBy={filterBy}
        onClearFilters={clearFilters}
        handleFilterChange={handleFilterChange}
        onFilterTypeChange={filterTypeChange}
      />
      </div>
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <TableContainer>
            <Table
              className={classes.table}
              size='small'
              aria-label="variants table" >
              <MuiTableHeader 
                blankHeadCells={blankHeadCells} 
                headCells={headCells} 
                order={order}  
                orderBy={orderBy} 
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {stableSort(tableData, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                  return (
                    <StyledTableRow tabIndex={-1} key={row.variantId}>
                        <StyledTableCell style={{width: '2%'}}>{uploadedTodayCell(row.createdDate)}</StyledTableCell>
                        <StyledTableCell style={{width: '2%'}}>{assignedToMeCell(row.variantName)}</StyledTableCell>
                        <StyledTableCell style={{width: '15%'}}>{variantNameCell(row)}</StyledTableCell>
                        <StyledTableCell style={{width: '25%'}}>{statusCell(row)}</StyledTableCell> 
                        <StyledTableCell style={{width: '2%'}}>{row.currentReviewVersion}</StyledTableCell>
                        <StyledTableCell style={{width: '2%'}}>{casesCell(row)}</StyledTableCell>
                        <StyledTableCell style={{width: '7%'}}>{geneCell(row)}</StyledTableCell>
                        <StyledTableCell style={{width: '7%'}}>{row.potentialCondition}</StyledTableCell>
                        <StyledTableCell style={{width: '15%'}}>{row.programClassification}</StyledTableCell>
                        <StyledTableCell style={{width: '12.5%'}}>{row.createdDate ? moment(row.createdDate).format("MM/DD/YYYY") : ''}</StyledTableCell> 
                        <StyledTableCell style={{width: '12.5%'}}>{row.lastReviewed ? moment(row.lastReviewed).format("MM/DD/YYYY") : ''}</StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <MuiTablePagination 
            rowsPerPage={rowsPerPage}
            page={page}
            count={tableData.length}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage} 
          />
        </Paper>
      </div>
      <Modal
        className="modal"
        overlayClassName="modalOverlay"
        aria-modal="true"
        contentLabel="Cases for Variant"
        shouldCloseOnOverlayClick={true}
        isOpen={modalCasesIsOpen}
        onRequestClose={closeModal}
        onAfterClose={returnFocus}
      >
        <div>
          <div className="modalHeader">Cases for Variant: {modalVariantName}</div>
          <div className="modalContent center">
            {casesList()}
          </div>
            <div className="modalButtons">
                <button
                    className="modalButton primaryButton"
                    onClick={closeModal}
                >
                    Close
                </button>
            </div>
        </div>
      </Modal>
    </>
  );
}
