import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import Loading from '../Loading';
import {Helmet} from "react-helmet";

import BreadCrumbs from '../BreadCrumbs';
import Oauth from '../../utils/Oauth.js';
import './index.css';

export default class Help extends Component {
  constructor(props) {
    super(props);

    this.state = {
      breadCrumbs: [],
    };
  }

  componentDidUpdate() {
    console.log('---Help.componentDidUpdate()');
  }

  async componentDidMount() {
    console.log('---Help.componentDidMount()');
  }
 
  render() {
    return (
      <div>
        <Loading isLoading={this.state.loading}/>
        <Helmet>
          <title>ED3N - Help</title>
        </Helmet>
        <BreadCrumbs breadCrumbs={this.state.breadCrumbs} />
        <h1 className="pageTitle">Help</h1>
        <div className='emailWrapper helpPage'>
            <div>
                For help with ED3N, please email CDC ED3N Support:
            </div>
            <div className='linkWrapper'>
                <a className='emailLink' role='link' aria-label='send email' href="mailto:cdced3nsupport@cdc.gov?Subject=ED3N%20Help"><FontAwesomeIcon icon={faEnvelope} className='errorButtonIcons'></FontAwesomeIcon>CDC ED3N support</a>
            </div>
        </div>
      </div>
    );
  }
}
