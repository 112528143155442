
import React from 'react';
import { makeStyles, Grid, FormControl, TextField, MenuItem, Box, Button } from '@material-ui/core';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import PropTypes from 'prop-types';

const useStyleFilter = makeStyles({
    //custom colors for MUI text field select
    filterInputs: {
        "& .MuiOutlinedInput-input": {
            color: "#2e2e2e"
        },
        "& .MuiInputLabel-root": {
            color: "#2e2e2e"
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#737373"
        },
        "&:hover .MuiOutlinedInput-input": {
            color: "#006778"
        },
        "&:hover .MuiInputLabel-root": {
            color: "#006778"
        },
        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#006778"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
            color: "#bb4d00",
            backgroundColor: "white"
        },
        "& .MuiInputLabel-root.Mui-focused": {
            color: "#bb4d00"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#bb4d00"
        }
    }
});


//NOTE:  We could extend this component later.  
//For now, it's default filter by Gene and Condition
function TableFilter(props) {
    const filterClasses = useStyleFilter();

    // use a prop here to set the Array condition name
    
    const geneArray = [...new Set(props.rows.sort((a, b) => a.gene.localeCompare(b.gene)).map(row => row.gene))]
    const conditionArray = [...new Set(props.rows.sort((a, b) => a.potentialCondition.localeCompare(b.potentialCondition)).map(row => row.potentialCondition))]
    const generateKey = (item) => { //generate unique key for UI generated lists to prevent react warning
        return `${ item }_${ new Date().getTime() }`;
    }
         
    return(
        <Grid container spacing={3} >
            <Grid item xs={6}>
                <Grid container spacing={3} >
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextField
                                fullWidth
                                size="small"
                                id="variantSearchFilter"
                                className={filterClasses.filterInputs}
                                value={props.searchFilter}
                                onChange={props.onFilterTypeChange}
                                variant="outlined"
                                label="Search Filter"
                                select
                            >
                                <MenuItem value=""><em>None</em></MenuItem>  
                                <MenuItem value="gene">By Gene</MenuItem>
                                <MenuItem value="condition">By Condition</MenuItem>
                            </TextField>
                        </FormControl>
                    </Grid>
                    {props.searchFilter ? 
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextField
                                fullWidth
                                size="small"
                                id="searchFilter"
                                className={filterClasses.filterInputs}
                                value={props.filterBy}
                                onChange={props.handleFilterChange}
                                variant="outlined"
                                //return label from selected filter
                                label={props.searchFilter.charAt(0).toUpperCase() + props.searchFilter.slice(1)}
                                select
                            >
                                {props.searchFilter === 'gene' ? //select gene or condition
                                    geneArray.map((item) => (
                                      <MenuItem value={item} key={generateKey(item)}>{item.split(',').join(', ')}</MenuItem>
                                    ))
                                :
                                    conditionArray.map((item) => (
                                      <MenuItem value={item} key={generateKey(item)}>{item}</MenuItem>
                                    ))
                                }
                            </TextField>
                        </FormControl>
                    </Grid>
                    : ''}
                </Grid>
            </Grid>
            <Grid item xs={6}>
                <Box display="flex" justifyContent="flex-end">
                    <Button 
                        id="clearFilters"
                        color="primary"
                        variant="contained"
                        aria-label="Clear table filters" 
                        onClick={props.onClearFilters}
                    >
                        <ClearAllIcon className="iconMarginRight" /> Clear Filters
                    </Button>
                </Box>
            </Grid>
        </Grid>
    )
}
TableFilter.propTypes = {
    onClearFilters: PropTypes.func.isRequired,
    rows: PropTypes.array.isRequired,
    filterBy: PropTypes.string.isRequired,
    searchFilter: PropTypes.string.isRequired,
    handleFilterChange: PropTypes.func.isRequired,
    onFilterTypeChange: PropTypes.func.isRequired,
  };
  export default TableFilter